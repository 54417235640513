import { FbaShipmentCostTableItemContract, PrepServiceContract } from "api/types/contracts/shipping";
import _ from "lodash";

type SelectedProductItem = {
    prepServices: string[],
    count: number
}

export const unitPrepServicesCostTableItems = (prepServicesList: PrepServiceContract[], selectedProducts: SelectedProductItem[], additionalPrepServices: string[]) => {
    const list: FbaShipmentCostTableItemContract[] = [];
    const totalCount = _.sumBy(selectedProducts, p => p.count);

    for (const item of selectedProducts) {
        for (const prepServiceId of item.prepServices) {
            const prepService = prepServicesList.find(p => p.prepServiceId === prepServiceId);
            
            if (prepService) {
                let listItem = list.find(p => p.prepServiceId === prepServiceId);
                
                if (prepService.pricingType === "perUnit") {

                    if (!listItem) {
                        listItem = {
                            prepServiceId,
                            serviceName: prepService.description || prepService.name,
                            unitPrice: prepService.price || 0,
                            count: 0,
                            total: prepService.price || 0
                        };

                        list.push(listItem);
                    }
                
                    listItem.count += item.count;
                    if (prepService.priceRanges.length > 0) {
                        const range = prepService.priceRanges.find(r => r.from <= listItem!.count && r.to >= listItem!.count);

                        if (range) {
                            listItem.unitPrice = range.value;
                        }
                    }
                    listItem.total = listItem.unitPrice * listItem.count;
                }
            }
        }
    }

    for (const prepServiceId of additionalPrepServices) {
        const prepService = prepServicesList.find(p => p.prepServiceId === prepServiceId);

        if (prepService) {
            if (prepService.pricingType !== "perUnit") {
                continue;
            }

            let listItem = list.find(p => p.prepServiceId === prepServiceId);

            if (!listItem) {
                listItem = {
                    prepServiceId,
                    serviceName: prepService.description || prepService.name,
                    unitPrice: prepService.price || 0,
                    count: 0,
                    total: prepService.price || 0,
                    deletable: !prepService.includeAsDefault
                };

                list.push(listItem);
            }

            listItem.count = totalCount;
            if (prepService.priceRanges.length > 0) {
                const range = prepService.priceRanges.find(r => r.from <= listItem!.count && r.to >= listItem!.count);

                if (range) {
                    listItem.unitPrice = range.value;
                }
            }
            listItem.total = listItem.unitPrice * listItem.count;
        }
    }

    return list;
}

export const skuPrepServicesConstTableItems = (prepServicesList: PrepServiceContract[], selectedProducts: SelectedProductItem[], additionalPrepServices: string[]) => {
    const list: FbaShipmentCostTableItemContract[] = [];

    for (const item of selectedProducts) {
        for (const prepServiceId of item.prepServices) {
            const prepService = prepServicesList.find(p => p.prepServiceId === prepServiceId);
            
            if (prepService) {
                let listItem = list.find(p => p.prepServiceId === prepServiceId);
                
                if (prepService.pricingType === "perSku") {

                    if (!listItem) {
                        listItem = {
                            prepServiceId,
                            serviceName: prepService.description || prepService.name,
                            unitPrice: prepService.price || 0,
                            count: 0,
                            total: prepService.price || 0
                        };

                        list.push(listItem);
                    }
                
                    listItem.count += 1;
                    if (prepService.priceRanges.length > 0) {
                        const range = prepService.priceRanges.find(r => r.from <= listItem!.count && r.to >= listItem!.count);

                        if (range) {
                            listItem.unitPrice = range.value;
                        }
                    }
                    listItem.total = listItem.unitPrice * listItem.count;
                }
            }
        }
    }

    for (const prepServiceId of additionalPrepServices) {
        const prepService = prepServicesList.find(p => p.prepServiceId === prepServiceId);

        if (prepService) {
            if (prepService.pricingType !== "perSku") {
                continue;
            }

            let listItem = list.find(p => p.prepServiceId === prepServiceId);

            if (!listItem) {
                listItem = {
                    prepServiceId,
                    serviceName: prepService.description || prepService.name,
                    unitPrice: prepService.price || 0,
                    count: 0,
                    total: prepService.price || 0,
                    deletable: !prepService.includeAsDefault
                };

                list.push(listItem);
            }

            listItem.count = selectedProducts.length;
            if (prepService.priceRanges.length > 0) {
                const range = prepService.priceRanges.find(r => r.from <= listItem!.count && r.to >= listItem!.count);

                if (range) {
                    listItem.unitPrice = range.value;
                }
            }
            listItem.total = listItem.unitPrice * listItem.count;
        }
    }

    return list;
}

export const boxPrepServicesConstTableItems = (prepServicesList: PrepServiceContract[], additionalPrepServices: string[], packedBoxesCount: number) => {
    const list: FbaShipmentCostTableItemContract[] = [];

    // for (const { item } of selectedProducts) {
    //     for (const prepServiceId of item.prepServices) {
    //         const prepService = prepServicesList.find(p => p.prepServiceId === prepServiceId);
            
    //         if (prepService) {
    //             let listItem = list.find(p => p.prepServiceId === prepServiceId);
                
    //             if (prepService.pricingType === "perBox") {
    //                 if (!listItem) {
    //                     listItem = {
    //                         prepServiceId,
    //                         serviceName: "(B) " + prepService.name,
    //                         unitPrice: prepService.price || 0,
    //                         count: 0,
    //                         total: prepService.price || 0
    //                     };

    //                     list.push(listItem);
    //                 }

    //                 const productBoxes = packedBoxes.filter(b => Array.from(b.containedProducts.keys()).find(p => p.asin === item.asin));
                
    //                 listItem.count += productBoxes.length;
    //                 if (prepService.priceRanges.length > 0) {
    //                     const range = prepService.priceRanges.find(r => r.from <= listItem!.count && r.to >= listItem!.count);

    //                     if (range) {
    //                         listItem.unitPrice = range.value;
    //                     }
    //                 }
    //                 listItem.total = listItem.unitPrice * listItem.count;
    //             }
    //         }
    //     }
    // }

    for (const prepServiceId of additionalPrepServices) {
        const prepService = prepServicesList.find(p => p.prepServiceId === prepServiceId);

        if (prepService) {
            if (prepService.pricingType !== "perBox") {
                continue;
            }

            let listItem = list.find(p => p.prepServiceId === prepServiceId);

            if (!listItem) {
                listItem = {
                    prepServiceId,
                    serviceName: prepService.description || prepService.name,
                    unitPrice: prepService.price || 0,
                    count: 0,
                    total: prepService.price || 0,
                    deletable: !prepService.includeAsDefault
                };

                list.push(listItem);
            }

            listItem.count = packedBoxesCount;
            if (prepService.priceRanges.length > 0) {
                const range = prepService.priceRanges.find(r => r.from <= listItem!.count && r.to >= listItem!.count);

                if (range) {
                    listItem.unitPrice = range.value;
                }
            }
            listItem.total = listItem.unitPrice * listItem.count;
        }
    }

    return list;
}

export const fixedPrepServicesConstTableItems = (prepServicesList: PrepServiceContract[], selectedPrepServiceIds: string[]) => {
    const list: FbaShipmentCostTableItemContract[] = [];

    for (const prepServiceId of selectedPrepServiceIds) {
        const prepService = prepServicesList.find(p => p.prepServiceId === prepServiceId);

        if (prepService) {
            if (prepService.pricingType !== "fixPrice") {
                continue;
            }

            let listItem = list.find(p => p.prepServiceId === prepServiceId);

            if (!listItem) {
                listItem = {
                    prepServiceId,
                    serviceName: prepService.description || prepService.name,
                    unitPrice: prepService.price || 0,
                    count: 0,
                    total: prepService.price || 0
                };

                list.push(listItem);
            }

            listItem.count = 1;
            if (prepService.priceRanges.length > 0) {
                const range = prepService.priceRanges.find(r => r.from <= listItem!.count && r.to >= listItem!.count);

                if (range) {
                    listItem.unitPrice = range.value;
                }
            }
            listItem.total = listItem.unitPrice * listItem.count;
        }
    }
    
    return list;
}

export const prepServiceCostTable = (prepServicesList: PrepServiceContract[], selectedProducts: SelectedProductItem[], additionalPrepServices: string[], packedBoxesCount: number) => {
    const list: FbaShipmentCostTableItemContract[] = [];

    list.push(...unitPrepServicesCostTableItems(prepServicesList, selectedProducts, additionalPrepServices));
    list.push(...skuPrepServicesConstTableItems(prepServicesList, selectedProducts, additionalPrepServices));
    list.push(...boxPrepServicesConstTableItems(prepServicesList, additionalPrepServices, packedBoxesCount));
    list.push(...fixedPrepServicesConstTableItems(prepServicesList, additionalPrepServices));

    return list;
}