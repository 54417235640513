import { useMutation } from "@tanstack/react-query";
import BusyOverlay from "Components/Common/BusyOverlay";
import RequiredLabel from "Components/Common/RequiredLabel";
import ValidationErrorDisplay from "Components/Displays/ValidationErrorDisplay";
import UnitOfCurrencySelect from "Components/EnumSelects/UnitOfCurrencySelect";
import UnitOfLengthSelect from "Components/EnumSelects/UnitOfLengthSelect";
import TextInput from "Components/Form/TextInput";
import CurrencyInput from "Components/Form/UnitInputs/CurrencyInput";
import LengthInput from "Components/Form/UnitInputs/LengthInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { postCarrierOversizeRule, putCarrierOversizeRule } from "api/carrierRules";
import { CarrierOversizeRuleContract, CarrierOversizeRuleForm } from "api/types/contracts/carrier";
import { RangeValueDefinitionContract, UnitOfCurrency, UnitOfLength } from "api/types/contracts/common";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, Col, Container, Form, Row } from "reactstrap";
import * as Yup from "yup";

type FormProps = {
    oversizeRule?: CarrierOversizeRuleContract,
    onSuccess: VoidFunction
}

const defaultForm: Partial<CarrierOversizeRuleForm> = {
    name: "",
    currencyCode: undefined,
    girthRanges: [],
    lengthRanges: [],
    unitOfLength: undefined
};

const createOversizeRuleForm = (rule: CarrierOversizeRuleContract | undefined): CarrierOversizeRuleForm | undefined => {
    return rule ? {
        name: rule.name,
        currencyCode: rule.currencyCode,
        girthRanges: _.sortBy(rule.girthRanges, r => r.from),
        lengthRanges: _.sortBy(rule.lengthRanges, r => r.from),
        unitOfLength: rule.unitOfLength
    } as CarrierOversizeRuleForm : undefined
};

const EditForm = (props: FormProps) => {
    const { setPageTitle } = useMetadata();
    const { t } = useTranslation();
    const { userProfile } = useProfile();
    const ruleForm = useMemo(() => createOversizeRuleForm(props.oversizeRule) || defaultForm, [props.oversizeRule]);

    setPageTitle(`${t(props.oversizeRule ? "Update Oversize Rule" : "Create Oversize Rule")} - ${t("Carrier Rules")} - ${t("Management")}`);

    const saveCarrierOversizeRuleMutation = useMutation({
        mutationFn: async (params: CarrierOversizeRuleForm) => {
            if (props.oversizeRule) {
                await putCarrierOversizeRule({
                    carrierOversizeRuleId: props.oversizeRule.carrierOversizeRuleId,
                    carrierOversizeRule: params
                });
            }
            else {
                await postCarrierOversizeRule({
                    carrierOversizeRule: params
                });
            }
        },
        onSuccess: () => {
            if (props.oversizeRule) {
                toast.success(t("Oversize rule updated"));
            }
            else {
                toast.success(t("Oversize rule created"));
            }

            props.onSuccess();
        }
    });
    
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...ruleForm,
            unitOfLength: ruleForm.unitOfLength ?? userProfile?.user.warehouse?.settings.unitOfLength,
            currencyCode: ruleForm.currencyCode ?? userProfile?.user.warehouse?.settings.unitOfCurrency
        },
        validationSchema: Yup.object<CarrierOversizeRuleForm, TypedShape<CarrierOversizeRuleForm>>({
            name: Yup.string().required(t("Name is required")),
            currencyCode: Yup.string<UnitOfCurrency>().required(t("Currency is required")),
            unitOfLength: Yup.string<UnitOfLength>().required(t("Unit of length is required")),
            girthRanges: Yup.array<RangeValueDefinitionContract<number>>().of(Yup.object({
                from: Yup.number().required(t("Lower bound is required")),
                to: Yup.number().required(t("Upper bound is required")),
                value: Yup.number().required(t("Price is required"))
            })).overlapCheck(t("Ranges must not overlap")).required(),
            lengthRanges: Yup.array<RangeValueDefinitionContract<number>>().of(Yup.object({
                from: Yup.number().required(t("Lower bound is required")),
                to: Yup.number().required(t("Upper bound is required")),
                value: Yup.number().required(t("Price is required"))
            })).min(1, t("At least one range must be defined")).overlapCheck(t("Ranges must not overlap")).required()
        }),
        onSubmit: values => {
            const carrierOversizeRule = values as CarrierOversizeRuleForm;

            saveCarrierOversizeRuleMutation.mutate(carrierOversizeRule);
        },
    });

    const addNewGirthRangeRow = () => {
        const oldRanges = validation.values.girthRanges || [];
        const lastRangeEnd = oldRanges.at(-1)?.to || 0;

        validation.setFieldValue("girthRanges", [...oldRanges, {
            from: lastRangeEnd,
            to: lastRangeEnd + 1
        }]);
    };

    const removeGirthRangeRow = (i: number) => {
        const newRanges = validation.values.girthRanges?.filter((_, index) => index !== i);
        validation.setFieldValue("girthRanges", newRanges);
    }

    const addNewLengthRangeRow = () => {
        const oldRanges = validation.values.lengthRanges || [];
        const lastRangeEnd = oldRanges.at(-1)?.to || 0;

        validation.setFieldValue("lengthRanges", [...oldRanges, {
            from: lastRangeEnd,
            to: lastRangeEnd + 1
        }]);
    };

    const removeLengthRangeRow = (i: number) => {
        const newRanges = validation.values.lengthRanges?.filter((_, index) => index !== i);
        validation.setFieldValue("lengthRanges", newRanges);
    }

    useEffect(() => {
        if (ruleForm.girthRanges?.length === 0) {
            addNewGirthRangeRow();
        }

        if (ruleForm.lengthRanges?.length === 0) {
            addNewLengthRangeRow();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ruleForm]);

    return <div>
        <Form className="needs-validation" action="#" onSubmit={validation.handleSubmit}>
            <Container fluid>
                <Row>
                    <Col className="mb-2">
                    <h4>{t(props.oversizeRule ? "Edit Oversize Rule" : "Create Oversize Rule")}</h4>
                        <p className="mb-0 fs-10">{t("paragraphs:CarrierRulesOversizeRuleDescription")}</p>
                    </Col>
                </Row>
                <Row className="g-2 mb-2">
                    <Col xs={12}>
                        <RequiredLabel>{t("Rule Name")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="name">
                            <TextInput />
                        </ValidationWrapper>
                    </Col>
                    <Col xs={12}>
                        <RequiredLabel>{t("Currency")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="currencyCode">
                            <UnitOfCurrencySelect />
                        </ValidationWrapper>
                    </Col>
                    <Col xs={12} className="mb-2">
                        <RequiredLabel>{t("Unit")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="unitOfLength">
                            <UnitOfLengthSelect />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <Row className="g-2 mb-2">
                    <Col className="vstack gap-2">
                        <div className="label-separator my-2 label-seperator-2">
                            <span className="label">{t("Longest Side")}</span>
                        </div>
                        <div className="hstack gap-3 text-center">
                            <div style={{ flex: 4 }} className="fw-semibold small">
                                {t("Size Range")}
                            </div>
                            <div style={{ flex: 4 }} className="fw-semibold small">
                                {t("Oversize Fee")}
                            </div>
                        </div>
                        {validation.values.lengthRanges?.map((range, i) => <React.Fragment key={i}>
                            <div className="hstack gap-3 align-items-center">
                                <span className="small text-muted">{t("from")}</span>
                                <div style={{ flex: 2 }}>
                                    <ValidationWrapper validation={validation} field={`lengthRanges.${i}.from`}>
                                        <LengthInput size="sm" placeholder="From" tabIndex={3 * i + 1}  className="text-center" unit={validation.values.unitOfLength} />
                                    </ValidationWrapper>
                                </div>
                                <span className="small text-muted">{t("to")}</span>
                                <div style={{ flex: 2 }}>
                                    <ValidationWrapper validation={validation} field={`lengthRanges.${i}.to`}>
                                        <LengthInput size="sm" placeholder="To" tabIndex={3 * i + 2}  className="text-center" unit={validation.values.unitOfLength} />
                                    </ValidationWrapper>
                                </div>
                                <span className="small text-muted">{t("then")}</span>
                                <div style={{ flex: 3 }}>
                                    <ValidationWrapper validation={validation} field={`lengthRanges.${i}.value`}>
                                        <CurrencyInput size="sm" placeholder="Price" tabIndex={3 * i + 3} currency={validation.values.currencyCode ?? "usd"} />
                                    </ValidationWrapper>
                                </div>
                                <div style={{ flex: 0 }}>
                                    <Button size="sm" color="ghost-danger" className="btn-icon" onClick={() => removeLengthRangeRow(i)}>
                                        <i className="ri-delete-bin-5-fill"></i>
                                    </Button>
                                </div>
                            </div>
                        </React.Fragment>)}
                        <ValidationErrorDisplay validation={validation} field="lengthRanges" showUntouched />
                        <div className="d-flex justify-content-center">
                            <Button size="sm" type="button" className="btn-ghost-info" onClick={addNewLengthRangeRow}>
                                {t("Add another")}
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row className="g-2 mb-2">
                    <Col className="vstack gap-2">
                        <div className="label-separator my-2 label-seperator-2">
                            <span className="label">{t("Longest Side + Girth")}</span>
                        </div>
                        <div className="hstack gap-3 text-center">
                            <div style={{ flex: 4 }} className="fw-semibold small">
                                {t("Size Range")}
                            </div>
                            <div style={{ flex: 4 }} className="fw-semibold small">
                                {t("Oversize Fee")}
                            </div>
                        </div>
                        {validation.values.girthRanges?.map((range, i) => <React.Fragment key={i}>
                            <div className="hstack gap-3 align-items-center" key={i}>
                                <span className="small text-muted">{t("from")}</span>
                                <div style={{ flex: 2 }}>
                                    <ValidationWrapper validation={validation} field={`girthRanges.${i}.from`}>
                                        <LengthInput size="sm" placeholder="From" tabIndex={3 * i + 30} className="text-center" unit={validation.values.unitOfLength} />
                                    </ValidationWrapper>
                                </div>
                                <span className="small text-muted">{t("to")}</span>
                                <div style={{ flex: 2 }}>
                                    <ValidationWrapper validation={validation} field={`girthRanges.${i}.to`}>
                                        <LengthInput size="sm" placeholder="To" tabIndex={3 * i + 31} className="text-center" unit={validation.values.unitOfLength} />
                                    </ValidationWrapper>
                                </div>
                                <span className="small text-muted">{t("then")}</span>
                                <div style={{ flex: 3 }}>
                                    <ValidationWrapper validation={validation} field={`girthRanges.${i}.value`}>
                                        <CurrencyInput size="sm" placeholder="Price" tabIndex={3 * i + 32} currency={validation.values.currencyCode ?? "usd"} />
                                    </ValidationWrapper>
                                </div>
                                <div style={{ flex: 0 }}>
                                    <Button size="sm" color="ghost-danger" className="btn-icon" onClick={() => removeGirthRangeRow(i)}>
                                        <i className="ri-delete-bin-5-fill"></i>
                                    </Button>
                                </div>
                            </div>
                        </React.Fragment>)}
                        <ValidationErrorDisplay validation={validation} field="girthRanges" showUntouched />
                        <div className="d-flex justify-content-center">
                            <Button size="sm" type="button" className="btn-ghost-info" onClick={addNewGirthRangeRow}>
                                {t("Add another")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={saveCarrierOversizeRuleMutation.isPending} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </div>;
}

export default EditForm;