import { getCurrentTenant } from "api/tenant";
import { StorageAuthorizationMode, initialize, setCompany, setCustomer, setCustomerLoading, setError, setLoading, setSignatureValues, setStorageAuthorizationLoading, setTenant } from "./reducer";
import { ApiError } from "helpers/types";
import { TenantInfo } from "api/types/contracts/companies";
import { getCookieAuthorizationValues, getQueryAuthorizationValues } from "api/storage";
import { getCurrentCompany } from "api/company";
import { getCurrentCustomer } from "api/customer";
import { AppDispatch, RootState } from "slices";

export const startApp = () => async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(initialize());

        const loggedInUser = getState().Login.user;
        if (loggedInUser && new Date(loggedInUser.expiresAt) > new Date() && loggedInUser.user.userType === "customer") {
            try {
                var customer = await getCurrentCustomer();
        
                dispatch(setCustomer(customer));
            }
            catch (error) {
                
            }
        }
        else {
            dispatch(setCustomer(undefined));
        }
}

export const getTenantInfo = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading("loading"));
    const storedTenant = localStorage.getItem("tenant");
    let tenant: TenantInfo | undefined;
    
    if (storedTenant) {
        try {
            tenant = JSON.parse(storedTenant);
        } catch (error) {
            localStorage.removeItem("tenant");
        }
    }
    
    if (!tenant) {
        try {
            tenant = await getCurrentTenant();
            
            localStorage.setItem("tenant", JSON.stringify(tenant));
        } catch (error) {
            const apiError = error as ApiError;
            if (apiError.status === 0) {
                setError(apiError.message);
            }
        }
    }

    if (tenant) {
        dispatch(setTenant(tenant));
    }
    else {
        dispatch(setError("Failed to load tenant info"));
    }
};

export const refreshTenantInfo = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const tenant = await getCurrentTenant();

        localStorage.setItem("tenant", JSON.stringify(tenant));
        
        dispatch(setTenant(tenant));
    } catch (error) {
        
    }
}

export const loadCurrentCompany = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const company = await getCurrentCompany();

        dispatch(setCompany(company));
    } catch (error) {
        
    }
}

export const loadCurrentCustomer = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(setCustomerLoading());
        const customer = await getCurrentCustomer();

        dispatch(setCustomer(customer));
    } catch (error) {
        dispatch(setError("Failed to load customer"));
        dispatch(setCustomer(undefined));
    }
}

export const resetCustomer = () => (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setCustomer(undefined));
}

export const loadStorageAuthorizationValues = (mode: StorageAuthorizationMode) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setStorageAuthorizationLoading());

    try {
        let authorizationValues: Record<string, string>;

        if (mode === "cookie") {
            authorizationValues = await getCookieAuthorizationValues();
        }
        else if (mode === "queryString") {
            authorizationValues = await getQueryAuthorizationValues();
        }
        else {
            throw new Error("Not implemented");
        }

        dispatch(setSignatureValues([mode, authorizationValues]));

        return [mode, authorizationValues] as [StorageAuthorizationMode, Record<string, string>];
    } catch (error) {
        dispatch(setSignatureValues(undefined));
    }
}