import { APIClient } from "../helpers/api_helper";
import type { AdjustUserBalanceCommand } from "./types/commands";
import type { WalletContract } from "./types/contracts/payment";
import type { GetMyWalletQuery, GetWalletQuery } from "./types/queries";

const api = new APIClient();

export const getMyWallet = async (data: GetMyWalletQuery) => {
    const result = await api.get<WalletContract>(`/api/wallet/me`, { ...data });
    return result;
}

export const getWallet = async (data: GetWalletQuery) => {
    const { customerId, ...rest } = data;
    const result = await api.get<WalletContract>(`/api/wallet/customer/${customerId}`, rest);
    return result;
}

export const patchAdjustBalance = async (data: AdjustUserBalanceCommand) => {
    const { walletId, ...rest } = data;

    await api.patch(`/api/wallet/${walletId}/adjust`, { ...rest });
}