import { useProfile } from "Components/Hooks/ProfileHooks";
import { UnitOfWeight, type UnitOfLength } from "api/types/contracts/common";
import { convertWeight } from "helpers/unitConversions";
import _ from "lodash";

export type WeightProps = {
    value: number | undefined,
    unit: UnitOfWeight | undefined,
    showAlternative?: boolean,
    alternativeUnit?: UnitOfLength
}

const Weight = ({ value, unit, showAlternative, alternativeUnit }: WeightProps) => {
    const { userProfile } = useProfile();
    const displayUnit = unit ?? userProfile?.user.warehouse?.settings?.unitOfWeight ?? "lbs";
    const alternativeDisplayUnit = alternativeUnit ?? displayUnit === "lbs" ? "kg" : "lbs";

    if (value) {
        return <>
            <span className="text-nowrap">{_.round(value, 2)} {displayUnit}</span>
            {showAlternative && <span className="text-nowrap d-block text-muted small">
                {_.round(convertWeight(value, displayUnit, alternativeDisplayUnit), 2)} {alternativeDisplayUnit}
            </span>}
        </>;
    } else {
        return "-";
    }
}

export default Weight;