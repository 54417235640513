import BusyOverlay from "Components/Common/BusyOverlay";
import Loader from "Components/Common/Loader";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import ProductPickList from "Components/EntitySelects/ProductPickList";
import { useCommon } from "Components/Hooks/CommonHooks";
import { createAppSelector, useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import FbaShipmentItemsForm from "Components/Shared/Shipment/FbaShipmentItemsForm";
import { FbaShipmentFormItem } from "api/types/commands";
import { convertLength, convertWeight } from "helpers/unitConversions";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledDropdown } from "reactstrap";
import { addFbaShipmentItems } from "slices/shipping/fbaShipping/thunk";

const AddProductForm = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [addModal, setAddModal] = useState(false);
    const [productModal, setProductModal] = useState(false);
    const [items, setItems] = useState<FbaShipmentFormItem[]>();
    const { calculateExchange } = useCommon();

    const toggleAddModal = () => setAddModal(prev => !prev);
    const toggleProductModal = () => setProductModal(prev => !prev);

    const { fbaShipment, loading } = useAppSelector(
        createAppSelector([state => state.FbaShipping], 
            (fbaShipping) => ({
                fbaShipment: fbaShipping.processingItem,
                loading: fbaShipping.loading
            })
        )
    );

    if (!fbaShipment) {
        return <Loader height="50px" />;
    }

    const addItems = async (items: FbaShipmentFormItem[]) => {
        if (items.length > 0) {
            await dispatch(addFbaShipmentItems({
                fbaShipmentId: fbaShipment.fbaShipmentId,
                items
            }));

            setItems(undefined);
        }

        setProductModal(false);
        setAddModal(false);
    }

    const existingProducts = _.uniq(fbaShipment.items.map(i => i.product.productId));

    return <>
        <UncontrolledDropdown>
            <DropdownToggle caret color="primary">
                {t("Add New Product(s)")}
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => {
                    setItems(undefined);
                    toggleAddModal();
                }}>
                    {t("Add New ASIN")}
                </DropdownItem>
                <DropdownItem onClick={() => {
                    setItems(undefined);
                    toggleProductModal();
                }}>
                    {t("Select From Inventory")}
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
        <Modal backdrop="static" isOpen={addModal} toggle={toggleAddModal} size="xl" unmountOnClose>
            <ModalCloseButton onClick={toggleAddModal} />
            <ModalHeader>
                {t("Add Product")}
            </ModalHeader>
            <ModalBody>
                <FbaShipmentItemsForm existingProducts={fbaShipment.items.map(i => i.product.productId)} 
                    value={items} onChange={setItems} warehouseId={fbaShipment.warehouse.warehouseId} size="sm"
                    unitOfCurrency={fbaShipment.warehouse.settings.unitOfCurrency}
                    unitOfLength={fbaShipment.warehouse.settings.unitOfLength}
                    unitOfWeight={fbaShipment.warehouse.settings.unitOfWeight} />
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end gap-2">
                    <BusyOverlay busy={loading.save} size="sm">
                        <Button color="success" type="button" onClick={() => addItems(items ?? [])}>
                            {t("Save")}
                        </Button>
                    </BusyOverlay>
                    <Button color="danger" type="button" onClick={toggleAddModal}>
                        {t("Cancel")}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
        <Modal backdrop="static" isOpen={productModal} toggle={toggleProductModal} size="xl" unmountOnClose>
            <ModalCloseButton onClick={toggleProductModal} />
            <ModalBody>
                <ProductPickList 
                    disabledProducts={existingProducts} 
                    customerId={fbaShipment.customer.customerId}
                    onSelectComplete={products => {
                        toggleProductModal();

                        if (products.length === 0) {
                            return;
                        }

                        addItems(products.map(p => ({
                            productId: p.productId,
                            count: 1,
                            asin: p.asin ?? "",
                            name: p.name,
                            fnsku: p.fnsku,
                            weight: convertWeight(p.weight, p.options.unitOfWeight, fbaShipment.warehouse.settings.unitOfWeight),
                            length: convertLength(p.length, p.options.unitOfLength, fbaShipment.warehouse.settings.unitOfLength),
                            width: convertLength(p.width, p.options.unitOfLength, fbaShipment.warehouse.settings.unitOfLength),
                            height: convertLength(p.height, p.options.unitOfLength, fbaShipment.warehouse.settings.unitOfLength),
                            buyPrice: calculateExchange(p.buyPrice, p.options.unitOfCurrency, fbaShipment.warehouse.settings.unitOfCurrency),
                            isBundle: false,
                            isSplit: false,
                            bundleProducts: [],
                            prepServices: []
                        })));
                    }} />
            </ModalBody>
        </Modal>
    </>
}

export default AddProductForm;