import type { UnitOfCurrency } from "./common"
import type { CustomerContract } from "./customers";
import type { FbaShipmentContract } from "./shipping"
import type { UserContract } from "./users";
import type { WarehouseContract } from "./warehouses";

export const InvoiceStatusList = ["draft", "pending", "refund", "cancelled", "paid"] as const;
export const PaymentMethodList = ["stripe", "wise", "payoneer", "wireTransfer", "zelle"] as const;
export const CreditRequestStatusList = ["pending", "processing", "approved", "rejected"] as const;
export type InvoiceStatus = typeof InvoiceStatusList[number];
export type PaymentMethod = typeof PaymentMethodList[number];
export type CreditRequestStatus = typeof CreditRequestStatusList[number];
export type CreditCardVault = "stripe";

export type InvoiceContract = {
    invoiceId: string,
    fbaShipment: FbaShipmentContract | undefined,
    invoiceCode: string,
    status: InvoiceStatus,
    shippingCost: number,
    invoiceDate: Date,
    total: number,
    unitOfCurrency: UnitOfCurrency,
    items: InvoiceItemContract[],
    customer: CustomerContract,
    warehouse: WarehouseContract,
    createdAt: Date,
    owner: UserContract
}

export type InvoiceItemContract = {
    invoiceItemId: string,
    prepServiceId?: string,
    groupName: string,
    serviceName: string,
    unitPrice: number,
    discount?: number,
    price: number,
    count: number,
    total: number,
    createdAt: Date,
}

export type InvoiceForm = {
    //invoiceCode: string,
    unitOfCurrency: UnitOfCurrency,
    items: InvoiceItemForm[]
}

export type InvoiceItemForm = {
    prepServiceId?: string | undefined | null,
    groupName: string,
    serviceName: string,
    unitPrice: number,
    discount?: number | undefined | null,
    count: number,
    total: number
}

export type WalletContract = {
    walletId: string,
    warehouseId: string,
    balance: number,
    unitOfCurrency: UnitOfCurrency,
    createdAt: Date,
    updatedAt: Date
}

export type CreditCardContract = {
    creditCardId: string,
    customerId: string,
    maskedCardNumber: string,
    identifier: string,
    vault: CreditCardVault,
    isDefault: boolean,
    type: string,
    expirationYear: number,
    expirationMonth: number,
    ownerUserId: string,
    createdAt: Date,
    owner: UserContract
}

/*
public record TransactionHistoryContract
{
    public Guid TransactionHistoryId { get; set; }
    public Guid? WalletId { get; set; }
    public decimal? Debit { get; set; }
    public decimal? Credit { get; set; }
    public decimal Balance { get; set; }
    public string UnitOfCurrency { get; set; }
    public string Description { get; set; }
    public DateTimeOffset CreatedAt { get; set; }

    public virtual CustomerContract Customer { get; set; }
    public virtual InvoiceContract? Invoice { get; set; }
}
*/
export type TransactionHistoryContract = {
    transactionHistoryId: string,
    transactionCode: string,
    walletId: string | undefined,
    debit: number | undefined,
    credit: number | undefined,
    balance: number,
    unitOfCurrency: UnitOfCurrency,
    description: string,
    createdAt: Date,
    customer: CustomerContract,
    invoice: InvoiceContract | undefined
}

export type StripeSetupIntentContract = {
    clientSecret: string
}

/*

public record CreditRequestContract : IOwnedContract
{
    public Guid CreditRequestId { get; set; }
    public string RequestCode { get; set; }
    public Guid WarehouseId { get; set; }
    public Guid CustomerId { get; set; }
    public Guid OwnerUserId { get; set; }
    public Guid? UpdateUserId { get; set; }
    public decimal Fee { get; set; }
    public decimal RequestedAmount { get; set; }
    public decimal? ApprovedAmount { get; set; }
    public string? ReceiptPath { get; set; }
    public string UnitOfCurrency { get; set; }
    public PaymentMethod PaymentMethod { get; set; }
    public CreditRequestStatus Status { get; set; }
    public DateTimeOffset? UpdatedAt { get; set; }
    public DateTimeOffset CreatedAt { get; set; }

    public virtual UserContract Owner { get; set; }
    public virtual CustomerContract Customer { get; set; }
    public virtual WarehouseContract Warehouse { get; set; }
    public virtual UserContract? UpdateUser { get; set; }
}
*/

export type CreditRequestContract = {
    creditRequestId: string,
    requestCode: string,
    warehouseId: string,
    customerId: string,
    ownerUserId: string,
    updateUserId: string | undefined,
    fee: number,
    requestedAmount: number,
    approvedAmount: number | undefined,
    receiptPath: string | undefined,
    unitOfCurrency: UnitOfCurrency,
    paymentMethod: PaymentMethod,
    status: CreditRequestStatus,
    updatedAt: Date | undefined,
    createdAt: Date,
    owner: UserContract,
    customer: CustomerContract,
    warehouse: WarehouseContract,
    updateUser: UserContract | undefined
}