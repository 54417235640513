import { useEffect } from "react";
import { Row, Col, Card } from "reactstrap";
import { Link, useSearchParams } from "react-router-dom";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useTranslation } from "react-i18next";
import Copyright from "Components/Copyright";
import { patchVerifyEmail } from "api/user";
import BusyOverlay from "Components/Common/BusyOverlay";
import { useMutation } from "@tanstack/react-query";

const VerifyEmail = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token");

    const { setPageTitle } = useMetadata();
    const { t } = useTranslation();

    setPageTitle(t("Verify Email Address"));

    const verifyEmailMutation = useMutation({
        mutationFn: patchVerifyEmail
    });

    useEffect(() => {
        if (token) {
            verifyEmailMutation.mutate({
                token: token
            });
        }
    }, [token]);

    return <>
        <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
            <div className="bg-overlay"></div>
            <div className="auth-page-content overflow-hidden pt-lg-5">
                <Row>
                    <Col lg={4} className="mx-auto">
                        <BusyOverlay busy={verifyEmailMutation.isPending} backgroundColor="body-secondary" spinnerColor="tenant-primary" opaque>
                            <Card className="overflow-hidden m-0">
                                    {verifyEmailMutation.isSuccess ? <>
                                        <div className="p-lg-5 p-4 text-center">
                                            <div className="avatar-lg mx-auto mt-2">
                                                <div className="avatar-title bg-light text-success display-3 rounded-circle">
                                                    <i className="ri-checkbox-circle-fill"></i>
                                                </div>
                                            </div>
                                            <div className="mt-4 pt-2">
                                                <h4>{t("Well done !")}</h4>
                                                <p className="text-muted mx-4">{t("Your email successfully verified")}</p>
                                                <div className="mt-4">
                                                    <Link to="/login" className="btn btn-success w-100">{t("Login to Dashboard")}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : 
                                    <>
                                        <div className="p-lg-5 p-4 text-center">
                                            <div className="avatar-lg mx-auto mt-2">
                                                <div className="avatar-title bg-light text-danger display-3 rounded-circle">
                                                    <i className="ri-close-circle-fill"></i>
                                                </div>
                                            </div>
                                            <div className="mt-4 pt-2">
                                                <h4>{t("Oh no!")}</h4>
                                                <p className="text-muted mx-4">{t("Email verification failed")}</p>
                                            </div>
                                        </div>
                                    </>}
                            </Card>
                        </BusyOverlay>
                    </Col>
                </Row>
            </div>
            <footer className="footer">
                <div className="text-center">
                    <Copyright />
                </div>
            </footer>
        </div>
    </>;
};

export default VerifyEmail;
