import axios, { AxiosResponse, AxiosRequestConfig, AxiosRequestTransformer, type AxiosError, type AxiosInstance } from "axios";

import config from "config";
import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { ApiError } from "./types";
import { logError } from "./logger";
import { setError } from "slices/tenant/reducer";
import { getLoggedInUser } from "./localStorage";
import store from "slices";
import { toQueryString } from "./string";

const dateToStringTransformer = (data: any): any => {
    if (data instanceof Date) {
        // do your specific formatting here
        return moment(data).toISOString(true);
    }

    if (Array.isArray(data)) {
        return data.map(dateToStringTransformer)
    }

    if (typeof data === 'object' && data !== null) {
        var entries = Object.entries(data).map(([key, value]) => [key, dateToStringTransformer(value)]);
        return Object.fromEntries(entries)
    }

    return data;
};

const readResponseData = async <T>(response: AxiosResponse<T, any>): Promise<T> => {
    if (response.config.responseType === "blob") {
        return JSON.parse(await (response.data as Blob).text()) as T;
    }
    else {
        return response.data as T;
    }
}

/**
 * Represents an API client that provides methods for making HTTP requests.
 */
class APIClient {
    private instance: AxiosInstance;
    /**
     *
     */
    constructor({ enableToasts }: { enableToasts: boolean } = { enableToasts: true }) {
        this.instance = axios.create({
            transformRequest: [dateToStringTransformer, ...axios.defaults.transformRequest as AxiosRequestTransformer[]],
            //transformResponse: [dateStringToDateTransformer, ...axios.defaults.transformResponse as AxiosResponseTransformer[]],
            baseURL: config.api.API_URL,
            headers: {
                common: {
                    "X-App-Name": "TylocWeb"
                },
                get: {
        
                },
                post: {
                    "Content-Type": "application/json"
                },
                put: {
                    "Content-Type": "application/json"
                },
                patch: {
                    "Content-Type": "application/json"
                },
                delete: {
        
                }
            }
        })
        
        this.instance.interceptors.request.use((request) => {
            const user = getLoggedInUser();
            const token = user?.token;
            if (token) 
                request.headers.Authorization = "Bearer " + token;
        
            return request;
        });
        
        // intercepting to capture errors
        this.instance.interceptors.response.use(
            (response) => response,
            async (error: AxiosError<FaultResponse>) => {
                if (error.response == null) {
                    const errorMessage = `${error.name}: [${error.code}] ${error.message}`;
        
                    logError("API request failed", {
                        error
                    });
                    
                    store.dispatch(setError("Connection failure"));
        
                    return Promise.reject({ status: 0, message: "Connection failure" } as ApiError);
                }
                else if (error.response.status === 422) {
                    const data = await readResponseData(error.response);
                    const errorMessage = data.detail;
                    if (enableToasts) {
                        toast.error(`${data.title}: ${data.detail || ""}`);
                    }
        
                    logError(`API returns status 422: [${data.title}] ${data.detail || ""}`, {
                        error: data
                    });
                    
                    return Promise.reject({ status: error.response.status, message: errorMessage } as ApiError);
                }
                else if (error.response.status === 401) {
                    toast.error("Authorization failed");
        
                    logError(`API returns unauthorized`, {});
        
                    return Promise.reject({ status: error.response.status, message: "Authorization failed" } as ApiError);
                }
                else if (error.response.status === 404) {
                    return Promise.reject({ status: error.response.status, message: "Not Found" } as ApiError);
                }
                else if (error.response.status >= 400 && error.response.status < 500) {
                    const data = await readResponseData(error.response);
                    const errorMessage = _.sample(data.errors)?.[0];
                    if (enableToasts) {
                        toast.error(`[${data.title}] ${errorMessage || ""}`);
                    }
        
                    logError(`API returns status ${error.response.status}: ${errorMessage}`, {
                        error: data
                    });
        
                    return Promise.reject({ status: error.response.status, message: errorMessage } as ApiError);
                } 
                else if (error.response.status >= 500) {
                    const data = await readResponseData(error.response);
                    const errorMessage = data.detail;

                    if (enableToasts) {
                        toast.error(`[${data.title}] ${data.detail || ""}`);
                    }
        
                    logError(`API server error: ${errorMessage}`, {
                        error: data
                    });
        
                    return Promise.reject({ status: error.response.status, message: errorMessage } as ApiError);
                }
            }
        );

    }
    /**
     * Sends a GET request to the specified URL with optional query parameters.
     * @param url - The URL to send the GET request to.
     * @param params - Optional query parameters to include in the request.
     * @returns A Promise that resolves to the response data.
     */
    get = async <T = any,>(url: string, params?: any): Promise<T> => {
        if (!params) {
            const response = await this.instance.get<T>(url);
            return response?.data;
        }

        const queryString = toQueryString(params);
        const response = await this.instance.get<T>(`${url}?${queryString}`);
        return response?.data;
    };

    getFile = async (url: string): Promise<{ blob: Blob, fileName: string }> => {
        const response = await this.instance.get(url, { responseType: "blob"});
        const disposition = (response.headers["content-disposition"] as (string | undefined));
        let fileName = "file";

        if (disposition) {
            const startIndex = (disposition?.indexOf("filename=") ?? -1) + 9;
            const endIndex = disposition.indexOf(";", startIndex) ?? disposition.length;
            fileName = disposition.substring(startIndex, endIndex);
        }
        
        return {
            blob: response.data as Blob,
            fileName: fileName
        };
    }

    /**
     * Sends a POST request to the specified URL with the provided data.
     * @param url - The URL to send the request to.
     * @param data - The data to send with the request.
     * @returns A Promise that resolves to the AxiosResponse object.
     */
    post = (url: string, data: any): Promise<AxiosResponse> => {
        return this.instance.post(url, data);
    };

    /**
     * Sends a PATCH request to the specified URL with the provided data.
     * @param url - The URL to send the PATCH request to.
     * @param data - The data to send in the PATCH request.
     * @returns A Promise that resolves to the AxiosResponse object.
     */
    patch = (url: string, data: any): Promise<AxiosResponse> => {
        return this.instance.patch(url, data);
    };

    /**
     * Sends a PUT request to the specified URL with the provided data.
     * @param url - The URL to send the request to.
     * @param data - The data to send in the request body.
     * @returns A Promise that resolves to the AxiosResponse object.
     */
    put = (url: string, data: any): Promise<AxiosResponse> => {
        return this.instance.put(url, data);
    };

    /**
     * Sends a DELETE request to the specified URL.
     * 
     * @param url - The URL to send the request to.
     * @param config - Optional configuration for the request.
     * @returns A Promise that resolves to the AxiosResponse.
     */
    delete = (url: string, config?: AxiosRequestConfig ): Promise<AxiosResponse> => {
        return this.instance.delete(url, { ...config });
    };
}

/**
 * Represents a fault response from an API.
 */
export type FaultResponse = {
    type: string;
    title: string;
    status: number;
    detail?: string;
    errors?: {
        [key: string]: string[];
    };
};

/**
 * Represents a paged list of items.
 * @template T The type of items in the list.
 */
export type PagedList<T> = {
    items: T[],
    totalCount: number,
    currentPage: number
}

export type PagerQuery = {
    page: number,
    pageSize: number
}

export type SorterQuery = {
    sortBy?: string,
    sortingOrder?: SortingDirection
}

/**
 * Represents a date range with optional start and end dates.
 */
export type DateRange = {
    start?: Date,
    end?: Date
}

/**
 * Represents a numeric range with optional minimum and maximum values.
 */
export type NumericRange = {
    min?: number,
    max?: number
}

export type SortingDirection = "ascending" | "descending";

export { APIClient, toQueryString };