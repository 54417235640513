/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-empty-pattern */
import BusyOverlay from "Components/Common/BusyOverlay";
import CompanySelect from "Components/EntitySelects/CompanySelect";
import CountrySelect from "Components/EntitySelects/CountrySelect";
import StateSelect from "Components/EntitySelects/StateSelect";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import ValidatedInput from "Components/Form/Validated/ValidatedInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import type { CountryContract, UnitOfCurrency, UnitOfLength, UnitOfWeight } from "api/types/contracts/common";
import type { WarehouseContract, WarehouseForm, WarehouseStatus, WarehouseType } from "api/types/contracts/warehouses";
import classnames from "classnames";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Nav, NavItem, TabContent, TabPane, Row, Col, Label, NavLink, Input, Button, FormGroup } from "reactstrap";
import * as Yup from "yup";
import { DangerAlert } from "Components/Alerts";
import UnitOfLengthSelect from "Components/EnumSelects/UnitOfLengthSelect";
import WarehouseTypeSelect from "Components/EnumSelects/WarehouseTypeSelect";
import UnitOfWeightSelect from "Components/EnumSelects/UnitOfWeightSelect";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import RequiredLabel from "Components/Common/RequiredLabel";
import Restricted from "Components/Common/Restricted";
import { useTenant } from "Components/Hooks/TenantHooks";
import { useProfile } from "Components/Hooks/ProfileHooks";
import UnitOfCurrencySelect from "Components/EnumSelects/UnitOfCurrencySelect";
import Checkbox from "Components/Form/Checkbox";
import TextInput from "Components/Form/TextInput";
import NumberInput from "Components/Form/NumberInput";
import SelectInput from "Components/Form/SelectInput";
import { useMutation } from "@tanstack/react-query";
import { postWarehouse, putWarehouse } from "api/warehouse";
import LinedHeader from "Components/Common/LinedHeader";
import { toast } from "react-toastify";
import PhoneInput from "Components/Form/PhoneInput";

type WizardFormProps = {
    warehouse?: WarehouseContract,
    onSuccess: VoidFunction
}

const testModeOptions = [{
    label: "Enabled",
    value: true
}, {
    label: "Disabled",
    value: false
}];

const defaultForm: Partial<WarehouseForm> = {
    warehouseStatus: "active",
    warehouseType: "normal",
    settings: {
        unitOfLength: "inch",
        unitOfWeight: "lbs",
        unitOfCurrency: "usd",
        enableStripe: false,
        enablePayoneer: false,
        enableWise: false,
        enableWireTransfer: false,
        enableZelle: false,
        creditBalanceRequirement: false,
        invoice: {
            paymentSupportEmail: "",
            invoicePrefix: "INV-"
        }
    }
};

const createWarehouseForm = (warehouse: WarehouseContract | undefined): WarehouseForm | undefined => {
    return warehouse ? {
        companyId: warehouse.company.companyId,
        name: warehouse.name,
        code: warehouse.code,
        warehouseType: warehouse.warehouseType,
        warehouseStatus: warehouse.warehouseStatus,
        address: {
            ...warehouse.address
        },
        settings: {
            ...warehouse.settings
        }
    } as WarehouseForm : undefined
};

const WizardForm = (props: WizardFormProps) => {
    const { company } = useTenant();
    const { canRead } = useProfile();
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const warehouseForm = useMemo(() => createWarehouseForm(props.warehouse) || defaultForm, [props.warehouse]);
    const [activeTab, setActiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState<number[]>([]);
    const [selectedCountry, setSelectedCountry] = useState<CountryContract>();
    const [selectedCompany, setSelectedCompany] = useState(company);

    const { mutate: saveWarehouse, isPending: saveLoading, isError: saveIsError, error: saveError } = useMutation({
        mutationFn: async (params: WarehouseForm) => {
            if (props.warehouse) {
                await putWarehouse({ warehouseId: props.warehouse.warehouseId, warehouse: params });
            }
            else {
                await postWarehouse({ warehouse: params });
            }
        },
        mutationKey: ["save-warehouse"],
        onSuccess: () => {
            if (props.warehouse?.warehouseId) {
                toast.success(t("Warehouse updated"));
            }
            else {
                toast.success(t("Warehouse created"));
            }

            props.onSuccess();
        }
    });

    useEffect(() => {
        if (!validation.values.companyId && company) {
            validation.setFieldValue("companyId", company.companyId);
        }
    }, [company]);
    
    setPageTitle(`${t(props.warehouse ? "Update Warehouse" : "Create Warehouse")} - ${t("Management")}`);
    
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: warehouseForm,
        validationSchema: Yup.object<WarehouseForm, TypedShape<WarehouseForm>>({
            companyId: Yup.string().required(t("Please select a Company")),
            address: Yup.object({
                countryCode: Yup.string().required(t("Please select a Country")),
                state: Yup.string().required(t("Please enter a State")),
                stateCode: Yup.string().notRequired(),
                zipCode: Yup.string().required(t("Please enter a Zip Code")),
                address1: Yup.string().required(t("Please enter an Address")),
                address2: Yup.string().notRequired(),
                city: Yup.string().required(t("Please enter a City")),
                email: Yup.string().email(t("Please enter a Valid Email")).required(t("Please enter an Email")),
                phone: Yup.string().required(t("Please enter a Contact Number")).min(10, t("Please enter a valid Phone Number")),
                province: Yup.string().notRequired()
            }).required(),
            name: Yup.string().required(t("Please enter Warehouse Name")),
            code: Yup.string().notRequired(),
            warehouseType: Yup.string<WarehouseType>().required(),
            warehouseStatus: Yup.string<WarehouseStatus>().required(),
            settings: Yup.object({
                unitOfLength: Yup.string<UnitOfLength>().required(),
                unitOfWeight: Yup.string<UnitOfWeight>().required(),
                unitOfCurrency: Yup.string<UnitOfCurrency>().required(),
                creditBalanceRequirement: Yup.boolean().required(),
                enableStripe: Yup.boolean().required(),
                enablePayoneer: Yup.boolean().required(),
                enableWise: Yup.boolean().required(),
                enableWireTransfer: Yup.boolean().required(),
                enableZelle: Yup.boolean().required(),
                stripe: Yup.object({
                    publishableKey: Yup.string().required(t("Please enter Stripe Publishable Key")).when(([], schema, { context }) => 
                        context.settings.enableStripe ? schema : schema.notRequired()),
                    secretKey: Yup.string().required(t("Please enter Stripe Secret Key")).when(([], schema, { context }) => 
                        context.settings.enableStripe ? schema : schema.notRequired()),
                    testMode: Yup.boolean().required(t("Please select Test Mode")).when(([], schema, { context }) => 
                        context.settings.enableStripe ? schema : schema.notRequired()),
                    processingFee: Yup.number().required(t("Please enter Processing Fee")).when(([], schema, { context }) => 
                        context.settings.enableStripe ? schema : schema.notRequired())
                }).notRequired(),
                wise: Yup.object({
                    accountName: Yup.string().required(t("Please enter wise account name")).when(([], schema, { context }) => 
                        context.settings.enableWise ? schema : schema.notRequired()),
                    email: Yup.string().required(t("Please enter wise email")).when(([], schema, { context }) => 
                        context.settings.enableWise ? schema : schema.notRequired()),
                    processingFee: Yup.number().required(t("Please enter Processing Fee")).when(([], schema, { context }) => 
                        context.settings.enableWise ? schema : schema.notRequired()),
                }).notRequired(),
                payoneer: Yup.object({
                    accountName: Yup.string().required(t("Please enter Payoneer account name")).when(([], schema, { context }) => 
                        context.settings.enablePayoneer ? schema : schema.notRequired()),
                    email: Yup.string().required(t("Please enter payoeneer email")).when(([], schema, { context }) => 
                        context.settings.enablePayoneer ? schema : schema.notRequired()),
                    processingFee: Yup.number().required(t("Please enter Processing Fee")).when(([], schema, { context }) => 
                        context.settings.enablePayoneer ? schema : schema.notRequired())
                }).notRequired(),
                wireTransfer: Yup.object({
                    bankName: Yup.string().required(t("Please enter Bank Name")).when(([], schema, { context }) => 
                        context.settings.enableWireTransfer ? schema : schema.notRequired()),
                    bankAddress: Yup.string().required(t("Please enter Bank Address")).when(([], schema, { context }) => 
                        context.settings.enableWireTransfer ? schema : schema.notRequired()),
                    swiftCode: Yup.string().required(t("Please enter Swift Code")).when(([], schema, { context }) => 
                        context.settings.enableWireTransfer ? schema : schema.notRequired()),
                    processingFee: Yup.number().required(t("Please enter Processing Fee")).when(([], schema, { context }) => 
                        context.settings.enableWireTransfer ? schema : schema.notRequired()),
                    recipientName: Yup.string().required(t("Please enter Recipient Name")).when(([], schema, { context }) => 
                        context.settings.enableWireTransfer ? schema : schema.notRequired()),
                    routingNumber: Yup.string().required(t("Please enter Routing Number")).when(([], schema, { context }) => 
                        context.settings.enableWireTransfer ? schema : schema.notRequired()),
                    accountNumber: Yup.string().required(t("Please enter Account Number")).when(([], schema, { context }) => 
                        context.settings.enableWireTransfer ? schema : schema.notRequired()),
                    recipientAddress: Yup.string().required(t("Please enter Recipient Address")).when(([], schema, { context }) => 
                        context.settings.enableWireTransfer ? schema : schema.notRequired())
                }).notRequired(),
                zelle: Yup.object({
                    emailOrPhoneNumber: Yup.string().required("Please enter Email/Phone Number").when(([], schema, { context }) => 
                        context.settings.enableZelle ? schema : schema.notRequired()),
                    processingFee: Yup.number().required(t("Please enter Processing Fee")).when(([], schema, { context }) => 
                        context.settings.enableZelle ? schema : schema.notRequired())
                }).notRequired(),
                invoice: Yup.object({
                    paymentSupportEmail: Yup.string().email().required(t("Please enter Payment Support Email")),
                    invoicePrefix: Yup.string().required(t("Please enter Invoice Prefix")),
                }).required()
            }).required()
        }),
        onSubmit: (values) => {
            const warehouse = values as WarehouseForm;
            
            saveWarehouse(warehouse);
        },
    });

    useEffect(() => {
        if (selectedCountry) {
            validation.setFieldValue("unitOfCurrency", selectedCountry.currency.toLowerCase());
        }
    }, [selectedCountry])
    

    const useCompanyAddress = () => {
        const comp = selectedCompany ?? company;

        if (comp) {
            validation.setFieldValue("address.countryCode", comp.contactAddress.countryCode);
            validation.setFieldValue("address.state", comp.contactAddress.state);
            validation.setFieldValue("address.stateCode", comp.contactAddress.stateCode);
            validation.setFieldValue("address.zipCode", comp.contactAddress.zipCode);
            validation.setFieldValue("address.address1", comp.contactAddress.address1);
            validation.setFieldValue("address.address2", comp.contactAddress.address2);
            validation.setFieldValue("address.city", comp.contactAddress.city);
            validation.setFieldValue("address.phone", comp.contactAddress.phone);
            validation.setFieldValue("address.email", comp.contactAddress.email);
        }
    };
    
    function toggleTab(tab: number) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab];

            setActiveTab(tab);
            setPassedSteps(modifiedSteps);
        }
    }

    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <div className="step-arrow-nav mb-4">
                <Nav className="nav-pills custom-nav nav-justified" role="tablist">
                    <NavItem>
                        <NavLink href="#" className={classnames({
                            active: activeTab === 1,
                            done: passedSteps.includes(1),
                        })} onClick={() => toggleTab(1)}>{t("General Information")}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#" className={classnames({
                            active: activeTab === 2,
                            done: passedSteps.includes(2),
                        })} onClick={() => toggleTab(2)}>{t("Settings")}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#" className={classnames({
                            active: activeTab === 3,
                            done: passedSteps.includes(3),
                        })} onClick={() => toggleTab(3)}>{t("Payment")}</NavLink>
                    </NavItem>
                </Nav>
            </div>
            <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                    <div>
                        <div className="mb-4">
                            <div>
                                <h5 className="mb-1">{t("General Information")}</h5>
                                <p className="text-muted">
                                    {t("In this part warehouse general information exists")}
                                </p>
                            </div>
                        </div>
                        <Row className="g-3">
                            <Restricted require="management.company" read>
                                <Col lg={6}>
                                    <RequiredLabel htmlFor="companyId">{t("Company")}</RequiredLabel>
                                    {props.warehouse?.company ? 
                                        <Input type="text" readOnly className="bg-light" defaultValue={props.warehouse.company.name} /> 
                                    : 
                                        <ValidationWrapper validation={validation} field="companyId">
                                            <CompanySelect onSelect={setSelectedCompany} />
                                        </ValidationWrapper>
                                    }
                                </Col>
                            </Restricted>
                            <Col lg={canRead("management.company") ? 6 : 12}>
                                <RequiredLabel htmlFor="name">{t("Name")}</RequiredLabel>
                                <ValidatedInput validation={validation} field="name" placeholder="Enter Name" />
                            </Col>
                            <Col lg={6}>
                                <RequiredLabel htmlFor="type">{t("Type")}</RequiredLabel>
                                <ValidationWrapper validation={validation} field="warehouseType">
                                    <WarehouseTypeSelect />
                                </ValidationWrapper>
                            </Col>
                            <Col lg={6}>
                                <Label htmlFor="code">{t("Warehouse Code")}</Label>
                                <ValidatedInput validation={validation} field="code" placeholder="Enter Code" />
                            </Col>
                            <Col xs={12}>
                                <LinedHeader after={<Button onClick={useCompanyAddress} size="sm" color="ghost-info" style={{ whiteSpace: "nowrap" }}>
                                    {t("Use Company Address")}
                                </Button>}>{t("Address")}</LinedHeader>
                            </Col>
                            <Col lg={6}>
                                <RequiredLabel htmlFor="address.countryCode">{t("Country")}</RequiredLabel>
                                <ValidationWrapper validation={validation} field="address.countryCode">
                                    <CountrySelect onSelect={setSelectedCountry} />
                                </ValidationWrapper>
                            </Col>
                            <Col lg={6}>
                                <RequiredLabel htmlFor="address.state">{t("State")}</RequiredLabel>
                                <ValidationWrapper validation={validation} field="address.state">
                                    <StateSelect countryCode={selectedCountry?.code || validation.values.address?.countryCode} onSelect={val => {
                                        validation.setFieldValue("address.stateCode", val?.code);
                                    }} />
                                </ValidationWrapper>
                            </Col>
                            <Col lg={6}>
                                <RequiredLabel htmlFor="address.city">{t("City")}</RequiredLabel>
                                <ValidatedInput validation={validation} field="address.city" placeholder="Enter City" />
                            </Col>
                            <Col lg={6}>
                                <RequiredLabel htmlFor="address.zipCode">{t("Zip Code")}</RequiredLabel>
                                <ValidatedInput validation={validation} field="address.zipCode" placeholder="Enter Zip code" />
                            </Col>
                            <Col lg={6}>
                                <RequiredLabel htmlFor="address.address1">{t("Address 1")}</RequiredLabel>
                                <ValidatedInput validation={validation} field="address.address1" placeholder="Enter Address" />
                            </Col>
                            <Col lg={6}>
                                <Label htmlFor="address.address2">{t("Address 2")}</Label>
                                <ValidatedInput validation={validation} field="address.address2" placeholder="Enter Address" />
                            </Col>
                            <Col lg={6}>
                                <RequiredLabel htmlFor="address.phone">{t("Phone Number")}</RequiredLabel>
                                <ValidationWrapper validation={validation} field="address.phone">
                                    <PhoneInput country={selectedCountry?.iso2Code} />
                                </ValidationWrapper>
                            </Col>
                            <Col lg={6}>
                                <RequiredLabel htmlFor="address.email">{t("Email")}</RequiredLabel>
                                <ValidatedInput validation={validation} field="address.email" placeholder="Enter Email" />
                            </Col>
                        </Row>
                    </div>
                    <div className="d-flex justify-content-end gap-3 mt-4">
                        <ValidatorButton type="button" onClick={() => toggleTab(2)} 
                            validation={validation} fields={["companyId", "name", "address"]}
                            className="btn btn-success btn-label right ms-auto nexttab nexttab">
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                            {t("Settings")}
                        </ValidatorButton>
                    </div>
                </TabPane>
                <TabPane tabId={2}>
                    <div>
                        <div className="mb-4">
                            <div>
                                <h5 className="mb-1">{t("Settings")}</h5>
                                <p className="text-muted">
                                    {t("In this part warehouse settings exists")}
                                </p>
                            </div>
                        </div>
                        <div className="mb-3">
                            <Row className="g-3">
                                <Col xs={4}>
                                    <Label htmlFor="settings.unitOfLength">{t("Unit of Length")}</Label>
                                    <ValidationWrapper validation={validation} field="settings.unitOfLength">
                                        <UnitOfLengthSelect />
                                    </ValidationWrapper>
                                </Col>
                                <Col xs={4}>
                                    <Label htmlFor="settings.unitOfWeight">{t("Unit of Weight")}</Label>
                                    <ValidationWrapper validation={validation} field="settings.unitOfWeight">
                                        <UnitOfWeightSelect />
                                    </ValidationWrapper>
                                </Col>
                                <Col xs={4}>
                                    <Label htmlFor="settings.unitOfCurrency">{t("Currency")}</Label>
                                    <ValidationWrapper validation={validation} field="settings.unitOfCurrency">
                                        <UnitOfCurrencySelect />
                                    </ValidationWrapper>
                                </Col>
                                <Col xs={6}>
                                    <ValidationWrapper validation={validation} field="settings.creditBalanceRequirement">
                                        <Checkbox>
                                            <span>{t("Credit Balance Requirement")}</span>
                                            <br />
                                            <span className="text-muted fw-light">
                                                {t("paragraphs:CreditBalanceRequirementHelpText")}
                                            </span>
                                        </Checkbox>
                                    </ValidationWrapper>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end gap-3 mt-4">
                        <Button type="button" color="light" className="btn-label previestab" onClick={() => toggleTab(1)}>
                            <i className="ri-arrow-left-line label-icon align-middle fs-16"></i>
                            {t("Back")}
                        </Button>
                        <ValidatorButton type="button" onClick={() => toggleTab(3)} 
                            validation={validation} fields={["companyId", "name", "address", "settings.unitOfLength", "settings.unitOfWeight", "settings.unitOfCurrency"]}
                            className="btn btn-success btn-label right nexttab nexttab">
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                            {t("Payment Settings")}
                        </ValidatorButton>
                    </div>
                </TabPane>
                <TabPane tabId={3}>
                    <div>
                        <div className="mb-4">
                            <div>
                                <h5 className="mb-1">{t("Payment Settings")}</h5>
                                <p className="text-muted">
                                    {t("paragraphs:WarehousePaymentSettingsDescription")}
                                </p>
                            </div>
                        </div>
                        <div className="mb-3">
                            <Row>
                                <Col lg={6}>
                                    <div className="my-2">
                                        <div>
                                            <LinedHeader after={<ValidationWrapper validation={validation} field="settings.enableStripe">
                                                <Checkbox>
                                                    {t("Enable")}
                                                </Checkbox>
                                            </ValidationWrapper>}>{t("Stripe Integration Settings")}</LinedHeader>
                                            <Row className="g-3">
                                                <Col xs={12}>
                                                    <Label htmlFor="settings.stripe.publishableKey">{t("Publishable Key")}</Label>
                                                    <ValidationWrapper validation={validation} field="settings.stripe.publishableKey">
                                                        <TextInput disabled={!validation.values.settings?.enableStripe} placeholder={t("Enter Publishable Key")} />
                                                    </ValidationWrapper>
                                                </Col>
                                                <Col xs={12}>
                                                    <Label htmlFor="settings.stripe.secretKey">{t("Secret Key")}</Label>
                                                    <ValidationWrapper validation={validation} field="settings.stripe.secretKey">
                                                        <TextInput disabled={!validation.values.settings?.enableStripe} placeholder={t("Enter Secret Key")} />
                                                    </ValidationWrapper>
                                                </Col>
                                                <Col xs={12}>
                                                    <Label htmlFor="settings.stripe.processingFee">{t("Processing Fee")}</Label>
                                                    <ValidationWrapper validation={validation} field="settings.stripe.processingFee">
                                                        <NumberInput disabled={!validation.values.settings?.enableStripe} placeholder={t("Enter Processing Fee")} />
                                                    </ValidationWrapper>
                                                    <span className="form-text">{t("paragraphs:ProcessingFeeHelpText")}</span>
                                                </Col>
                                                <Col xs={12}>
                                                    <FormGroup row noMargin>
                                                        <Label className="col-auto" htmlFor="settings.stripe.testMode">{t("Test Mode")}</Label>
                                                        <Col>
                                                            <ValidationWrapper validation={validation} field="settings.stripe.testMode">
                                                                <SelectInput disabled={!validation.values.settings?.enableStripe} display="inline-radio" options={testModeOptions} />
                                                            </ValidationWrapper>
                                                        </Col>
                                                    </FormGroup>
                                                    <span className="form-text">{t("paragraphs:StripeTestModeHelpText")}</span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="my-2">
                                        <div>
                                            <LinedHeader after={<ValidationWrapper validation={validation} field="settings.enableWise">
                                                <Checkbox>
                                                    {t("Enable")}
                                                </Checkbox>
                                            </ValidationWrapper>}>{t("Wise Account Details")}</LinedHeader>
                                            <Row className="g-3">
                                                <Col xs={12}>
                                                    <Label htmlFor="settings.wise.accountName">{t("Account Name")}</Label>
                                                    <ValidationWrapper validation={validation} field="settings.wise.accountName">
                                                        <TextInput disabled={!validation.values.settings?.enableWise} placeholder={t("Enter Account Name")} />
                                                    </ValidationWrapper>
                                                    <span className="form-text">{t("paragraphs:WiseAccountNameHelpText")}</span>
                                                </Col>
                                                <Col xs={12}>
                                                    <Label htmlFor="settings.wise.email">{t("Account Email")}</Label>
                                                    <ValidationWrapper validation={validation} field="settings.wise.email">
                                                        <TextInput disabled={!validation.values.settings?.enableWise} placeholder={t("Enter Account Email")} />
                                                    </ValidationWrapper>
                                                    <span className="form-text">{t("paragraphs:WiseAccountEmailHelpText")}</span>
                                                </Col>
                                                <Col xs={12}>
                                                    <Label htmlFor="settings.wise.processingFee">{t("Processing Fee")}</Label>
                                                    <ValidationWrapper validation={validation} field="settings.wise.processingFee">
                                                        <NumberInput disabled={!validation.values.settings?.enableWise} placeholder={t("Enter Processing Fee")} />
                                                    </ValidationWrapper>
                                                    <span className="form-text">{t("paragraphs:ProcessingFeeHelpText")}</span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <div className="my-2">
                                        <div>
                                            <LinedHeader after={<ValidationWrapper validation={validation} field="settings.enablePayoneer">
                                                <Checkbox>
                                                    {t("Enable")}
                                                </Checkbox>
                                            </ValidationWrapper>}>{t("Payoneer Account Details")}</LinedHeader>
                                            <Row className="g-3">
                                                <Col xs={12}>
                                                    <Label htmlFor="settings.payoneer.accountName">{t("Account Name")}</Label>
                                                    <ValidationWrapper validation={validation} field="settings.payoneer.accountName">
                                                        <TextInput disabled={!validation.values.settings?.enablePayoneer} placeholder={t("Enter Account Name")} />
                                                    </ValidationWrapper>
                                                    <span className="form-text">{t("paragraphs:PayoneerAccountNameHelpText")}</span>
                                                </Col>
                                                <Col xs={12}>
                                                    <Label htmlFor="settings.payoneer.email">{t("Account Email")}</Label>
                                                    <ValidationWrapper validation={validation} field="settings.payoneer.email">
                                                        <TextInput disabled={!validation.values.settings?.enablePayoneer} placeholder={t("Enter Account Email")} />
                                                    </ValidationWrapper>
                                                    <span className="form-text">{t("paragraphs:PayoneerAccountEmailHelpText")}</span>
                                                </Col>
                                                <Col xs={12}>
                                                    <Label htmlFor="settings.payoneer.processingFee">{t("Processing Fee")}</Label>
                                                    <ValidationWrapper validation={validation} field="settings.payoneer.processingFee">
                                                        <NumberInput disabled={!validation.values.settings?.enablePayoneer} placeholder={t("Enter Processing Fee")} />
                                                    </ValidationWrapper>
                                                    <span className="form-text">{t("paragraphs:ProcessingFeeHelpText")}</span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className="my-2">
                                        <div>
                                            <LinedHeader after={<ValidationWrapper validation={validation} field="settings.enableZelle">
                                                <Checkbox>
                                                    {t("Enable")}
                                                </Checkbox>
                                            </ValidationWrapper>}>{t("Zelle Account Details")}</LinedHeader>
                                            <Row className="g-3">
                                                <Col xs={12}>
                                                    <Label htmlFor="settings.zelle.emailOrPhoneNumber">{t("Email/Phone Number")}</Label>
                                                    <ValidationWrapper validation={validation} field="settings.zelle.emailOrPhoneNumber">
                                                        <TextInput disabled={!validation.values.settings?.enableZelle} placeholder={t("Enter Email/Phone Number")} />
                                                    </ValidationWrapper>
                                                    <span className="form-text">{t("paragraphs:ZelleEmailOrPhoneNumberHelpText")}</span>
                                                </Col>
                                                <Col xs={12}>
                                                    <Label htmlFor="settings.zelle.processingFee">{t("Processing Fee")}</Label>
                                                    <ValidationWrapper validation={validation} field="settings.zelle.processingFee">
                                                        <NumberInput disabled={!validation.values.settings?.enableZelle} placeholder={t("Enter Processing Fee")} />
                                                    </ValidationWrapper>
                                                    <span className="form-text">{t("paragraphs:ProcessingFeeHelpText")}</span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="my-2">
                                        <div>
                                            <Row>
                                                <Col xs={12}>
                                                    <LinedHeader after={<ValidationWrapper validation={validation} field="settings.enableWireTransfer">
                                                        <Checkbox>
                                                            {t("Enable")}
                                                        </Checkbox>
                                                    </ValidationWrapper>}>{t("Wire Transfer (ACH, SWIFT) Payments")}</LinedHeader>
                                                </Col>
                                                <Col xs={6}>
                                                    <LinedHeader tag="h6">{t("Bank Details")}</LinedHeader>
                                                    <Row className="g-3">
                                                        <Col xs={12}>
                                                            <Label htmlFor="settings.wireTransfer.bankName">{t("Bank Name")}</Label>
                                                            <ValidationWrapper validation={validation} field="settings.wireTransfer.bankName">
                                                                <TextInput disabled={!validation.values.settings?.enableWireTransfer} placeholder={t("Enter Bank Name")} />
                                                            </ValidationWrapper>
                                                            <span className="form-text">{t("paragraphs:WireTransferBankNameHelpText")}</span>
                                                        </Col>
                                                        <Col xs={12}>
                                                            <Label htmlFor="settings.wireTransfer.bankAddress">{t("Bank Address")}</Label>
                                                            <ValidationWrapper validation={validation} field="settings.wireTransfer.bankAddress">
                                                                <TextInput disabled={!validation.values.settings?.enableWireTransfer} placeholder={t("Enter Bank Address")} />
                                                            </ValidationWrapper>
                                                            <span className="form-text">{t("paragraphs:WireTransferBankAddressHelpText")}</span>
                                                        </Col>
                                                        <Col xs={12}>
                                                            <Label htmlFor="settings.wireTransfer.swiftCode">{t("SWIFT Code")}</Label>
                                                            <ValidationWrapper validation={validation} field="settings.wireTransfer.swiftCode">
                                                                <TextInput disabled={!validation.values.settings?.enableWireTransfer} placeholder={t("Enter SWIFT Code")} />
                                                            </ValidationWrapper>
                                                            <span className="form-text">{t("paragraphs:WireTransferSwiftCodeHelpText")}</span>
                                                        </Col>
                                                        <Col xs={12}>
                                                            <Label htmlFor="settings.wireTransfer.processingFee">{t("Processing Fee")}</Label>
                                                            <ValidationWrapper validation={validation} field="settings.wireTransfer.processingFee">
                                                                <NumberInput disabled={!validation.values.settings?.enableWireTransfer} placeholder={t("Enter Processing Fee")} />
                                                            </ValidationWrapper>
                                                            <span className="form-text">{t("paragraphs:ProcessingFeeHelpText")}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={6}>
                                                    <LinedHeader tag="h6">{t("Recipient Details")}</LinedHeader>
                                                    <Row className="g-3">
                                                        <Col xs={12}>
                                                            <Label htmlFor="settings.wireTransfer.recipientName">{t("Recipient Name")}</Label>
                                                            <ValidationWrapper validation={validation} field="settings.wireTransfer.recipientName">
                                                                <TextInput disabled={!validation.values.settings?.enableWireTransfer} placeholder={t("Enter Recipient Name")} />
                                                            </ValidationWrapper>
                                                            <span className="form-text">{t("paragraphs:WireTransferRecipientNameHelpText")}</span>
                                                        </Col>
                                                        <Col xs={12}>
                                                            <Label htmlFor="settings.wireTransfer.routingNumber">{t("Routing Number")}</Label>
                                                            <ValidationWrapper validation={validation} field="settings.wireTransfer.routingNumber">
                                                                <TextInput disabled={!validation.values.settings?.enableWireTransfer} placeholder={t("Enter Routing Number")} />
                                                            </ValidationWrapper>
                                                            <span className="form-text">{t("paragraphs:WireTransferRoutingNumberHelpText")}</span>
                                                        </Col>
                                                        <Col xs={12}>
                                                            <Label htmlFor="settings.wireTransfer.accountNumber">{t("Account Number / IBAN")}</Label>
                                                            <ValidationWrapper validation={validation} field="settings.wireTransfer.accountNumber">
                                                                <TextInput disabled={!validation.values.settings?.enableWireTransfer} placeholder={t("Enter Account Number / IBAN")} />
                                                            </ValidationWrapper>
                                                            <span className="form-text">{t("paragraphs:WireTransferAccountNumberHelpText")}</span>
                                                        </Col>
                                                        <Col xs={12}>
                                                            <Label htmlFor="settings.wireTransfer.recipientAddress">{t("Address")}</Label>
                                                            <ValidationWrapper validation={validation} field="settings.wireTransfer.recipientAddress">
                                                                <TextInput disabled={!validation.values.settings?.enableWireTransfer} placeholder={t("Recipient Address")} />
                                                            </ValidationWrapper>
                                                            <span className="form-text">{t("paragraphs:WireTransferRecipientAddressHelpText")}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <LinedHeader>{t("Invoice Settings")}</LinedHeader>
                            <Row className="g-3">
                                <Col lg={6}>
                                    <Label htmlFor="settings.invoice.paymentSupportEmail">{t("Payment Related Support Email Address")}</Label>
                                    <ValidationWrapper validation={validation} field="settings.invoice.paymentSupportEmail">
                                        <TextInput placeholder={t("Enter Email Address")} />
                                    </ValidationWrapper>
                                    <span className="form-text">{t("paragraphs:InvoiceSupportEmailHelpText")}</span>
                                </Col>
                                <Col lg={6} className="g-3">
                                    <Label htmlFor="settings.invoice.invoicePrefix">{t("Invoice Prefix")}</Label>
                                    <ValidationWrapper validation={validation} field="settings.invoice.invoicePrefix">
                                        <TextInput placeholder={t("Enter Invoice Prefix")} />
                                    </ValidationWrapper>
                                    <span className="form-text">{t("paragraphs:InvoicePrefixHelpText")}</span>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end gap-2">
                        {saveIsError && <DangerAlert className="mb-0 py-2 fs-13">
                            {saveError.message}
                        </DangerAlert>}
                        <Button type="button" color="light" className="btn-label previestab" onClick={() => toggleTab(2)}>
                            <i className="ri-arrow-left-line label-icon align-middle fs-16"></i>
                            {t("Back")}
                        </Button>
                        <BusyOverlay busy={saveLoading} size="sm">
                            <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label nexttab">
                                <i className="ri-save-3-line label-icon align-middle fs-16"></i>
                                {t("Save")}
                            </ValidatorButton>
                        </BusyOverlay>
                    </div>
                </TabPane>
                {/* <TabPane tabId={4}>
                    <div className="text-center">
                        <div className="avatar-md mt-5 mb-4 mx-auto">
                            <div className="avatar-title bg-light text-success display-4 rounded-circle">
                                <i className="ri-checkbox-circle-fill"></i>
                            </div>
                        </div>
                        <h5>{t("Well done")}!</h5>
                        <p className="text-muted">{t("You have successfully created the company")}</p>
                        <Button type="button" color="warning" onClick={() => props.onSuccess?.()}>{t("Close wizard")}</Button>
                    </div>
                </TabPane> */}
            </TabContent>
        </Form>
    </>;
}

export default WizardForm;