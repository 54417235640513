import { useQuery } from "@tanstack/react-query";
import SelectInput from "Components/Form/SelectInput"
import { useThrottle } from "Components/Hooks/HelperHooks";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { getProductSizeList } from "api/productSize";
import { ProductSizeContract } from "api/types/contracts/products";
import { ListProductSizesQuery } from "api/types/queries";
import { EntitySelectInputProps, SelfPopulatedSelectInputRef } from "helpers/types";
import { ForwardedRef, RefAttributes, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { loadList } from "slices/product/size/thunk";
import { useImmer } from "use-immer";

type ProductSizeSelectProps = EntitySelectInputProps<ProductSizeContract, "sizeId"> & {
    categoryId?: string,
    userId?: string
}

const ProductSizeSelect = ({ isMulti, value, onChange, onSelect, name, isValid, categoryId, userId, ...rest }: ProductSizeSelectProps, ref: ForwardedRef<SelfPopulatedSelectInputRef>) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [query, updateQuery] = useImmer<ListProductSizesQuery>({
        page: 1,
        pageSize: 99,
        search: "",
        categoryId: "",
        userId
    });

    const { data: sizes, isFetching: loading, refetch: reload } = useQuery({
        queryKey: ["product-size-list", query],
        queryFn: () => getProductSizeList(query),
        select: data => data.items
    });

    const productSizeList = useMemo(() => sizes?.map(s => ({ 
        label: s.name, 
        value: s.sizeId 
    })) ?? [], [sizes]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = sizes?.filter(c => val.includes(c.sizeId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const throttledLoadList = useThrottle(() => {
        dispatch(loadList(query));
    }, 300);

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = sizes?.find(s => s.sizeId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.sizeId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        throttledLoadList();
    }, [throttledLoadList, query]);

    useImperativeHandle(ref, () => {
        return {
            reload: () => {
                console.log("Reloading product size list");
                
                reload();
                return Promise.resolve();
            }
        };
    }, [reload]);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (!sizes || sizes.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sizes]);

    const onSearch = useCallback((val?: string) => {
        updateQuery(q => {
            q.search = val;
        });
    }, [updateQuery]);

    useEffect(() => {
        updateQuery(q => {
            q.categoryId = categoryId;
        });
    }, [updateQuery, categoryId]);
    
    const selectProps = {
        ...rest,
        placeholder: rest.placeholder ?? t("Product Size"),
        busy: loading,
        options: productSizeList,
        showClear: true,
        onSearch: onSearch,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default forwardRef(ProductSizeSelect) as (
    props: ProductSizeSelectProps & RefAttributes<SelfPopulatedSelectInputRef>
) => ReturnType<typeof ProductSizeSelect>;
