import BusyOverlay from "Components/Common/BusyOverlay";
import CountryDisplay from "Components/Displays/CountryDisplay";
import CustomerDisplay from "Components/Displays/CustomerDisplay";
import FbaShipmentStatusBadge from "Components/Displays/FbaShipmentStatusBadge";
import Currency from "Components/Displays/UnitDisplay/Currency";
import PrepServiceSelect from "Components/EntitySelects/PrepServiceSelect";
import NumberInput from "Components/Form/NumberInput";
import CurrencyInput from "Components/Form/UnitInputs/CurrencyInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { FbaShipmentContract, PrepServiceContract } from "api/types/contracts/shipping";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, CardTitle, Form, Table } from "reactstrap";
import * as Yup from "yup";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { TypedShape } from "helpers/types";
import { UnitOfCurrency } from "api/types/contracts/common";
import type { InvoiceForm, InvoiceItemForm } from "api/types/contracts/payment";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getPrepServiceList } from "api/prepService";
import { toast } from "react-toastify";
import TextInput from "Components/Form/TextInput";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { postInvoice } from "api/invoice";
import type { CreateInvoiceCommand } from "api/types/commands";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import CustomerSelect from "Components/EntitySelects/CustomerSelect";
import InvoiceItemSelect from "./InvoiceItemSelect";
import { useMetadata } from "Components/Hooks/MetadataHooks";

type EditFormProps = {
    fbaShipment?: FbaShipmentContract
};

const EditForm = ({ fbaShipment }: EditFormProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [params, setParams] = useSearchParams();
    const [selectedItem, setSelectedItem] = useState<InvoiceItemForm>();
    const { userProfile } = useProfile();
    const currency = fbaShipment?.warehouse.settings.unitOfCurrency ?? userProfile?.user.warehouse?.settings.unitOfCurrency ?? "usd";
    const isFirst = params.get("first") === "true";
    const [selectedWarehouse, setSelectedWarehouse] = useState(fbaShipment?.warehouse);

    const { setPageTitle } = useMetadata();

    if (fbaShipment) {
        setPageTitle(t("Create invoice for {{shipmentCode}} - Invoices", { shipmentCode: fbaShipment.shipmentCode }));
    }
    else {
        setPageTitle(t("Create Invoice - Invoices"));
    }
    
    const validation = useFormik({
        initialValues: {
            fbaShipmentId: fbaShipment?.fbaShipmentId,
            customerId: fbaShipment?.customer.customerId,
            warehouseId: fbaShipment?.warehouse.warehouseId,
            invoice: {
                unitOfCurrency: currency,
                items: []
            }
        } as Partial<CreateInvoiceCommand>,
        validationSchema: Yup.object<CreateInvoiceCommand, TypedShape<CreateInvoiceCommand>>({
            fbaShipmentId: Yup.string().notRequired(),
            customerId: Yup.string().required(t("Customer is required")),
            warehouseId: Yup.string().required(t("Warehouse is required")),
            invoice: Yup.object<InvoiceForm, TypedShape<InvoiceForm>>({
                unitOfCurrency: Yup.string<UnitOfCurrency>().required(t("Currency is required")),
                items: Yup.array<InvoiceItemForm>(Yup.object<InvoiceItemForm, TypedShape<InvoiceItemForm>>({
                    count: Yup.number().required(t("Count is required")),
                    discount: Yup.number().notRequired(),
                    prepServiceId: Yup.string().notRequired(),
                    groupName: Yup.string().required(),
                    serviceName: Yup.string().required(t("Service name is required")),
                    total: Yup.number().required(t("Total is required")),
                    unitPrice: Yup.number().required(t("Unit price is required"))
                }).required()).required()
            }).required()
        }),
        onSubmit: values => {
            createInvoiceMutation.mutate({
                fbaShipmentId: values.fbaShipmentId,
                customerId: values.customerId!,
                warehouseId: values.warehouseId!,
                invoice: {
                    unitOfCurrency: values.invoice!.unitOfCurrency,
                    items: values.invoice!.items.filter(i => i.total > 0)
                }
            });
        }
    });

    const createInvoiceMutation = useMutation({
        mutationFn: postInvoice,
        onSuccess: invoice => {
            toast.success(t("Invoice created"));
            navigate(`/invoice/${invoice.invoiceId}`);
        }
    })

    const { data: prepServices } = useQuery({
        queryKey: ["prep-services-select"],
        queryFn: () => getPrepServiceList({
            page: 1,
            pageSize: 99
        }),
        select: data => data?.items
    });

    const addNewItem = () => {
        if (selectedItem && !validation.values.invoice!.items.find(i => i.groupName === selectedItem.groupName && i.serviceName === selectedItem.serviceName)) {
            validation.setFieldValue("invoice.items", [...validation.values.invoice!.items, selectedItem]);
        }

        setSelectedItem(undefined);
    }

    useEffect(() => {        
        if (fbaShipment && isFirst) {
            const cost = fbaShipment.actualCost ?? fbaShipment.estimatedCost;

            const items = [];

            if (cost.shipping > 0) {
                items.push({
                    count: 1,
                    discount: 0,
                    groupName: t("Shipping & Tax"),
                    prepServiceId: undefined,
                    serviceName: t("Shipping Cost"),
                    total: _.round(cost.shipping, 2),
                    unitPrice: _.round(cost.shipping, 2)
                });
            }

            if (cost.import > 0) {
                items.push({
                    count: 1,
                    discount: 0,
                    groupName: t("Shipping & Tax"),
                    prepServiceId: undefined,
                    serviceName: t("Import Tax"),
                    total: _.round(cost.import, 2),
                    unitPrice: _.round(cost.import, 2)
                });
            }

            if (cost.insurance > 0) {
                items.push({
                    count: 1,
                    discount: 0,
                    groupName: t("Shipping & Tax"),
                    prepServiceId: undefined,
                    serviceName: t("Insurance Fee"),
                    total: _.round(cost.insurance, 2),
                    unitPrice: _.round(cost.insurance, 2)
                });
            }

            if (cost.oversize > 0) {
                items.push({
                    count: 1,
                    discount: 0,
                    groupName: t("Shipping & Tax"),
                    prepServiceId: undefined,
                    serviceName: t("Oversize Fee"),
                    total: _.round(cost.oversize, 2),
                    unitPrice: _.round(cost.oversize, 2)
                });
            }

            if (cost.overweight > 0) {
                items.push({
                    count: 1,
                    discount: 0,
                    groupName: t("Shipping & Tax"),
                    prepServiceId: undefined,
                    serviceName: t("Overweight Fee"),
                    total: _.round(cost.overweight, 2),
                    unitPrice: _.round(cost.overweight, 2)
                });
            }

            items.push(...cost.items.map(i => ({
                count: i.count,
                discount: i.discount,
                groupName: t("Prep Services"),
                prepServiceId: i.prepServiceId as string | undefined,
                serviceName: i.serviceName,
                total: _.round(i.unitPrice * i.count, 2),
                unitPrice: _.round(i.unitPrice, 2),
            })));

            validation.setFieldValue("invoice.items", items);

            console.log("items", items);
            
        }
    }, [fbaShipment, isFirst]);

    const itemGroups = useMemo(() => _.chain(validation.values.invoice!.items)
        .map((item, index) => ({ item, index }))
        .groupBy(i => i.item.groupName)
        .map((items, groupName) => ({
            groupName,
            items: items,
            total: _.sumBy(items, i => i.item.total)
        }))
        .value(), [validation.values.invoice!.items]);

    const grandTotal = useMemo(() => _.sumBy(validation.values.invoice!.items, item => item.total), [validation.values.invoice!.items]);

    return <>
        <Card body>
            {fbaShipment ? <dl className="row align-items-center mb-0 g-2">
                <dt className="col-2">{t("FBA Shipment ID")}</dt>
                <dd className="col-2 mb-0"><Link to={`fba-shipment/${fbaShipment.fbaShipmentId}`}>{fbaShipment.shipmentCode}</Link></dd>
                <dt className="col-2">{t("Status")}</dt>
                <dd className="col-2 mb-0"><FbaShipmentStatusBadge value={fbaShipment.status} /></dd>
                <dt className="col-2">{t("Country")}</dt>
                <dd className="col-2 mb-0"><CountryDisplay country={fbaShipment.shipmentTarget.address.country} /></dd>

                <dt className="col-2">{t("Customer")}</dt>
                <dd className="col-2 mb-0"><CustomerDisplay customer={fbaShipment.customer} /></dd>
                <dt className="col-2">{t("W/T Products")}</dt>
                <dd className="col-2 mb-0">{0}/{_.sumBy(fbaShipment.items, "count")}</dd>
                <dt className="col-2">{t("Expected Total Price")}</dt>
                <dd className="col-2 mb-0"><Currency value={fbaShipment.actualCost?.total ?? fbaShipment.estimatedCost.total} currency={currency} /></dd>

                <dt className="col-2">{t("Invoice ID")}</dt>
                <dd className="col-2 mb-0">{t("N/A")}</dd>
                <dt className="col-2">{t("Carrier")} / {t("Carrier Service")}</dt>
                <dd className="col-2 mb-0">{fbaShipment.carrierAccountService.carrierService.carrier.name} / {fbaShipment.carrierAccountService.carrierService.name}</dd>
                <dt className="col-2">{t("Boxes")}</dt>
                <dd className="col-2 mb-0">{fbaShipment.packages ? fbaShipment.packages.length : fbaShipment.estimatedBoxes.length}</dd>
            </dl> 
            : <dl className="row align-items-center mb-0 g-5">
                <dt className="col-2 mb-0">{t("Warehouse")}</dt>
                <dd className="col-4 mb-0">
                    <ValidationWrapper validation={validation} field="warehouseId">
                        <WarehouseSelect size="sm" value={selectedWarehouse?.warehouseId} onSelect={setSelectedWarehouse} />
                    </ValidationWrapper>
                </dd>
                <dt className="col-2 mb-0">{t("Customer")}</dt>
                <dd className="col-4 mb-0">
                    <ValidationWrapper validation={validation} field="customerId">
                        <CustomerSelect size="sm" />
                    </ValidationWrapper>
                </dd>
            </dl>}
        </Card>
        <Form className="needs-validation" action="#" onSubmit={validation.handleSubmit}>
            <Card>
                <CardHeader>
                    <div className="hstack">
                        <CardTitle tag="h5" className="mb-0">{t("Details")}</CardTitle>
                        <div className="hstack align-items-center w-30 ms-auto gap-2">
                            <InvoiceItemSelect size="sm" warehouseId={selectedWarehouse?.warehouseId}
                                disabledCallback={item => !!validation.values.invoice!.items.find(i => i.groupName === item.groupName && i.serviceName === item.serviceName)}
                                value={selectedItem} onChange={setSelectedItem} className="w-100" />
                                <Button className="flex-shrink-0" type="button" size="sm" color="info" onClick={addNewItem}>
                                    {t("Add Item")}
                                </Button>
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="table-responsive table-card">
                        <Table className="mb-0 align-middle" borderless hover size="sm">
                            {itemGroups.length === 0 && <tbody>
                                <tr>
                                    <td colSpan={7}>
                                        <div className="vstack justify-content-center align-items-center gap-2" style={{ height: "200px" }}>
                                            <span>{t("No items added")}</span>
                                            
                                        </div>
                                    </td>
                                </tr>
                            </tbody>}
                            {itemGroups.map(group => <React.Fragment key={group.groupName}>
                                <thead className="table-light text-muted">
                                    <tr>
                                        <th style={{ width: "1%" }}></th>
                                        <th>{group.groupName}</th>
                                        <th style={{ width: "10%" }}>{t("Unit Price")}</th>
                                        <th style={{ width: "10%" }}>{t("Count")}</th>
                                        <th style={{ width: "10%" }}>{t("Sub Total")}</th>
                                        <th style={{ width: "10%" }}>{t("Discount")}</th>
                                        <th style={{ width: "10%" }}>{t("Total")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {group.items.map(({ item, index }) => {
                                        const prepService = prepServices?.find(p => p.prepServiceId === item.prepServiceId);

                                        return <tr key={index}>
                                            <td>
                                                <Button type="button" color="ghost-danger" size="sm" className="btn-icon" onClick={() => {
                                                    validation.setFieldValue("invoice.items", validation.values.invoice!.items.filter((_, i) => i !== index));
                                                }}>
                                                    <i className="ri-delete-bin-line fs-16"></i>
                                                </Button>
                                            </td>
                                            <td>
                                                <ValidationWrapper validation={validation} field={`invoice.items.${index}.serviceName`}>
                                                    <TextInput disabled={!!prepService} size="sm" />
                                                </ValidationWrapper>
                                            </td>
                                            <td>
                                                <ValidationWrapper validation={validation} field={`invoice.items.${index}.unitPrice`}>
                                                    <CurrencyInput size="sm" disabled={!!prepService} onChange={val => {
                                                        validation.setFieldValue(`invoice.items.${index}.total`, _.round(((val ?? 0) * item.count) - (item.discount ?? 0), 2));
                                                    }} />
                                                </ValidationWrapper>
                                            </td>
                                            <td>
                                                <ValidationWrapper validation={validation} field={`invoice.items.${index}.count`}>
                                                    <NumberInput size="sm" onChange={val => {
                                                        validation.setFieldValue(`invoice.items.${index}.total`, _.round(((val ?? 0) * item.unitPrice) - (item.discount ?? 0), 2));
                                                    }} />
                                                </ValidationWrapper>
                                            </td>
                                            <td>
                                                <CurrencyInput size="sm" currency={currency} disabled value={_.round(item.unitPrice * item.count, 2)} />
                                            </td>
                                            <td>
                                                <ValidationWrapper validation={validation} field={`invoice.items.${index}.discount`}>
                                                    <CurrencyInput size="sm" currency={currency} disabled={prepService?.pricingType === "custom"} onChange={val => {
                                                        validation.setFieldValue(`invoice.items.${index}.total`, _.round(((item.count ?? 0) * item.unitPrice) - (val ?? 0), 2));
                                                    }} />
                                                </ValidationWrapper>
                                            </td>
                                            <td>
                                                <ValidationWrapper validation={validation} field={`invoice.items.${index}.total`}>
                                                    <CurrencyInput size="sm" currency={currency} disabled={prepService?.pricingType !== "custom"} onChange={val => {
                                                        if (prepService?.pricingType === "custom") {
                                                            validation.setFieldValue(`invoice.items.${index}.unitPrice`, val);
                                                        }
                                                    }} />
                                                </ValidationWrapper>
                                            </td>
                                        </tr>;
                                    })}
                                    <tr>
                                        <td></td>
                                        <td>
                                            <div className="hstack gap-2 w-75">
                                                {<InvoiceItemSelect size="sm" warehouseId={selectedWarehouse?.warehouseId}
                                                    groupFilter={group.groupName}
                                                    disabledCallback={item => !!validation.values.invoice!.items.find(i => i.groupName === item.groupName && i.serviceName === item.serviceName)}
                                                    value={selectedItem} onChange={setSelectedItem} className="w-100" />}
                                                <Button type="button" size="sm" color="info" className="flex-shrink-0" onClick={addNewItem}>{t("Add New Row")}</Button>
                                            </div>
                                        </td>
                                        <td colSpan={5}></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={7} className="text-end">
                                            <span className="me-2">{group.groupName} {t("Total")}</span>
                                            <span className="fw-bold">
                                                <Currency currency={currency} value={group.total} />
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </React.Fragment>)}
                        </Table>
                    </div>
                </CardBody>
            </Card>
            <Card body>
                <div className="mx-auto w-50 vstack gap-3">
                    {itemGroups.map(group => <React.Fragment key={group.groupName}>
                        <div className="d-flex justify-content-between">
                            <span>{group.groupName}</span>
                            <span className="fw-bold"><Currency currency={currency} value={group.total} /></span>
                        </div>
                    </React.Fragment>)}
                    <div className="d-flex justify-content-end gap-2 py-2 border-top border-dark-subtle">
                        <span className="fs-5">{t("Total")}</span>
                        <span className="fw-bold fs-5"><Currency currency={currency} value={grandTotal} /></span>
                    </div>
                </div>
            </Card>
            <div className="d-flex justify-content-center mb-3">
                <BusyOverlay busy={createInvoiceMutation.isPending} size="sm">
                    <ValidatorButton validation={validation} color="primary" type="submit">
                        {t("Create Invoice")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
}

export default EditForm;