import { useMutation } from '@tanstack/react-query';
import { DangerAlert } from 'Components/Alerts';
import BusyOverlay from 'Components/Common/BusyOverlay';
import RequiredLabel from 'Components/Common/RequiredLabel';
import NumberInput from 'Components/Form/NumberInput';
import StepInput from 'Components/Form/StepInput';
import LengthInput from 'Components/Form/UnitInputs/LengthInput';
import ValidationWrapper from 'Components/Form/Validated/ValidationWrapper';
import ValidatorButton from 'Components/Form/Validated/ValidatorButton';
import { createAppSelector, useAppDispatch, useAppSelector } from 'Components/Hooks/StoreHooks';
import { postAisle } from 'api/aisle';
import { CreateAisleCommand } from 'api/types/commands';
import { useFormik } from 'formik';
import { TypedShape } from 'helpers/types';
import { useTranslation } from 'react-i18next';
import { Col, Form, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import { createAisle } from 'slices/location/thunk';
import * as Yup from 'yup';

type FormProps = {
    areaId: string,
    onSuccess: VoidFunction
}

type FormType = Omit<CreateAisleCommand, "areaId">;

const CreateAisleFormView = (props: FormProps) => {
    const { t } = useTranslation();

    const createAisleMutation = useMutation({
        mutationFn: postAisle,
        mutationKey: ["create-aisle"],
        onSuccess: () => {
            props.onSuccess();
        }
    });

    const { warehouse } = useAppSelector(
        createAppSelector([state => state.Location], 
            location => ({
                warehouse: location.warehouse
            })
        )
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            aisleCount: 1,
            bayCount: 0
        } as FormType,
        validationSchema: Yup.object<FormType, TypedShape<FormType>>({
            aisleCount: Yup.number().required("You must enter aisle count").min(1, t("Aisle count must be greater than zero")),
            bayCount: Yup.number().notRequired(),
            aisleHeight: Yup.number().notRequired(),
            aisleWidth: Yup.number().notRequired(),
            aisleDepth: Yup.number().notRequired(),
            bayHeight: Yup.number().notRequired(),
            bayWidth: Yup.number().notRequired()
        }),
        onSubmit: (values) => {
            createAisleMutation.mutate({ 
                areaId: props.areaId,
                ...values
            });
        },
    });

    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <div>
                <Row className="g-3 mb-3">
                    <Col lg={6}>
                        <RequiredLabel htmlFor="aisleCount">{t("Aisle Count")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="aisleCount">
                            <StepInput min={1} placeholder="Enter Aisle Count" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={6}>
                        <Label htmlFor="bayCount">{t("Bay Count")}</Label>
                        <ValidationWrapper validation={validation} field="bayCount">
                            <StepInput min={0} placeholder="Enter bay count on each aisle" />
                        </ValidationWrapper>
                    </Col>
                    <div className='clearfix m-0'></div>
                    <Col xs={12}>
                        <Label>{t("Aisle Dimensions")}</Label>
                        <div className="hstack gap-2">
                            <InputGroup>
                                <ValidationWrapper validation={validation} field="aisleWidth">
                                    <NumberInput placeholder="Enter Width" />
                                </ValidationWrapper>
                                <ValidationWrapper validation={validation} field="aisleHeight">
                                    <NumberInput placeholder="Enter Height" />
                                </ValidationWrapper>
                                <ValidationWrapper validation={validation} field="aisleDepth">
                                    <NumberInput placeholder="Enter Length" />
                                </ValidationWrapper>
                                <InputGroupText>
                                    {warehouse?.settings.unitOfLength}
                                </InputGroupText>
                            </InputGroup>
                        </div>
                    </Col>
                </Row>
                {validation.values.bayCount! > 0 && <Row className="g-3">
                    <Col lg={6}>
                        <Label htmlFor="bayHeight">{t("Bay Height")}</Label>
                        <ValidationWrapper validation={validation} field="bayHeight">
                            <LengthInput placeholder="Enter bay height" unit={warehouse?.settings.unitOfLength} />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={6}>
                        <Label htmlFor="bayWidth">{t("Bay Width")}</Label>
                        <ValidationWrapper validation={validation} field="bayWidth">
                            <LengthInput placeholder="Enter bay width" unit={warehouse?.settings.unitOfLength} />
                        </ValidationWrapper>
                    </Col>
                </Row>}
            </div>
            <div className="mt-4">{createAisleMutation.isError && <DangerAlert>{createAisleMutation.error.message}</DangerAlert>}</div>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={createAisleMutation.isPending} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
}

export default CreateAisleFormView;