import classNames from "classnames"
import { PropsWithChildren, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Tooltip } from "reactstrap"

type ClipboardCopyProps = PropsWithChildren & {
    text: string,
    className?: string
}

const ClipboardCopy = ({ text, className, children }: ClipboardCopyProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const tooltipRef = useRef<Tooltip>(null);
    const { t } = useTranslation();
    const [tooltipShown, setTooltipShown] = useState(false);

    const onCopyClick = () => {
        navigator.clipboard.writeText(text);
        setTooltipShown(true);
        setTimeout(() => setTooltipShown(false), 700);
    }

    return <>
        {children ? <>
            <Button innerRef={buttonRef} onClick={onCopyClick} size="sm" color="ghost-dark" className={classNames("position-relative h-auto py-0 px-1 w-auto", className)}>
                {children}
                <span className={classNames("bg-white h-100 position-absolute rounded start-0 text-center top-0 w-100 z-1", {
                    "d-none": !tooltipShown,
                    "d-block": tooltipShown
                })}>{t("Copied")}!</span>
            </Button>
        </> : <Button innerRef={buttonRef} type="button" onClick={onCopyClick} color="ghost-dark" className={classNames("btn-icon h-auto py-0 px-1 w-auto", className)} size="sm">
            <i className="ri-file-copy-line" style={{ fontSize: "1.1em" }}></i>
            <span className={classNames("bg-white h-100 position-absolute rounded start-0 text-center top-0 w-100 z-1", {
                    "d-none": !tooltipShown,
                    "d-block": tooltipShown
                })}><i className="ri-check-double-line text-success"></i></span>
        </Button>}
    </>
}

export default ClipboardCopy;