import { useMutation } from '@tanstack/react-query';
import { DangerAlert } from 'Components/Alerts';
import BusyOverlay from 'Components/Common/BusyOverlay';
import RequiredLabel from 'Components/Common/RequiredLabel';
import NumberInput from 'Components/Form/NumberInput';
import TextInput from 'Components/Form/TextInput';
import ValidationWrapper from 'Components/Form/Validated/ValidationWrapper';
import ValidatorButton from 'Components/Form/Validated/ValidatorButton';
import { createAppSelector, useAppSelector } from 'Components/Hooks/StoreHooks';
import { putBinLocation } from 'api/binLocation';
import { BinLocationContract, BinLocationForm } from 'api/types/contracts/locations';
import { useFormik } from 'formik';
import { TypedShape } from 'helpers/types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Col, Form, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import * as Yup from 'yup';

type FormProps = {
    binLocation: BinLocationContract,
    onSuccess: () => void
}

const createBinForm = (bin: BinLocationContract): BinLocationForm => {
    return {
        name: bin.name,
        ...bin.properties
    }
};

const UpdateBinLocationFormView = (props: FormProps) => {
    const aisle = createBinForm(props.binLocation);
    const { t } = useTranslation();
    
    const updateBinLocationMutation = useMutation({
        mutationFn: putBinLocation,
        mutationKey: ["update-bin-location"],
        onSuccess: () => {
            toast.success(t("Bin Location updated"));
            props.onSuccess();
        }
    });
    
    const { warehouse } = useAppSelector(
        createAppSelector([state => state.Location], 
            location => ({
                warehouse: location.warehouse
            })
        )
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: aisle,
        validationSchema: Yup.object<BinLocationForm, TypedShape<BinLocationForm>>({
            name: Yup.string().required(t("Please enter bin location name")),
            height: Yup.number().notRequired(),
            width: Yup.number().notRequired(),
            depth: Yup.number().notRequired()
        }),
        onSubmit: values => {
            updateBinLocationMutation.mutate({
                binLocationId: props.binLocation.binLocationId,
                binLocation: values
            });
        },
    });
    
    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <div>
                <Row className="g-3">
                    <Col lg={6}>
                        <RequiredLabel htmlFor="name">{t("Name")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="name">
                            <TextInput maxLength={5} placeholder="Enter Bin Location Name" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={12}>
                        <Label htmlFor="width">{t("Dimensions")}</Label>
                        <div className="hstack gap-2">
                            <InputGroup>
                                <ValidationWrapper validation={validation} field="width">
                                    <NumberInput placeholder="Enter Width" />
                                </ValidationWrapper>
                                <ValidationWrapper validation={validation} field="height">
                                    <NumberInput placeholder="Enter Height" />
                                </ValidationWrapper>
                                <ValidationWrapper validation={validation} field="depth">
                                    <NumberInput placeholder="Enter Depth" />
                                </ValidationWrapper>
                                <InputGroupText>
                                    {warehouse?.settings.unitOfLength}
                                </InputGroupText>
                            </InputGroup>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="mt-4">{updateBinLocationMutation.isError && <DangerAlert>{updateBinLocationMutation.error.message}</DangerAlert>}</div>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={updateBinLocationMutation.isPending} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
}

export default UpdateBinLocationFormView;