import type { FormikValidation } from "helpers/types";
import { FormFeedback } from "reactstrap";

type ValidationErrorDisplayProps<T, U = keyof T> = {
    validation: FormikValidation<T>,
    field: U | string,
    showUntouched?: boolean
}

const ValidationErrorDisplay = <T,>({ validation, field, showUntouched }: ValidationErrorDisplayProps<T>) => {
    const fieldName = field as string; 
    const { error, touched } = validation.getFieldMeta(fieldName);

    const errorMessage = (error: string | Record<string, string>[]) => {
        if (typeof error === "string") {
            return error;
        }
        
        //return error.filter(e => !!e).map((e, i) => <span key={i}><span>{t("Row {{i}}", { i: i + 1 })}: {Object.values(e).join(", ")}</span><br /></span>);
    }

    return error && (showUntouched || touched) ? 
        <FormFeedback type="invalid" className="d-block">
            {errorMessage(error)}
        </FormFeedback> : undefined;
}

export default ValidationErrorDisplay;