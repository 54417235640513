import { LoaderFunction, Navigate, RouteObject, defer } from "react-router-dom";

// API
import { getCompany } from "api/company";
import { getWarehouse } from "api/warehouse";
import { getProduct } from "api/product";
import { getCustomer } from "api/customer";
import { getReceivingEntry } from "api/receiving";
import { getPrepService } from "api/prepService";
import { getInbound, getInboundReceivingsList } from "api/inbound";

//Dashboard
import DashboardPage from "pages/Dashboard";

// login
import LoginPage from "pages/Authentication/Login";
import ForgetPasswordPage from "pages/Authentication/ForgetPassword";
import Logout from "pages/Authentication/Logout";
import RegisterPage from "pages/Authentication/Register";

// Tenants
import CompainesPage from "pages/Companies";
import WarehousesPage from "pages/Warehouses";
import Index from "pages/Home";
import LocationsPage from "pages/Locations";
import BinsPage from "pages/Bins";
import CustomersPage from "pages/Customers";
import VerifyEmail from "pages/Authentication/VerifyEmail";
import ProductsPage from "pages/Products";
import InboundEditPage from "pages/Inbound/Edit";
import InboundListPage from "pages/Inbound/List";
import GateEntryPage from "pages/GateEntry";
import ReceivingsPage from "pages/Receivings";
import InboundDetailPage from "pages/Inbound/Detail";
import PrepServicesPage from "pages/PrepServices";
import GetAQuotePage from "pages/Shipment/GetAQuote";
import ListFbaShipmentsPage from "pages/Shipment/List";
import ProcessFbaShipmentPage from "pages/Shipment/Process";
import { getFbaShipment } from "api/fbaShipping";
import InvoicePage from "pages/Invoice";
import { getInvoice } from "api/invoice";
import PutawayPage from "pages/Inventory/Putaway";
import { getBin } from "api/bin";
import CarriersPage from "pages/Carriers";
import OversizeCarrierRulePage from "pages/CarrierRules/Oversize";
import { getCarrierImportTaxPricingRule, getCarrierInsuranceRule, getCarrierOversizeRule, getCarrierOverweightRule } from "api/carrierRules";
import OverweightCarrierRulePage from "pages/CarrierRules/Overweight";
import InsuranceCarrierRulePage from "pages/CarrierRules/Insurance";
import ImportTaxPricingCarrierRulePage from "pages/CarrierRules/ImportTaxPricing";
import PrinterListPage from "pages/Printers/PrinterListPage";
import PrintingSetupPage from "pages/Printers/PrintingSetupPage";
import PrintDocumentEditPage from "pages/Printers/PrintDocumentEditPage";
import WalletPage from "pages/Wallet";
import CreditRequestPage from "pages/CreditRequest";
import { getCreditRequest } from "api/payment";
import InvoiceListPage from "pages/Invoice/List";
import RecoverPasswordPage from "pages/Authentication/RecoverPassword";

type RouteItem = {
    path: string,
    element?: JSX.Element,
    pageFn?: () => JSX.Element,
    loader?: LoaderFunction
}

const authProtectedRoutes: RouteItem[] = [
    { path: "/dashboard", element: <DashboardPage /> },
    { path: "/index", element: <DashboardPage /> },

    //User Profile
    //{ path: "/profile", element: <UserProfile /> },

    // Companies
    { path: "/companies/new", element: <CompainesPage edit /> },
    { path: "/companies/edit/:companyId", element: <CompainesPage edit />, loader: ({ params }) => {
        if (params.companyId) {
            return defer({ 
                company: getCompany({ companyId: params.companyId })
            });
        }
        
        return null;
    }},
    { path: "/companies", element: <CompainesPage /> },
    // Warehouses
    { path: "/warehouses/new", element: <WarehousesPage edit /> },
    { path: "/warehouses/edit/:warehouseId", element: <WarehousesPage edit />, loader: ({ params }) => {
        if (params.warehouseId) {
            return defer({ 
                warehouse: getWarehouse({ warehouseId: params.warehouseId })
            });
        }
        
        return null;
    }},
    { path: "/warehouses", element: <WarehousesPage /> },
    { path: "/locations", element: <LocationsPage /> },
    { path: "/locations/:warehouseId", element: <LocationsPage /> },
    { path: "/bins", element: <BinsPage /> },
    { path: "/bins/new", element: <BinsPage edit /> },
    { path: "/bins/edit/:binId", element: <BinsPage edit />, loader: ({ params }) => {
        if (params.binId) {
            return defer({ 
                bin: getBin({ id: params.binId })
            });
        }
        
        return null;
    }},
    { path: "/customers", element: <CustomersPage /> },
    { path: "/customers/edit/:customerId", element: <CustomersPage edit />, loader: ({ params }) => {
        if (params.customerId) {
            return defer({ 
                customer: getCustomer({ customerId: params.customerId })
            });
        }
        
        return null;
    }},
    { path: "/products", element: <ProductsPage /> },
    { path: "/products/new", element: <ProductsPage edit /> },
    { path: "/products/edit/:productId", element: <ProductsPage edit />, loader: ({ params }) => {
        if (params.productId) {
            return defer({ 
                product: getProduct({ productId: params.productId })
            });
        }
        
        return null;
    }},
    { path: "/inbounds", element: <InboundListPage /> },
    { path: "/inbounds/new", element: <InboundEditPage /> },
    { path: "/inbounds/:inboundId", element: <InboundDetailPage />, loader: ({ params }) => {
        if (params.inboundId) {
            return defer({ 
                inbound: getInbound({ inboundId: params.inboundId }),
                receivings: getInboundReceivingsList({ inboundId: params.inboundId })
            });
        }
        
        return null;
    }},
    { path: "/inbounds/edit/:inboundId", element: <InboundEditPage />, loader: ({ params }) => {
        if (params.inboundId) {
            return defer({ 
                inbound: getInbound({ inboundId: params.inboundId })
            });
        }
        
        return null;
    }},
    { path: "/gate-entry", element: <GateEntryPage /> },
    { path: "/gate-entry/:receivingId", element: <GateEntryPage />, loader: ({ params }) => {
        if (params.receivingId) {
            return defer({ 
                receivingEntry: getReceivingEntry({ receivingEntryId: params.receivingId })
            });
        }
        
        return null;
    }},
    { path: "/gate-entry/inbound/:inboundId", element: <GateEntryPage />, loader: ({ params }) => {
        if (params.inboundId) {
            return defer({ 
                inbound: getInbound({ inboundId: params.inboundId })
            });
        }
        
        return null;
    }},
    { path: "receivings", element: <ReceivingsPage /> },
    { path: "receivings/:receivingId", element: <ReceivingsPage process />, loader: ({ params }) => {
        if (params.receivingId) {
            return defer({ 
                receivingEntry: getReceivingEntry({ receivingEntryId: params.receivingId })
            });
        }
        
        return null;
    }},
    { path: "prep-services", element: <PrepServicesPage /> },
    { path: "prep-services/new", element: <PrepServicesPage edit /> },
    { path: "prep-services/edit/:prepServiceId", element: <PrepServicesPage edit />, loader: ({ params }) => {
        if (params.prepServiceId) {
            return defer({ 
                prepService: getPrepService({ prepServiceId: params.prepServiceId })
            });
        }
        
        return null;
    }},
    { path: "get-a-quote", element: <GetAQuotePage /> },
    { path: "fba-shipments", element: <ListFbaShipmentsPage /> },
    { path: "fba-shipment/:fbaShipmentId", element: <ProcessFbaShipmentPage />, loader: ({ params }) => {
        if (params.fbaShipmentId) {
            const promise = getFbaShipment({ fbaShipmentId: params.fbaShipmentId });

            return defer({ 
                fbaShipment: promise
            });
        }
        
        return null;
    }},
    { path: "create-invoice", element: <InvoicePage create />},
    { path: "create-invoice/:fbaShipmentId", element: <InvoicePage create /> , loader: ({ params }) => {
        if (params.fbaShipmentId) {
            return defer({ 
                fbaShipment: getFbaShipment({ fbaShipmentId: params.fbaShipmentId })
            });
        }
        
        return null;
    }},
    { path: "invoice/:invoiceId", element: <InvoicePage /> , loader: ({ params }) => {
        if (params.invoiceId) {
            return defer({ 
                invoice: getInvoice({ invoiceId: params.invoiceId })
            });
        }
        
        return null;
    }},
    { path: "bin-putaway", element: <PutawayPage forBin /> },
    { path: "item-putaway", element: <PutawayPage forItem /> },
    { path: "carriers", element: <CarriersPage /> },
    { path: "carrier-rules/oversize-rules", element: <OversizeCarrierRulePage />},
    { path: "carrier-rules/oversize-rules/new", element: <OversizeCarrierRulePage edit />},
    { path: "carrier-rules/oversize-rules/edit/:oversizeRuleId", element: <OversizeCarrierRulePage edit />, loader: ({ params }) => {
        if (params.oversizeRuleId) {
            return defer({ 
                oversizeRule: getCarrierOversizeRule({ carrierOversizeRuleId: params.oversizeRuleId })
            });
        }
        
        return null;
    }},
    { path: "carrier-rules/overweight-rules", element: <OverweightCarrierRulePage />},
    { path: "carrier-rules/overweight-rules/new", element: <OverweightCarrierRulePage edit />},
    { path: "carrier-rules/overweight-rules/edit/:overweightRuleId", element: <OverweightCarrierRulePage edit />, loader: ({ params }) => {
        if (params.overweightRuleId) {
            return defer({ 
                overweightRule: getCarrierOverweightRule({ carrierOverweightRuleId: params.overweightRuleId })
            });
        }
        
        return null;
    }},
    { path: "carrier-rules/insurance-rules", element: <InsuranceCarrierRulePage />},
    { path: "carrier-rules/insurance-rules/new", element: <InsuranceCarrierRulePage edit />},
    { path: "carrier-rules/insurance-rules/edit/:insuranceRuleId", element: <InsuranceCarrierRulePage edit />, loader: ({ params }) => {
        if (params.insuranceRuleId) {
            return defer({ 
                insuranceRule: getCarrierInsuranceRule({ carrierInsuranceRuleId: params.insuranceRuleId })
            });
        }
        
        return null;
    }},
    { path: "carrier-rules/import-tax-rules", element: <ImportTaxPricingCarrierRulePage />},
    { path: "carrier-rules/import-tax-rules/new", element: <ImportTaxPricingCarrierRulePage edit />},
    { path: "carrier-rules/import-tax-rules/edit/:importTaxRuleId", element: <ImportTaxPricingCarrierRulePage edit />, loader: ({ params }) => {
        if (params.importTaxRuleId) {
            return defer({ 
                importTaxRule: getCarrierImportTaxPricingRule({ carrierImportTaxPricingRuleId: params.importTaxRuleId })
            });
        }
        
        return null;
    }},
    { path: "printers", element: <PrinterListPage />},
    { path: "printing-setup", element: <PrintingSetupPage />},
    { path: "printing-setup/:printPreferenceId", element: <PrintDocumentEditPage />},
    { path: "my-wallet", element: <WalletPage /> },
    { path: "credit-requests", element: <CreditRequestPage /> },
    { path: "credit-requests/:creditRequestId", element: <CreditRequestPage edit />, loader: ({ params }) => {
        if (params.creditRequestId) {
            return defer({ 
                creditRequest: getCreditRequest({ creditRequestId: params.creditRequestId })
            });
        }
        
        return null;
    }},
    { path: "invoices", element: <InvoiceListPage /> },
    // this route should be at the end of all other routes
    {
        path: "/",
        element: <Navigate to="/dashboard" />
    }
];

const publicRoutes: RouteItem[] = [
    // Authentication Page
    { path: "/logout", element: <Logout /> },
    { path: "/login", element: <LoginPage /> },
    { path: "/forgot-password", element: <ForgetPasswordPage /> },
    { path: "/recover-password", element: <RecoverPasswordPage /> },
    { path: "/register", element: <RegisterPage /> },
    { path: "/verify-email", element: <VerifyEmail /> },
    { path: "/home", element: <Index /> }
];

export { authProtectedRoutes, publicRoutes };