import { useMutation } from "@tanstack/react-query";
import { patchCustomerInvoiceAddress } from "api/customer";
import type { AddressContract } from "api/types/contracts/common"
import type { InvoiceAddressForm } from "api/types/contracts/customers";
import BusyOverlay from "Components/Common/BusyOverlay";
import RequiredLabel from "Components/Common/RequiredLabel";
import CountrySelect from "Components/EntitySelects/CountrySelect";
import TextInput from "Components/Form/TextInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useFormik } from "formik";
import type { TypedShape } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Col, Form, Label, Row } from "reactstrap";
import * as Yup from "yup";

type InvoiceAddressFormProps = {
    customerId: string,
    invoiceAddress: AddressContract | undefined,
    onSuccess: () => void
}

const defaultAddress: InvoiceAddressForm = {
    addressTitle: "",
    name: "",
    companyName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    countryCode: ""
};

const createInvoiceAddressForm = (address: AddressContract | undefined) => {
    return address ? {
        addressTitle: address.addressTitle,
        name: address.name,
        companyName: address.companyName,
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        state: address.state,
        stateCode: address.stateCode,
        zipCode: address.zipCode,
        countryCode: address.countryCode
    } as InvoiceAddressForm : defaultAddress;
}

const InvoiceAddressEditForm = (props: InvoiceAddressFormProps) => {
    const { t } = useTranslation();
    const { mutate: saveAddress, isPending: saveAddressLoading } = useMutation({
        mutationKey: ["save-invoice-address"],
        mutationFn: patchCustomerInvoiceAddress,
        onSuccess: () => props.onSuccess()
    });

    const validation = useFormik({
        initialValues: createInvoiceAddressForm(props.invoiceAddress),
        validationSchema: Yup.object<InvoiceAddressForm, TypedShape<InvoiceAddressForm>>({
            addressTitle: Yup.string().notRequired(),
            name: Yup.string().required(t("Name is required")),
            companyName: Yup.string().required(t("Company Name is required")),
            address1: Yup.string().required(t("Address is required")),
            address2: Yup.string().notRequired(),
            city: Yup.string().required(t("City is required")),
            state: Yup.string().required(t("State is required")),
            zipCode: Yup.string().required(t("Zip Code is required")),
            countryCode: Yup.string().required(t("Country Code is required"))
        }).required(),
        onSubmit: (values) => {
            saveAddress({
                customerId: props.customerId,
                address: {
                    ...values
                }
            });
        }
    });
    
    return <>
        <div>
            <div>
                <h5 className="mb-1">{t("Update Billing Address")}</h5>
                <p className="text-muted">
                    {t("paragraphs:UpdateBillingAddressDescription")}
                </p>
            </div>
            <Form onSubmit={validation.handleSubmit}>
                <Row className="g-3">
                    <Col xs={12}>
                        <RequiredLabel>{t("Full Name")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="name">
                            <TextInput placeholder={t("Enter your full name")} />
                        </ValidationWrapper>
                    </Col>
                    <Col xs={12}>
                        <Label>{t("Company Name")}</Label>
                        <ValidationWrapper validation={validation} field="companyName">
                            <TextInput placeholder={t("Enter your company name")} />
                        </ValidationWrapper>
                    </Col>
                    <Col xs={12}>
                        <RequiredLabel>{t("Street Address")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="address1">
                            <TextInput placeholder={t("123 Main Street")} />
                        </ValidationWrapper>
                    </Col>
                    <Col xs={12}>
                        <Label>{t("Address Line 2")}</Label>
                        <ValidationWrapper validation={validation} field="address2">
                            <TextInput placeholder={t("Apartment, suite, etc.")} />
                        </ValidationWrapper>
                    </Col>
                    <Col xs={6}>
                        <RequiredLabel>{t("City")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="city">
                            <TextInput placeholder={t("Enter your city")} />
                        </ValidationWrapper>
                    </Col>
                    <Col xs={6}>
                        <RequiredLabel>{t("State/Province/Region")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="state">
                            <TextInput placeholder={t("Enter your state or province")} />
                        </ValidationWrapper>
                    </Col>
                    <Col xs={6}>
                        <RequiredLabel>{t("Postal Code")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="zipCode">
                            <TextInput placeholder={t("Enter your ZIP or postal code")} />
                        </ValidationWrapper>
                    </Col>
                    <Col xs={6}>
                        <RequiredLabel>{t("Country")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="countryCode">
                            <CountrySelect />
                        </ValidationWrapper>
                    </Col>
                    <Col xs={12}>
                        <div className="d-flex justify-content-end">
                            <BusyOverlay busy={saveAddressLoading} size="sm">
                                <ValidatorButton validation={validation} color="primary" type="submit">
                                    {t("Save Address")}
                                </ValidatorButton>
                            </BusyOverlay>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    </>;
}

export default InvoiceAddressEditForm;