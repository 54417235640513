import { useQuery } from "@tanstack/react-query";
import SelectInput from "Components/Form/SelectInput";
import { getCustomerListForSelect } from "api/customer";
import { CustomerContract } from "api/types/contracts/customers";
import { ListCustomersQueryForSelect } from "api/types/queries";
import { EntitySelectInputProps } from "helpers/types";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useImmer } from "use-immer";
import { useDebounce } from "@uidotdev/usehooks";

type CustomerSelectProps = EntitySelectInputProps<CustomerContract, "customerId"> & {
    companyId?: string
}

const CustomerSelect = ({ isMulti, onChange, onSelect, isValid, companyId, value, ...rest }: CustomerSelectProps) => {
    const { t } = useTranslation();
    const [query, updateQuery] = useImmer<ListCustomersQueryForSelect>({
        companyId: "",
        search: ""
    });

    const debouncedQuery = useDebounce(query, 500);

    const { data: customers, isFetching: loading } = useQuery({
        queryKey: ["customers", debouncedQuery],
        queryFn: () => getCustomerListForSelect(debouncedQuery),
        enabled: !!query.search
    })

    const customerList = useMemo(() => customers?.map(c => ({ 
        value: c.customerId, 
        label: `${c.name} ${c.surname}`
    })) ?? [], [customers]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = customers?.filter(c => val.includes(c.customerId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = customers?.find(c => c.customerId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.customerId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        updateQuery(q => {
            if (companyId) {
                q.companyId = companyId;
            }
        });
    }, [updateQuery, companyId]);

    const onSearch = useCallback((val?: string) => {
        updateQuery(q => {
            q.search = val;
        });
    }, [updateQuery]);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (!customers || customers.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customers]);

    const selectProps = {
        ...rest,
        placeholder: rest.placeholder ?? t("Customer"),
        busy: loading,
        options: customerList,
        noOptionsMessage: t("Start typing to search customers"),
        onSearch: onSearch,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default CustomerSelect;