import type { CustomerContract } from "api/types/contracts/customers";
import { useState } from "react";
import Currency from "./UnitDisplay/Currency";
import { useQuery } from "@tanstack/react-query";
import { getWallet } from "api/wallet";
import BusyOverlay from "Components/Common/BusyOverlay";

type BalanceDisplayProps = {
    customer: CustomerContract,
    warehouseId: string
};

const BalanceDisplay = (props: BalanceDisplayProps) => {
    const customerWallet = props.customer.wallets?.find(w => w.warehouseId === props.warehouseId);
    
    const { data: wallet, isFetching: loading } = useQuery({
        queryKey: ["wallet", props.customer.customerId, props.warehouseId],
        queryFn: () => getWallet({ customerId: props.customer.customerId, warehouseId: props.warehouseId }),
        enabled: customerWallet === undefined,
        initialData: customerWallet
    });

    return <BusyOverlay busy={loading} size="sm" spinnerColor="tenant-primary" backgroundColor="body-secondary" opaque inline>
        <Currency value={wallet?.balance ?? 0} currency={wallet?.unitOfCurrency ?? "usd"} />
    </BusyOverlay>;
}

export default BalanceDisplay;