import { InfoAlert } from "Components/Alerts";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import Loader from "Components/Common/Loader";
import Restricted from "Components/Common/Restricted";
import DateDisplay from "Components/Displays/DateDisplay";
import InvoiceStatusBadge from "Components/Displays/InvoiceStatusBadge";
import Currency from "Components/Displays/UnitDisplay/Currency";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { createAppSelector, useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { InvoiceContract } from "api/types/contracts/payment";
import { FbaShipmentStatus } from "api/types/contracts/shipping";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, CardTitle, Table } from "reactstrap";
import { cancelFbaShipmentInvoice, loadFbaShipment, payFbaShipmentInvoice, refundFbaShipmentInvoice } from "slices/shipping/fbaShipping/thunk";

type InvoiceListProps = {
    
}

const InvoiceList = (props: InvoiceListProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { userProfile } = useProfile();
    const payConfirmationDialogRef = useRef<DialogRef>(null);
    const refundConfirmationDialogRef = useRef<DialogRef>(null);
    const cancelConfirmationDialogRef = useRef<DialogRef>(null);
    const [selectedInvoice, setSelectedInvoice] = useState<InvoiceContract>();

    const pay = (invoice: InvoiceContract) => {
        setSelectedInvoice(invoice);
        payConfirmationDialogRef.current?.show();
    }

    const refund = (invoice: InvoiceContract) => {
        setSelectedInvoice(invoice);
        refundConfirmationDialogRef.current?.show();
    }

    const cancel = (invoice: InvoiceContract) => {
        setSelectedInvoice(invoice);
        cancelConfirmationDialogRef.current?.show();
    }

    const { fbaShipment, loading } = useAppSelector(
        createAppSelector([state => state.FbaShipping], 
            (fbaShipping) => ({
                fbaShipment: fbaShipping.processingItem,
                loading: fbaShipping.loading
            })
        )
    );

    if (!fbaShipment) {
        return <Loader height="50px" />;
    }

    const first = fbaShipment.invoices.length === 0;

    return <>
        <Card>
            <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                    <CardTitle tag="h5" className="mb-0">{t("Invoices")}</CardTitle>
                    {Array<FbaShipmentStatus>("processing", "boxLabelPending", "boxLabelUploaded", "paymentWaiting", "paymentCompleted").includes(fbaShipment.status) && <>
                        <Restricted require="shipping.fbashipping" write>
                            <Link to={`/create-invoice/${fbaShipment.fbaShipmentId}?first=${first}`} className="btn btn-info btn-label">
                                <i className="ri-add-fill label-icon align-middle me-1"></i> {t("Create Invoice")}
                            </Link>
                        </Restricted>
                    </>}
                </div>
            </CardHeader>
            <CardBody>
                {fbaShipment.invoices.length > 0 ? <>
                    <div className="table-responsive table-card">
                        <Table className="mb-0 align-middle">
                            <thead className="table-light text-muted">
                                <tr>
                                    <th>{t("Action")}</th>
                                    <th>{t("ID")}</th>
                                    <th>{t("Date")}</th>
                                    <th>{t("Total")}</th>
                                    <th>{t("Status")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fbaShipment.invoices.map((invoice, index) => <tr key={index}>
                                    <td>
                                        <div className="hstack gap-1">
                                            <Link to={`/invoice/${invoice.invoiceId}`} className="btn btn-secondary btn-sm btn-icon">
                                                <i className="ri-eye-fill fs-16"></i>
                                            </Link>
                                            {userProfile?.user.userId === fbaShipment.customer.user.userId && <>
                                                {invoice.status === "pending" && <Button onClick={() => pay(invoice)} color="success" size="sm" className="">
                                                    {t("Pay")}
                                                </Button>}
                                            </>}
                                            <Restricted require="shipping.fbashipping" write>
                                                {invoice.status === "pending" && <Button onClick={() => cancel(invoice)} color="warning" size="sm" className="">
                                                    {t("Cancel")}
                                                </Button>}
                                                {invoice.status === "paid" && <Button onClick={() => refund(invoice)} color="danger" size="sm" className="">
                                                    {t("Refund")}
                                                </Button>}
                                            </Restricted>
                                        </div>
                                    </td>
                                    <td>
                                        <span># {invoice.invoiceCode}</span>
                                    </td>
                                    <td>
                                        <DateDisplay date={invoice.invoiceDate} />
                                    </td>
                                    <td>
                                        <Currency value={invoice.total} currency={fbaShipment.warehouse.settings.unitOfCurrency} />
                                    </td>
                                    <td>
                                        <InvoiceStatusBadge value={invoice.status} />
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </div>
                </> : <>
                    <InfoAlert className="mb-0">{t("No invoices has been created yet")}</InfoAlert>
                </>}
            </CardBody>
        </Card>
        <Dialog ref={payConfirmationDialogRef} color="info" buttons={["yes", "no"]} busy={loading.invoicePay} iconClass="ri-money-dollar-circle-line"  
            message={`Do you want to continue?`} title={`You are about to pay the invoice`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    dispatch(payFbaShipmentInvoice({
                        invoiceId: selectedInvoice!.invoiceId
                    })).then(() => {
                        hide();
                        setSelectedInvoice(undefined);
                        dispatch(loadFbaShipment({ fbaShipmentId: fbaShipment.fbaShipmentId }));
                    });
                }
                else {
                    hide();
                }
            }} />
        <Dialog ref={cancelConfirmationDialogRef} color="warning" buttons={["yes", "no"]} busy={loading.invoiceCancel} iconClass="ri-close-circle-line"  
            message={`Do you want to continue?`} title={`You are about to cancel the invoice`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    dispatch(cancelFbaShipmentInvoice({
                        invoiceId: selectedInvoice!.invoiceId
                    })).then(() => {
                        hide();
                        setSelectedInvoice(undefined);
                        dispatch(loadFbaShipment({ fbaShipmentId: fbaShipment.fbaShipmentId }));
                    });
                }
                else {
                    hide();
                }
            }} />

        <Dialog ref={refundConfirmationDialogRef} color="danger" buttons={["yes", "no"]} busy={loading.invoiceRefund} iconClass="ri-refund-2-line"  
            message={`Do you want to continue?`} title={`You are about to refund the invoice`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    dispatch(refundFbaShipmentInvoice({
                        invoiceId: selectedInvoice!.invoiceId
                    })).then(() => {
                        hide();
                        setSelectedInvoice(undefined);
                        dispatch(loadFbaShipment({ fbaShipmentId: fbaShipment.fbaShipmentId }));
                    });
                }
                else {
                    hide();
                }
            }} />
    </>;
}

export default InvoiceList;