import { useQuery } from "@tanstack/react-query";
import SelectInput from "Components/Form/SelectInput"
import { getCarrierAccountServiceList } from "api/carrier";
import { CarrierAccountServiceContract } from "api/types/contracts/carrier";
import { EntitySelectInputProps } from "helpers/types";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

type CarrierAccountServiceSelectProps = EntitySelectInputProps<CarrierAccountServiceContract, "carrierAccountServiceId"> & {
    shipmentTargetId: string,
    carrierId?: string,
    warehouseId: string,
}

const CarrierAccountServiceSelect = ({ isMulti, value, onChange, onSelect, name, isValid, shipmentTargetId, warehouseId, carrierId, ...rest }: CarrierAccountServiceSelectProps) => {
    const { t } = useTranslation();

    const { data: carrierAccountServices, isPending: loading } = useQuery({
        queryKey: ["carrierAccountServices", shipmentTargetId, warehouseId],
        queryFn: () => getCarrierAccountServiceList({
            shipmentTargetId,
            warehouseId
        })
    });

    const carrierAccountServiceList = useMemo(() => carrierAccountServices?.filter(c => c.carrierId === carrierId)
        .map(c => ({ 
            value: c.carrierAccountServiceId, 
            label: c.carrierService.name 
        })) ?? [], 
    [carrierAccountServices, carrierId]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = carrierAccountServices?.filter(c => val.includes(c.carrierAccountServiceId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = carrierAccountServices?.find(c => c.carrierAccountServiceId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.carrierAccountServiceId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        if (!value) {
            return;
        }

        if (!carrierAccountServices || carrierAccountServices.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carrierAccountServices]);
    
    const selectProps = {
        ...rest,
        placeholder: rest.placeholder ?? t("Carrier Service"),
        busy: loading,
        options: carrierAccountServiceList,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default CarrierAccountServiceSelect;
