import ModalCloseButton from "Components/Common/ModalCloseButton";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Modal, ModalBody } from "reactstrap";
import { Elements, useStripe, useElements, PaymentElement, CardElement } from '@stripe/react-stripe-js'; 
import { loadStripe } from '@stripe/stripe-js';
import { useMutation } from "@tanstack/react-query";
import BusyOverlay from "Components/Common/BusyOverlay";
import { postCreateSetupIntent, postCreateStripeCreditCard } from "api/stripe";
import type { WarehouseContract } from "api/types/contracts/warehouses";

type LinkCreditCardProps = {
    warehouse: WarehouseContract,
    onSuccess: () => void
}

const LinkCreditCard = (props: LinkCreditCardProps) => {
    const [linkCardModal, setLinkCardModal] = useState(false);
    const { t } = useTranslation();

    const stripePublishableKey = props.warehouse.settings.stripe?.publishableKey;

    const stripePromise = useMemo(() => stripePublishableKey ? loadStripe(stripePublishableKey) : null, 
        [stripePublishableKey]);

    return <>
        <Button color="ghost-primary" className="btn-label" onClick={() => setLinkCardModal(true)}>
            <i className="ri-add-fill label-icon fs-16"></i>
            <span>{t("Link Credit/Debit Card")}</span>
        </Button>
        <Modal backdrop="static" size="md" isOpen={linkCardModal} toggle={() => setLinkCardModal(prev => !prev)}>
            <ModalBody>
                <ModalCloseButton onClick={() => setLinkCardModal(prev => !prev)} />
                <Elements stripe={stripePromise} options={{
                    mode: "setup",
                    currency: props.warehouse.settings.unitOfCurrency,
                    payment_method_types: ["card"]
                }}>
                    <CardModalForm warehouse={props.warehouse} onSuccess={() => {
                        setLinkCardModal(false);
                        props.onSuccess();
                    }} />
                </Elements>   
            </ModalBody>
        </Modal>
    </>;
}

const CardModalForm = (props: LinkCreditCardProps) => {
    const { t } = useTranslation();

    const stripe = useStripe();
    const elements = useElements();

    const linkCardMutation = useMutation({
        mutationFn: async () => {
            if (!stripe || !elements)  {
                throw new Error("Not ready");
            }

            const { error: submitError } = await elements.submit();
            if (submitError) {
                throw new Error(submitError.message);
            }

            const intentCreate = await postCreateSetupIntent({ 
                warehouseId: props.warehouse.warehouseId 
            });

            const { error, setupIntent } = await stripe.confirmSetup({
                elements,
                clientSecret: intentCreate.clientSecret,
                confirmParams: {
                    return_url: window.location.href,
                },
                // Uncomment below if you only want redirect for redirect-based payments
                redirect: "if_required",
            });
    
            if (error) {
                throw new Error(error.message);
            }
            
            if (typeof setupIntent.payment_method === "string") {
                await postCreateStripeCreditCard({
                    identifier: setupIntent.payment_method,
                    warehouseId: props.warehouse.warehouseId
                });
            }
        },
        onSuccess: (data, variables, context) => {
            props.onSuccess();
        }
    });

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        linkCardMutation.mutate();
    }

    return <Form action="#" onSubmit={handleSubmit}>
        <PaymentElement />
        <div className="d-flex justify-content-end mt-3">
            <BusyOverlay busy={linkCardMutation.isPending} size="sm">
                <Button type="submit" color="primary">{t("Link Card")}</Button>
            </BusyOverlay>
        </div>
    </Form>;
}

export default LinkCreditCard;