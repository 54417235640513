import { useMutation } from '@tanstack/react-query';
import { DangerAlert } from 'Components/Alerts';
import BusyOverlay from 'Components/Common/BusyOverlay';
import RequiredLabel from 'Components/Common/RequiredLabel';
import Restricted from 'Components/Common/Restricted';
import BinTypeSelect from 'Components/EnumSelects/BinTypeSelect';
import NumberInput from 'Components/Form/NumberInput';
import LengthInput from 'Components/Form/UnitInputs/LengthInput';
import ValidationWrapper from 'Components/Form/Validated/ValidationWrapper';
import ValidatorButton from 'Components/Form/Validated/ValidatorButton';
import { useMetadata } from 'Components/Hooks/MetadataHooks';
import { useAppDispatch, useAppSelector } from 'Components/Hooks/StoreHooks';
import { putBin } from 'api/bin';
import { BinContract, BinForm, BinType } from 'api/types/contracts/bin';
import { useFormik } from 'formik';
import { TypedShape } from 'helpers/types';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import { updateBin } from 'slices/bins/thunk';
import * as Yup from 'yup';

type FormProps = {
    bin: BinContract,
    onSuccess: VoidFunction
}

const createBinForm = (bin: BinContract): BinForm => {
    return {
        type: bin.type,
        height: bin.height,
        width: bin.width,
        length: bin.length
    }
};

const UpdateBinForm = (props: FormProps) => {
    const bin = createBinForm(props.bin);
    const { setPageTitle } = useMetadata();
    const { t } = useTranslation();

    setPageTitle(`${t("Update Bin")} - ${t("Management")}`);

    const updateBinMutation = useMutation({
        mutationFn: putBin,
        mutationKey: ["update-bin"],
        onSuccess: () => {
            props.onSuccess();
        }
    });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: bin,
        validationSchema: Yup.object<BinForm, TypedShape<BinForm>>({
            type: Yup.string<BinType>().required(t("Please enter bin type")),
            height: Yup.number().notRequired(),
            width: Yup.number().notRequired(),
            length: Yup.number().notRequired()
        }),
        onSubmit: (values) => {
            updateBinMutation.mutate({
                binId: props.bin.binId,
                bin: values
            });
        },
    });

    const lengthUnit = props.bin.warehouse.settings.unitOfLength;
    
    return <>
        <Form className="needs-validation" action="#" onSubmit={validation.handleSubmit}>
            <div>
                <Row className='g-3'>
                    <Restricted require="management.company" read>
                        <Col xs={4}>
                            <RequiredLabel>{t("Company")}</RequiredLabel>
                            <Input type="text" readOnly className="bg-light" defaultValue={props.bin.warehouse.company.name} />
                        </Col>
                    </Restricted>
                    <Col xs={4}>
                        <RequiredLabel>{t("Warehouse")}</RequiredLabel>
                        <Input type="text" readOnly className="bg-light" defaultValue={props.bin.warehouse.name} />
                    </Col>
                    <Col xs={4}>
                        <RequiredLabel htmlFor="binType">{t("Bin Type")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="type">
                            <BinTypeSelect />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={7}>
                        <Label>{t("Dimensions")}</Label>
                        <div className="hstack gap-2">
                            <InputGroup>
                                <ValidationWrapper validation={validation} field="width">
                                    <NumberInput placeholder="Enter Width" />
                                </ValidationWrapper>
                                <ValidationWrapper validation={validation} field="height">
                                    <NumberInput placeholder="Enter Height" />
                                </ValidationWrapper>
                                <ValidationWrapper validation={validation} field="length">
                                    <NumberInput placeholder="Enter Length" />
                                </ValidationWrapper>
                                <InputGroupText>
                                    {lengthUnit}
                                </InputGroupText>
                            </InputGroup>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="mt-4">{updateBinMutation.isError && <DangerAlert>{updateBinMutation.error.message}</DangerAlert>}</div>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={updateBinMutation.isPending} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
}

export default UpdateBinForm;