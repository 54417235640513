import { Col, Form, Label, Row } from "reactstrap";
import { CustomerContract, CustomerForm } from "api/types/contracts/customers";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import * as Yup from "yup";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import CountrySelect from "Components/EntitySelects/CountrySelect";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import BusyOverlay from "Components/Common/BusyOverlay";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import PhoneInput from "Components/Form/PhoneInput";
import TextInput from "Components/Form/TextInput";
import { useMutation } from "@tanstack/react-query";
import { putCustomer } from "api/customer";
import { DangerAlert } from "Components/Alerts";
import { useState } from "react";
import type { CountryContract } from "api/types/contracts/common";

type FormProps = {
    customer: CustomerContract,
    onSuccess?: VoidFunction
}

const createCustomerForm = (customer: CustomerContract): CustomerForm => {
    return {
        name: customer.name,
        surname: customer.surname,
        email: customer.user.email,
        firmName: customer.firmName,
        phone: customer.phone,
        countryCode: customer.country.code,
        availableWarehouses: customer.properties.availableWarehouses
    }
};

const UpdateForm = (props: FormProps) => {
    const customer = createCustomerForm(props.customer);
    const [country, setCountry] = useState<CountryContract>();
    const { t } = useTranslation();

    const updateCustomerMutation = useMutation({
        mutationFn: putCustomer,
        onSuccess: () => {
            props.onSuccess?.();
        }
    })

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: customer,
        validationSchema: Yup.object<CustomerForm, TypedShape<CustomerForm>>({
            name: Yup.string().required(t("Name is required")),
            surname: Yup.string().required(t("Surname is required")),
            email: Yup.string().required(t("Email is required")),
            firmName: Yup.string().notRequired(),
            phone: Yup.string().required(t("Phone is required")).min(10, t("Please enter a valid Phone Number")),
            countryCode: Yup.string().required(t("Country is required")),
            availableWarehouses: Yup.array().required(t("Warehouses are required")),
        }),
        onSubmit: values => {
            updateCustomerMutation.mutate({
                customerId: props.customer.customerId,
                customer: values
            });
        },
    });

    return <>
        <Form onSubmit={validation.handleSubmit} className="needs-validation">
            <Row>
                <Col lg={6}>
                    <Row>
                        <Col lg={6} className="g-3">
                            <Label htmlFor="name">{t("First Name")}</Label>
                            <ValidationWrapper validation={validation} field="name">
                                <TextInput />
                            </ValidationWrapper>
                        </Col>
                        <Col lg={6} className="g-3">
                            <Label htmlFor="surname">{t("Surname")}</Label>
                            <ValidationWrapper validation={validation} field="surname">
                                <TextInput />
                            </ValidationWrapper>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="g-3">
                            <Label htmlFor="firmName">{t("Company")}</Label>
                            <ValidationWrapper validation={validation} field="firmName">
                                <TextInput />
                            </ValidationWrapper>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="g-3">
                            <Label htmlFor="phone">{t("Phone")}</Label>
                            <ValidationWrapper validation={validation} field="phone">
                                <PhoneInput country={country?.iso2Code} />
                            </ValidationWrapper>
                        </Col>
                    </Row>
                </Col>
                <Col lg={6}>
                    <Row>
                        <Col className="g-3">
                            <Label htmlFor="countryCode">{t("Country")}</Label>
                            <ValidationWrapper validation={validation} field="countryCode">
                                <CountrySelect onSelect={setCountry} />
                            </ValidationWrapper>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="g-3">
                            <Label htmlFor="availableWarehouses">{t("Warehouses")}</Label>
                            <ValidationWrapper validation={validation} field="availableWarehouses">
                                <WarehouseSelect isMulti />
                            </ValidationWrapper>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col lg={12} className="mt-auto">
                    <div className="hstack gap-2 justify-content-end">
                        {updateCustomerMutation.isError && <DangerAlert className="mb-0 py-2 fs-13">
                            {updateCustomerMutation.error.message}
                        </DangerAlert>}
                        <BusyOverlay busy={updateCustomerMutation.isPending} size="sm">
                            <ValidatorButton type="submit" className="btn btn-primary" validation={validation}>{t("Save")}</ValidatorButton>
                        </BusyOverlay>
                    </div>
                </Col>
            </Row>
        </Form>
    </>;
}

export default UpdateForm;