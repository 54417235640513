import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { reloadLocations } from "slices/location/thunk";
import CreateShelfFormView from "./_CreateShelfFormView";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

const NewShelfButton = () => {
    const [newModal, setNewModal] = useState(false);
    const { t } = useTranslation();
    //const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { warehouse, selectedBay } = useAppSelector(
        (state) => {
            const location = state.Location;
            const selectedArea = location.areas?.find(a => a.areaId === location.selectedAreaId);
            const selectedAisle = selectedArea?.aisles?.find(a => a.aisleId === location.selectedAisleId);
            const selectedBay = selectedAisle?.bays?.find(b => b.bayId === location.selectedBayId);

            return {
                ...location,
                selectedArea,
                selectedAisle,
                selectedBay
            };
        }
    );

    const onShelfAdded = () => { 
        setNewModal(false);
        dispatch(reloadLocations({ warehouseId: warehouse!.warehouseId }));
    };

    return warehouse ? <>
        <Button color="info" onClick={() => setNewModal(true)}>
            <i className="ri-add-line align-bottom me-1"></i> {t("Add New Shelf")}
        </Button>
        <Modal backdrop="static" isOpen={newModal} toggle={() => setNewModal(prev => !prev)}>
            <ModalCloseButton onClick={() => setNewModal(false)} />
            <ModalHeader>
                {t("Add Shelves")}
            </ModalHeader>
            <ModalBody>
                <CreateShelfFormView bayId={selectedBay!.bayId} onSuccess={onShelfAdded} />
            </ModalBody>
        </Modal>
    </> : null;
};

export default NewShelfButton;