import classNames from 'classnames';
import { ValidatableInputProps } from 'helpers/types';
import { useTranslation } from 'react-i18next';
import PhoneInput2, { PhoneInputProps as PhoneInput2Props } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { InputProps } from 'reactstrap';

export type PhoneInputProps = Pick<InputProps, "onKeyDown" | "id" | "bsSize" | "disabled" | "onBlur" | "className" | "style" | "placeholder"> & ValidatableInputProps<string> & { 
    country?: string
}

const PhoneInput = ({ className, value, country, bsSize, isValid, onChange, isMulti, name, id, ...rest }: PhoneInputProps) => {
    const { t } = useTranslation();

    const inputProps: PhoneInput2Props = {
        enableSearch: true,
        searchPlaceholder: t("Search for country"),
        searchClass: "phone-input-search",
        onChange: (value, data, event, formattedValue) => {
            onChange?.(formattedValue);
        },
        inputClass: classNames("w-100 fs-13 h-auto lh-base", className, {
            "form-control-sm": bsSize === "sm",
            "form-control-lg": bsSize === "lg",
            "is-invalid": isValid === false,
        }),
        searchNotFound: t("Country not found"),
        disableSearchIcon: true,
        value,
        country: country?.toLowerCase(),
        inputProps: {
            value: value || "",
            name,
            id
        },
        ...rest
    }

    return <PhoneInput2 {...inputProps} />
}

export default PhoneInput;