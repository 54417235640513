import React from "react";
import Loader from "./Loader";
import { Await } from "react-router-dom";

type ResolveWrapperProps<T> = {
    promise: Promise<T> | undefined,
    children: (value: T) => React.ReactNode
}
const ResolveWrapper = <T = any>({ promise, children }: ResolveWrapperProps<T>) => {
    
    return <React.Suspense fallback={<Loader height="400px" />}>
        <Await resolve={promise}>
            {(resolvedVal: T) => children(resolvedVal)}
        </Await>
    </React.Suspense>;
};

export default ResolveWrapper;