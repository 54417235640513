import { deleteFbaShipmentDocuments, cancelFbaShipping, getFbaShipment, getFbaShipmentsList, patchFbaShipmentItems, postFbaShipment, putFbaShipmentDocuments, putFbaShipmentPackages, patchFbaShipmentItemPrepService, patchFbaShipment, deleteFbaShipmentItem, postFbaShipmentItems, postFbaShipmentTransports, deleteFbaShipmentTransport, postStartProcessingFbaShipment } from "api/fbaShipping";
import type { AddFbaShipmentDocumentsCommand, AddFbaShipmentItemsCommand, ClearFbaShipmentDocumentsCommand, CreateFbaShipmentCommand, CreateFbaShipmentTransportsCommand, CancelFbaShipmentCommand, DeleteFbaShipmentItemCommand, DeleteFbaShipmentTransportCommand, UpdateFbaShipmentCommand, UpdateFbaShipmentItemPrepServiceCommand, UpdateFbaShipmentItemsCommand, UpdateFbaShipmentPackagesCommand, CancelInvoiceCommand, GenerateFnskuLabelCommand, PayInvoiceCommand, PrintDocumentCommand, RefundInvoiceCommand, StartProcessFbaShipmentCommand } from "api/types/commands";
import { GetFbaShippingQuery, ListFbaShipmentsQuery } from "api/types/queries";
import { AppDispatch, RootState } from "slices";
import { apiError, loading, setList, setProcessingItem } from "./reducer";
import { ApiError } from "helpers/types";
import { toast } from "react-toastify";
import i18n from "i18n";
import { postCancelInvoice, postPayInvoice, postRefundInvoice } from "api/invoice";
import { postGenerateFnskuLabel, postPrintDocument } from "api/printing";
import { multiDownload, withCdn } from "helpers/urlHelper";

export const createFbaShipment = (params: CreateFbaShipmentCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));
        var result = await postFbaShipment(params);

        if (result) {
            toast.success(i18n.t("Shipment created"));
        }
        
        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const loadList = (params: ListFbaShipmentsQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["list", true]));
        var result = await getFbaShipmentsList(params);
        
        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
}

export const removeFbaShipment = (params: CancelFbaShipmentCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        await cancelFbaShipping(params);

        return true;
        
    } catch (error) {
        dispatch(apiError(error as ApiError));

        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const loadFbaShipment = (params: GetFbaShippingQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["load", true]));
        var result = await getFbaShipment(params);
        
        dispatch(setProcessingItem(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["load", false]));
    }
}

export const updateFbaShipmentItems = (params: UpdateFbaShipmentItemsCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));
        const updatedFbaShipment = await patchFbaShipmentItems(params);

        toast.success(i18n.t("Items updated"));

        dispatch(setProcessingItem(updatedFbaShipment));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const addFbaShipmentItems = (params: AddFbaShipmentItemsCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));
        const updatedFbaShipment = await postFbaShipmentItems(params);

        toast.success(i18n.t("Item(s) added"));

        dispatch(setProcessingItem(updatedFbaShipment));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const updateFbaShipment = (params: UpdateFbaShipmentCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));
        const updatedFbaShipment = await patchFbaShipment(params);

        toast.success(i18n.t("Update successful"));
        
        dispatch(setProcessingItem(updatedFbaShipment));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const startProcessingFbaShipment = (params: StartProcessFbaShipmentCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));
        const updatedFbaShipment = await postStartProcessingFbaShipment(params);

        toast.success(i18n.t("Update successful"));
        
        dispatch(setProcessingItem(updatedFbaShipment));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const updateFbaShipmentPackages = (params: UpdateFbaShipmentPackagesCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));
        const updateFbaShipment = await putFbaShipmentPackages(params);

        toast.success(i18n.t("Packages updated"));

        dispatch(setProcessingItem(updateFbaShipment));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const uploadFbaShipmentDocuments = (params: AddFbaShipmentDocumentsCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        const updatedFbaShipment = await putFbaShipmentDocuments(params);

        toast.success(i18n.t("Documents uploaded"));

        dispatch(setProcessingItem(updatedFbaShipment));

    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const clearFbaShipmentDocuments = (params: ClearFbaShipmentDocumentsCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        const updatedFbaShipment = await deleteFbaShipmentDocuments(params);

        toast.success(i18n.t("Documents removed"));

        dispatch(setProcessingItem(updatedFbaShipment));

    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const payFbaShipmentInvoice = (params: PayInvoiceCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["invoicePay", true]));

        await postPayInvoice(params);

        toast.success(i18n.t("Invoice paid"));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["invoicePay", false]));
    }
}

export const cancelFbaShipmentInvoice = (params: CancelInvoiceCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["invoiceCancel", true]));

        await postCancelInvoice(params);

        toast.success(i18n.t("Invoice cancelled"));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["invoiceCancel", false]));
    }
}

export const refundFbaShipmentInvoice = (params: RefundInvoiceCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["invoiceRefund", true]));

        await postRefundInvoice(params);

        toast.success(i18n.t("Invoice refunded"));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["invoiceRefund", false]));
    }
}

export const updateFbaShipmentItemPrepService = (params: UpdateFbaShipmentItemPrepServiceCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        await patchFbaShipmentItemPrepService(params);

        toast.success(i18n.t("Saved"));

        const fbaShipment = getState().FbaShipping.processingItem!;

        dispatch(setProcessingItem({
            ...fbaShipment,
            items: fbaShipment.items.map(item => item.fbaShipmentItemId === params.fbaShipmentItemId ? {
                ...item,
                prepServices: item.prepServices.map(p => p.prepService.prepServiceId === params.prepServiceId ? {
                    ...p,
                    completed: params.completed
                } : p)
            } : item)
        }));

    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
}

export const removeFbaShipmentItem = (params: DeleteFbaShipmentItemCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        const updatedFbaShipment = await deleteFbaShipmentItem(params);

        toast.success(i18n.t("Item removed"));

        dispatch(setProcessingItem(updatedFbaShipment));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const createFbaShipmentTransports = (params: CreateFbaShipmentTransportsCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        const updatedFbaShipment = await postFbaShipmentTransports(params);
        
        toast.success(i18n.t("Shipments saved"));

        dispatch(setProcessingItem(updatedFbaShipment));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const removeFbaShipmentTransport = (params: DeleteFbaShipmentTransportCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        const updatedFbaShipment = await deleteFbaShipmentTransport(params);
        
        toast.success(i18n.t("Shipment deleted"));

        dispatch(setProcessingItem(updatedFbaShipment));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const generateFnskuLabel = (params: GenerateFnskuLabelCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["print", true]));

        const result = await postGenerateFnskuLabel(params);

        if (result.printQueued) {
            toast.success(i18n.t("Label sent to printer"));
        }
        else {
            await multiDownload([withCdn(result.filePath)]);
        }
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["print", false]));
    }
}

export const printDocument = (params: PrintDocumentCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["print", true]));

        const result = await postPrintDocument(params);

        if (result.printQueued) {
            toast.success(i18n.t("Document sent to printer"));
        }
        else {
            await  multiDownload([withCdn(result.filePath)]);
        }
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["print", false]));
    }
}