import { setList, loading, apiError } from "./reducer";
import { deleteCustomer, getCustomer, getCustomerList, putCustomer } from "api/customer";
import { GetCustomerQuery, ListCustomersQuery, type GetMyWalletQuery } from "api/types/queries";
import { DeleteCustomerCommand, UpdateCustomerCommand } from "api/types/commands";
import { ApiError } from "helpers/types";
import { toast } from "react-toastify";
import i18n from "i18n";
import { AppDispatch, RootState } from "slices";

export const loadCustomer = (params: GetCustomerQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        var result = await getCustomer(params);
        
        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
};

export const loadList = (params: ListCustomersQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["list", true]));

        var result = await getCustomerList(params);
        
        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
};

export const resetList = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setList({
        items: [],
        currentPage: 1,
        totalCount: 0
    }));
}

export const removeCustomer = (params: DeleteCustomerCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        await deleteCustomer(params);
        toast.success(i18n.t("Customer deleted", { count: 1 }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const removeMultipleCustomers = (params: DeleteCustomerCommand[]) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        for (const item of params) {
            await deleteCustomer(item);
        }

        toast.success(i18n.t("Customer deleted", { count: params.length }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}
