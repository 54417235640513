import { useMutation } from "@tanstack/react-query";
import { DangerAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";
import RequiredLabel from "Components/Common/RequiredLabel";
import NumberInput from "Components/Form/NumberInput";
import StepInput from "Components/Form/StepInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { createAppSelector, useAppSelector } from "Components/Hooks/StoreHooks";
import { postShelf } from "api/shelf";
import { CreateShelfCommand } from "api/types/commands";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Col, Form, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import { createShelf } from "slices/location/thunk";
import * as Yup from "yup";

type FormProps = {
    bayId: string,
    onSuccess: VoidFunction
}

type FormType = Omit<CreateShelfCommand, "bayId">;

const CreateShelfFormView = (props: FormProps) => {
    const { t } = useTranslation();
    
    const { warehouse } = useAppSelector(
        createAppSelector([state => state.Location], 
            location => ({
                warehouse: location.warehouse
            })
        )
    );

    const createShelfMutation = useMutation({
        mutationFn: postShelf,
        mutationKey: ["create-shelf"],
        onSuccess: () => {
            props.onSuccess();
        }
    });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            shelfCount: 1,
            binLocationCount: 0
        } as FormType,
        validationSchema: Yup.object<FormType, TypedShape<FormType>>({
            shelfCount: Yup.number().required("You must enter shelf count").min(1, t("Shelf count must be greater than zero")),
            binLocationCount: Yup.number().notRequired(),
            shelfHeight: Yup.number().notRequired(),
            shelfWidth: Yup.number().notRequired(),
            shelfDepth: Yup.number().notRequired(),
            binLocationWidth: Yup.number().notRequired(),
            binLocationHeight: Yup.number().notRequired(),
            binLocationDepth: Yup.number().notRequired(),
        }),
        onSubmit: (values) => {
            createShelfMutation.mutate({ 
                bayId: props.bayId,
                ...values
            });
        },
    });

    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <div>
                <Row className="g-3 mb-3">
                    <Col lg={6}>
                        <RequiredLabel htmlFor="shelfCount">{t("Shelf Count")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="shelfCount">
                            <StepInput min={1} />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={6}>
                        <Label htmlFor="binLocationCount">{t("Bin Locations for each shelf")}</Label>
                        <ValidationWrapper validation={validation} field="binLocationCount">
                            <StepInput min={0} />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={12}>
                        <Label>{t("Shelf Dimensions")}</Label>
                        <div className="hstack gap-2">
                            <InputGroup>
                                <ValidationWrapper validation={validation} field="shelfWidth">
                                    <NumberInput placeholder="Enter Width" />
                                </ValidationWrapper>
                                <ValidationWrapper validation={validation} field="shelfHeight">
                                    <NumberInput placeholder="Enter Height" />
                                </ValidationWrapper>
                                <ValidationWrapper validation={validation} field="shelfDepth">
                                    <NumberInput placeholder="Enter Length" />
                                </ValidationWrapper>
                                <InputGroupText>
                                    {warehouse?.settings.unitOfLength}
                                </InputGroupText>
                            </InputGroup>
                        </div>
                    </Col>
                </Row>
                {validation.values.binLocationCount! > 0 && <Row className="g-3">
                    <Col lg={12}>
                        <Label>{t("Bin Location Dimensions")}</Label>
                        <div className="hstack gap-2">
                            <InputGroup>
                                <ValidationWrapper validation={validation} field="binLocationWidth">
                                    <NumberInput placeholder="Enter Width" />
                                </ValidationWrapper>
                                <ValidationWrapper validation={validation} field="binLocationHeight">
                                    <NumberInput placeholder="Enter Height" />
                                </ValidationWrapper>
                                <ValidationWrapper validation={validation} field="binLocationDepth">
                                    <NumberInput placeholder="Enter Depth" />
                                </ValidationWrapper>
                                <InputGroupText>
                                    {warehouse?.settings.unitOfLength}
                                </InputGroupText>
                            </InputGroup>
                        </div>
                    </Col>
                </Row>}
            </div>
            <div className="mt-4">{createShelfMutation.isError && <DangerAlert>{createShelfMutation.error.message}</DangerAlert>}</div>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={createShelfMutation.isPending} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
};

export default CreateShelfFormView;
