import { useMutation } from "@tanstack/react-query";
import { DangerAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";
import RequiredLabel from "Components/Common/RequiredLabel";
import NumberInput from "Components/Form/NumberInput";
import StepInput from "Components/Form/StepInput";
import LengthInput from "Components/Form/UnitInputs/LengthInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { createAppSelector, useAppSelector } from "Components/Hooks/StoreHooks";
import { postBay } from "api/bay";
import { CreateBayCommand } from "api/types/commands";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Col, Form, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import * as Yup from "yup";

type FormProps = {
    aisleId: string,
    onSuccess: VoidFunction
}

type FormType = Omit<CreateBayCommand, "aisleId">;

const CreateBayFormView = (props: FormProps) => {
    const { t } = useTranslation();
    
    const createBayMutation = useMutation({
        mutationFn: postBay,
        mutationKey: ["create-bay"],
        onSuccess: () => {
            props.onSuccess();
        }
    });

    const { warehouse } = useAppSelector(
        createAppSelector([state => state.Location], 
            location => ({
                warehouse: location.warehouse
            })
        )
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            bayCount: 1,
            shelfCount: 0
        } as FormType,
        validationSchema: Yup.object<FormType, TypedShape<FormType>>({
            bayCount: Yup.number().required("You must enter bay count").min(1, t("Shelf bay must be greater than zero")),
            shelfCount: Yup.number().notRequired(),
            bayHeight: Yup.number().notRequired(),
            bayWidth: Yup.number().notRequired(),
            shelfHeight: Yup.number().notRequired(),
            shelfWidth: Yup.number().notRequired(),
            shelfDepth: Yup.number().notRequired(),
        }),
        onSubmit: (values) => {
            createBayMutation.mutate({ 
                aisleId: props.aisleId,
                ...values
            });
        },
    });

    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <div>
                <Row className="g-3 mb-3">
                    <Col lg={6}>
                        <RequiredLabel htmlFor="bayCount">{t("Bay Count")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="bayCount">
                            <StepInput min={1} placeholder="Enter Bay Count" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={6}>
                        <Label htmlFor="shelfCount">{t("Shelves for each bay")}</Label>
                        <ValidationWrapper validation={validation} field="shelfCount">
                            <StepInput min={0} />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={6}>
                        <Label htmlFor="bayWidth">{t("Bay Width")}</Label>
                        <ValidationWrapper validation={validation} field="bayWidth">
                            <LengthInput placeholder="Enter bay width" unit={warehouse?.settings.unitOfLength} />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={6}>
                        <Label htmlFor="bayHeight">{t("Bay Height")}</Label>
                        <ValidationWrapper validation={validation} field="bayHeight">
                            <LengthInput placeholder="Enter bay height" unit={warehouse?.settings.unitOfLength} />
                        </ValidationWrapper>
                    </Col>
                </Row>
                {validation.values.shelfCount !== 0 && <Row className="g-3">
                    <Col lg={12}>
                        <Label>{t("Shelf Dimensions")}</Label>
                        <div className="hstack gap-2">
                            <InputGroup>
                                <ValidationWrapper validation={validation} field="shelfWidth">
                                    <NumberInput placeholder="Enter Width" />
                                </ValidationWrapper>
                                <ValidationWrapper validation={validation} field="shelfHeight">
                                    <NumberInput placeholder="Enter Height" />
                                </ValidationWrapper>
                                <ValidationWrapper validation={validation} field="shelfDepth">
                                    <NumberInput placeholder="Enter Length" />
                                </ValidationWrapper>
                                <InputGroupText>
                                    {warehouse?.settings.unitOfLength}
                                </InputGroupText>
                            </InputGroup>
                        </div>
                    </Col>
                </Row>}
            </div>
            <div className="mt-4">{createBayMutation.isError && <DangerAlert>{createBayMutation.error.message}</DangerAlert>}</div>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={createBayMutation.isPending} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
};

export default CreateBayFormView;
