import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import List, { PrepServiceListRef } from "./_List";
import { Container, Modal, ModalBody } from "reactstrap";
import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import EditForm from "./EditForm";
import ResolveWrapper from "Components/Common/ResolveWrapper";
import { useAppDispatch } from "Components/Hooks/StoreHooks";
import { PrepServiceContract } from "api/types/contracts/shipping";

type PrepServicesPageProps = {
    edit?: boolean
}

type LoaderData = {
    prepService: Promise<PrepServiceContract>
} | undefined;

const PrepServicesPage = (props: PrepServicesPageProps) => {
    const loaderData = useLoaderData() as LoaderData;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const listRef = useRef<PrepServiceListRef>(null);

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("Prep Services")} parents={[t("Management")]} />
                <List ref={listRef} />
                {props.edit && 
                    <Modal backdrop="static" isOpen toggle={() => navigate("/prep-services")} unmountOnClose>
                        <ModalBody>
                            <ResolveWrapper promise={loaderData?.prepService}>
                                {resolvedPrepService => <>
                                    <ModalCloseButton onClick={() => navigate("/prep-services")} />
                                    <EditForm prepService={resolvedPrepService} onSuccess={() => {
                                        navigate("/prep-services");
                                        listRef.current?.reload();
                                    }} />
                                </>}
                            </ResolveWrapper>
                        </ModalBody>
                    </Modal>
                }
            </Container>
        </div>
    </>;
}

export default PrepServicesPage;