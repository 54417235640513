import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { InboundContract, InboundForm, InboundFormItem, InboundFormTracking, ShippingPurpose } from "api/types/contracts/inbound";
import { ProductContract } from "api/types/contracts/products";
import classNames from "classnames";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Button, Card, Col, Form, Modal, ModalBody, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table, UncontrolledAlert } from "reactstrap";
import * as Yup from "yup";
import QuickAddForm from "./_QuickAddForm";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import ProductPickList from "../../../Components/EntitySelects/ProductPickList";
import _ from "lodash";
import TextInput from "Components/Form/TextInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import NumberInput from "Components/Form/NumberInput";
import WarehouseGridSelect from "./_WarehouseGridSelect";
import ShippingPurposeRadio from "Components/EnumSelects/ShippingPurposeRadio";
import Radiobox from "Components/Form/Radiobox";
import ProductSupplierSelect from "Components/EntitySelects/ProductSupplierSelect";
import CarrierSelect from "Components/EntitySelects/CarrierSelect";
import DateInput from "Components/Form/DateInput";
import { WarehouseContract } from "api/types/contracts/warehouses";
import BusyOverlay from "Components/Common/BusyOverlay";
import { Link, useNavigate } from "react-router-dom";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { WarningAlert } from "Components/Alerts";
import AddressDisplay from "Components/Displays/AddressDisplay";
import DateDisplay from "Components/Displays/DateDisplay";
import TruncatableText from "Components/Common/TruncatableText";
import { CarrierContract } from "api/types/contracts/carrier";
import LinedHeader from "Components/Common/LinedHeader";
import { useMutation } from "@tanstack/react-query";
import { generateInboundCode, postInbound, putInbound } from "api/inbound";
import StepInput from "Components/Form/StepInput";
import ProductIdentifiersDisplay, { ProductIdentifiersHeader } from "Components/Displays/ProductIdentifiersDisplay";

export type EditFormProps = {
    inbound?: InboundContract
};

const createInboundForm = (inbound: InboundContract): InboundForm => ({
    inboundCode: inbound.inboundCode,
    notes: inbound.notes,
    shippingPurpose: inbound.shippingPurpose,
    warehouseId: inbound.warehouse.warehouseId,
    estimatedArrivalDate: inbound.estimatedArrivalDate,
    items: inbound.items.map(item => ({
        quantity: item.quantity,
        productId: item.product.productId,
    })),
    trackings: inbound.trackings.map(tracking => ({
        trackingNumber: tracking.trackingNumber,
        carrierId: tracking.carrier.carrierId
    })),
    fromAmazon: inbound.fromAmazon,
    isDraft: inbound.inboundStatus === "draft",
    suppliers: inbound.suppliers.map(s => s.supplierId)
});

const defaultForm: Partial<InboundForm> = {
    trackings: [],
    items: [],
    fromAmazon: true,
    isDraft: false
};

const EditForm = (props: EditFormProps) => {
    const { t } = useTranslation();
    const { userProfile, customer } = useProfile();
    const [activeTab, setActiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState<number[]>([]);
    const [productSelectModal, setProductSelectModal] = useState(false);
    const [quickAddModal, setQuickAddModal] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState<ProductContract[]>(props.inbound?.items.map(i => i.product) || []);
    const [destinationWarehouse, setDestinationWarehouse] = useState<WarehouseContract>();
    const [selectedCarriers, setSelectedCarriers] = useState<CarrierContract[]>([]);
    const navigate = useNavigate();

    const inbound = useMemo(() => props.inbound ? createInboundForm(props.inbound) : {
        ...defaultForm,
        warehouseId: userProfile?.user.warehouse?.warehouseId
    }, [props.inbound, userProfile]);
    
    useEffect(() => {
        if (!props.inbound) {
            inbound.warehouseId = userProfile?.user.warehouse?.warehouseId;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleProductSelectModal = () => setProductSelectModal(prev => !prev);
    const toggleQuickAddModal = () => setQuickAddModal(prev => !prev);

    const toggleTab = (tab: number) => {
        if (activeTab !== tab) 
            setActiveTab(tab);
    };

    const saveInboundMutation = useMutation({
        mutationFn: async (inbound: InboundForm) => {
            if (props.inbound?.inboundId) {
                await putInbound({ 
                    inboundId: props.inbound.inboundId, 
                    inbound 
                });
            }
            else {
                await postInbound({ 
                    inbound 
                });
            }
        },
        mutationKey: ["create-inbound"],
        onSuccess: () => {
            navigate("/inbounds");
        }
    });

    const generateInboundCodeMutation = useMutation({
        mutationFn: generateInboundCode,
        mutationKey: ["new-inbound-code"],
        onSuccess: (code) => {
            validation.setFieldValue("inboundCode", code);
        }
    });

    const deleteItem = (productId: string) => {
        setSelectedProducts(prev => prev.filter(p => p.productId !== productId));
    };

    useEffect(() => {
        const newItems = selectedProducts.map<InboundFormItem>(p => ({
            productId: p.productId,
            quantity: 1
        }));
        const items = validation.values.items && validation.values.items.length > 0 ? 
            _.uniqBy([...validation.values.items, ...newItems], "productId") 
            : newItems;

        validation.setFieldValue("items", items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProducts]);

    const addNewTrackingRow = () => {
        validation.setFieldValue("trackings", [...(validation.values.trackings || []), {
            trackingNumber: "",
            carrierId: ""
        }]);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: inbound,
        validationSchema: Yup.object<InboundForm, TypedShape<InboundForm>>({
            inboundCode: Yup.string().required(t("InboundCode not generated")),
            notes: Yup.string().notRequired(),
            estimatedArrivalDate: Yup.date().notRequired(),
            shippingPurpose: Yup.string<ShippingPurpose>().required(t("Shipping Purpose is required")),
            warehouseId: Yup.string().required(t("Warehouse is required")),
            items: Yup.array<InboundFormItem>().of(Yup.object({
                productId: Yup.string().required(t("Product is required")),
                quantity: Yup.number().required(t("Quantity is required"))
            })).required(t("Items is required")).min(1, t("At least one product is required")),
            trackings: Yup.array<InboundFormTracking>().of(Yup.object({
                trackingNumber: Yup.string().required(t("Tracking Number is required")),
                carrierId: Yup.string().required(t("Carrier is required"))
            })).required(t("Trackings is required")),
            fromAmazon: Yup.boolean().required(),
            isDraft: Yup.boolean().required(),
            suppliers: Yup.array().of(Yup.string().required()).when("fromAmazon", ([fromAmazon], schema) => {
                if (fromAmazon) {
                    return schema.notRequired()
                }
                else {
                    return schema.required(t("Supplier is required")).min(1, t("At least one supplier is required"));
                }
            })
        }),
        onSubmit: values => {
            const inbound = values as InboundForm;
            
            saveInboundMutation.mutate(inbound);
        },
    });

    useEffect(() => {
        if (activeTab === 3 && !validation.values.inboundCode) {
            generateInboundCodeMutation.mutate();
        }
    }, [activeTab, validation.values.inboundCode]);

    if (props.inbound && (props.inbound.inboundStatus !== "draft" && props.inbound.inboundStatus !== "confirmed")) {
        return <div className="text-center">
            <WarningAlert>{t("This inbound is not a draft and cannot be edited")}</WarningAlert>

            <div>
                <Link to="/inbounds" className="btn btn-primary">{t("See the inbounds list")}</Link>
            </div>
        </div>;
    }

    const submitForm = async (isDraft: boolean) => {
        await validation.setFieldValue("isDraft", isDraft);
        validation.handleSubmit();
    }

    return <>
        <Card body>
            <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
                <div className="step-arrow-nav mb-4">
                    <Nav className="nav-pills custom-nav nav-justified" role="tablist">
                        <NavItem>
                            <NavLink href="#" className={classNames({
                                active: activeTab === 1,
                                done: passedSteps.includes(1),
                            })} onClick={() => toggleTab(1)}>{t("Select Products")}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#" className={classNames({
                                active: activeTab === 2,
                                done: passedSteps.includes(2),
                            })} onClick={() => {}}>{t("Shipment Details")}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#" className={classNames({
                                active: activeTab === 3,
                                done: passedSteps.includes(3),
                            })} onClick={() => {}}>{t("Confirm")}</NavLink>
                        </NavItem>
                    </Nav>
                </div>

                <TabContent activeTab={activeTab}>
                    <TabPane tabId={1}>
                        <div>
                            {selectedProducts.length === 0 && <>
                                <div className="w-50 mx-auto vstack">
                                    <p>{t("paragraphs:InboundFormSelectFromInventoryDescription")}</p>
                                    <Button type="button" color="success" className="w-lg" onClick={toggleProductSelectModal}>
                                        {t("Select from Inventory")}
                                    </Button>
                                    <div className="label-separator my-4 label-seperator-2">
                                        <span className="label">{t("OR")}</span>
                                    </div>
                                    <QuickAddForm onProductsAdded={products => {
                                        setSelectedProducts(prev => _.uniqBy([...prev, ...products], "productId"));
                                    }} />
                                </div>
                            </>}
                            {selectedProducts.length > 0 && <>
                                <div className="d-flex justify-content-center align-items-center gap-2 mb-3">
                                    <div>
                                        {t("{{count}} Products Selected", { count: selectedProducts.length })}
                                    </div>
                                    <Button type="button" size="sm" color="primary" onClick={toggleProductSelectModal}>
                                        {t("Select more from inventory")}
                                    </Button>
                                    <div>
                                        {t("OR")}
                                    </div>
                                    <Button type="button" size="sm" color="info" onClick={toggleQuickAddModal}>
                                        {t("Quick Add")}
                                    </Button>
                                </div>
                                <div className="mb-3">
                                    <Table borderless striped className="table-centered align-middle mb-0">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th style={{ width: "5%"}}>{t("Image")}</th>
                                                <th style={{ width: "50%"}}>{t("Name")}</th>
                                                <th style={{ width: "10%"}}>
                                                    <ProductIdentifiersHeader />
                                                </th>
                                                <th style={{ width: "10%"}}>{t("Count")}</th>
                                                <th style={{ width: "15%"}}>{t("SKU")}</th>
                                                <th style={{ width: "20%"}}>{t("Category")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {validation.values.items?.map((item, i) => {
                                                const product = selectedProducts.find(p => p.productId === item.productId);

                                                if (!product) {
                                                    return undefined;
                                                }

                                                return <tr key={item.productId}>
                                                    <td>
                                                        <Button color="ghost-danger" size="sm" className="btn-icon" onClick={() => deleteItem(item.productId)}>
                                                            <i className="ri-delete-bin-line fs-16"></i>
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <ProductImageDisplay product={product} />
                                                    </td>
                                                    <td>
                                                        <TruncatableText maxLines={2}>
                                                            {product.name}
                                                        </TruncatableText>
                                                    </td>
                                                    <td>
                                                        <ProductIdentifiersDisplay product={product} />
                                                    </td>
                                                    <td>
                                                        <ValidationWrapper validation={validation} field={`items.${i}.quantity`}>
                                                            <StepInput min={1} />
                                                        </ValidationWrapper>
                                                    </td>
                                                    <td>
                                                        {product.sku}
                                                    </td>
                                                    <td>
                                                        <TruncatableText maxLines={2}>
                                                            {product.category?.name}
                                                        </TruncatableText>
                                                    </td>
                                                </tr>;
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <ValidatorButton type="button" color="success" onClick={() => toggleTab(2)} 
                                        validation={validation} fields={["items"]}>
                                        {t("Next")}
                                    </ValidatorButton>
                                </div>
                            </>}
                        </div>
                    </TabPane>
                    <TabPane tabId={2}>
                        <Row className="g-3">
                            <Col xs={12}>
                                <LinedHeader>{t("Select Warehouse")}</LinedHeader>
                                <ValidationWrapper validation={validation} field="warehouseId">
                                    <WarehouseGridSelect onSelect={setDestinationWarehouse} />
                                </ValidationWrapper>
                            </Col>
                            <Col sm={6}>
                                <LinedHeader>{t("Shipping Purpose")}</LinedHeader>
                                <ValidationWrapper validation={validation} field="shippingPurpose">
                                    <ShippingPurposeRadio direction="horizontal" />
                                </ValidationWrapper>
                            </Col>
                            <Col sm={6}>
                                <LinedHeader>{t("Supplier")}</LinedHeader>
                                <div className="vstack gap-2">
                                    <ValidationWrapper validation={validation} field="fromAmazon">
                                        <Radiobox label={t("From Amazon")} />
                                    </ValidationWrapper>
                                    <Radiobox label={t("Select Suppliers")} name="fromOtherSuppliers" value={!validation.values.fromAmazon} onChange={val => {
                                        validation.setFieldValue("fromAmazon", false);
                                    }} />
                                    {!validation.values.fromAmazon && <ValidationWrapper validation={validation} field="suppliers">
                                        <ProductSupplierSelect isMulti userId={props.inbound?.owner.userId} />
                                    </ValidationWrapper>}
                                </div>
                            </Col>
                            <Col xs={8}>
                                <LinedHeader>{t("Tracking Info")}</LinedHeader>
                                <Table bordered size="sm" className="table-centered table-nowrap mb-3 align-middle">
                                    <thead className="table-light">
                                        <tr>
                                            <th></th>
                                            <th>{t("Tracking Number")}</th>
                                            <th>{t("Carrier")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {validation.values.trackings?.map((tracking, i) => {
                                            return <tr key={i}>
                                                <td>
                                                    <Button color="ghost-danger" className="btn-label" onClick={() => {
                                                        const newTrackings = validation.values.trackings?.filter((_, index) => index !== i);
                                                        validation.setFieldValue("trackings", newTrackings);
                                                    }}>
                                                        <i className="ri-delete-bin-5-fill me-2 align-middle label-icon"></i>
                                                        {t("Remove")}
                                                    </Button>
                                                </td>
                                                <td>
                                                    <ValidationWrapper validation={validation} field={`trackings.${i}.trackingNumber`}>
                                                        <TextInput />
                                                    </ValidationWrapper>
                                                </td>
                                                <td>
                                                    <ValidationWrapper validation={validation} field={`trackings.${i}.carrierId`}>
                                                        <CarrierSelect onSelect={carrier => carrier && setSelectedCarriers(prev => _.uniqBy([...prev, carrier], "carrierId"))} />
                                                    </ValidationWrapper>
                                                </td>
                                            </tr>;
                                            })
                                        }
                                    </tbody>
                                </Table>
                                <div className="d-flex justify-content-center">
                                    <Button type="button" className="btn-ghost-info" onClick={addNewTrackingRow}>
                                        {t("Add Tracking ID")}
                                    </Button>
                                </div>
                            </Col>
                            <Col xs={4}>
                                <LinedHeader>{t("Estimated Arrival Date")}</LinedHeader>
                                <ValidationWrapper validation={validation} field="estimatedArrivalDate">
                                    <DateInput placeholder={t("Pick a date")} min={new Date()} />
                                </ValidationWrapper>
                            </Col>
                            <Col xs={12}>
                                <LinedHeader>{t("Notes")}</LinedHeader>
                                <ValidationWrapper validation={validation} field="notes">
                                    <TextInput type="textarea" />
                                </ValidationWrapper>
                            </Col>
                            <Col xs={12}>
                                <div className="d-flex justify-content-center gap-2">
                                    <Button type="button" color="warning" onClick={() => toggleTab(1)}>
                                        {t("Previous")}
                                    </Button>
                                    <ValidatorButton type="button" color="success" onClick={() => toggleTab(3)} 
                                        validation={validation} fields={["warehouseId", "shippingPurpose", "suppliers", "fromAmazon", "trackings", "estimatedArrivalDate", "notes"]}>
                                        {t("Next")}
                                    </ValidatorButton>
                                </div>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId={3}>
                        <Row>
                            <Col xs={6} className="gap-3">
                                <h3 className="text-center">{t("PRODUCTS")}</h3>
                                <p className="text-center">{t("paragraphs:InboundFormSummaryText", {
                                    totalQuantity: _.sumBy(validation.values.items, "quantity"),
                                    productCount: validation.values.items?.length
                                })}</p>
                                <Card body className="border">
                                    <Table borderless className="align-middle mb-0">
                                        <tbody>
                                            {validation.values.items?.map((item, i) => {
                                                const product = selectedProducts.find(p => p.productId === item.productId);
                                                if (!product) {
                                                    return undefined;
                                                }

                                                return <tr key={i}>
                                                    <td width="3rem">
                                                        <ProductImageDisplay product={product} />
                                                    </td>
                                                    <td>{product.name}</td>
                                                    <td>
                                                        <h5 className="mb-0">{item.quantity}</h5>
                                                    </td>
                                                </tr>;}
                                            )}
                                        </tbody>
                                    </Table>
                                </Card>
                            </Col>
                            <Col xs={6} className="gap-3">
                                <h3 className="text-center">{t("SHIPMENT DETAILS")}</h3>
                                <p>&nbsp;</p>
                                <Card body className="border">
                                    <Table borderless size="sm" className="mb-0">
                                        <tbody>
                                            <tr>
                                                <th>{t("Shipping To")}</th>
                                                <td>{destinationWarehouse?.name}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("Shipping Purpose")}</th>
                                                <td>{t(`enums:ShippingPurpose.${validation.values.shippingPurpose}`)}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("Tracking Info")}</th>
                                                <td>
                                                    <div className="vstack row-gap-1">
                                                        {validation.values.trackings?.map((tracking, i) => {
                                                            const carrier = selectedCarriers.find(c => c.carrierId === tracking.carrierId);
                                                            return <span>{tracking.trackingNumber} - {carrier?.name || "??"}</span>;
                                                        })}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t("Estimated Arrival Date")}</th>
                                                <td><DateDisplay date={validation.values.estimatedArrivalDate} /></td>
                                            </tr>
                                            <tr>
                                                <th>{t("Notes")}</th>
                                                <td>{validation.values.notes}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card>
                            </Col>
                            <Col xs={12}>
                                <div className="d-flex justify-content-center gap-2">
                                    <div className="w-50">
                                        <BusyOverlay busy={!validation.values.inboundCode} opaque spinnerColor="tenant-primary" backgroundColor="body-secondary">
                                            <div className="vstack gap-3">
                                                <UncontrolledAlert color="secondary" className="alert-additional mb-xl-0" closeClassName="d-none">
                                                    <div className="alert-body">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 me-3">
                                                                <i className="ri-alert-line fs-16 align-middle"></i>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h5 className="alert-heading mb-0">{t("Important")}!</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="alert-content">
                                                        <p className="mb-0">{t("paragraphs:InboundFormShippingAddressAlert")}</p>
                                                    </div>
                                                </UncontrolledAlert>
                                                <Card body className="user-select-all border">
                                                    {destinationWarehouse && (props.inbound?.customer || customer) && validation.values.inboundCode && <AddressDisplay 
                                                        address={destinationWarehouse.address} 
                                                        inboundCode={validation.values.inboundCode} 
                                                        warehouseName={destinationWarehouse.name} 
                                                        suitNumber={(props.inbound?.customer ?? customer)!.suitNumber} />}
                                                </Card>
                                            </div>
                                        </BusyOverlay>
                                        <div className="hstack justify-content-center gap-2">
                                            <Button type="button" color="warning" onClick={() => toggleTab(2)}>
                                                {t("Back")}
                                            </Button>
                                            <BusyOverlay busy={saveInboundMutation.isPending} size="sm">
                                                <ValidatorButton color="secondary" className="w-lg" validation={validation} onClick={() => submitForm(true)}>
                                                    {t("Save as Draft")}
                                                </ValidatorButton>
                                            </BusyOverlay>
                                            <BusyOverlay busy={saveInboundMutation.isPending} size="sm">
                                                <ValidatorButton color="success" className="w-lg" validation={validation} onClick={() => submitForm(false)}>
                                                    {t("Confirm")}
                                                </ValidatorButton>
                                            </BusyOverlay>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </TabPane>
                    {/* <TabPane tabId={4}>
                        <div className="text-center">
                            <div className="avatar-md mt-5 mb-4 mx-auto">
                                <div className="avatar-title bg-light text-success display-4 rounded-circle">
                                    <i className="ri-checkbox-circle-fill"></i>
                                </div>
                            </div>
                            <h5>{t("Well done")}!</h5>
                            <p className="text-muted">{t("You have successfully created the company")}</p>
                            <Button type="button" color="warning" onClick={() => props.onSuccess?.()}>{t("Close wizard")}</Button>
                        </div>
                    </TabPane> */}
                </TabContent>
            </Form>
        </Card>
        <Modal backdrop="static" isOpen={productSelectModal} toggle={toggleProductSelectModal} size="xl">
            <ModalBody>
                <ModalCloseButton onClick={toggleProductSelectModal} />
                <ProductPickList disabledProducts={selectedProducts} onSelectComplete={selected => {
                    toggleProductSelectModal();
                    
                    if (selected.length === 0) {
                        return;
                    }

                    setSelectedProducts(prev => _.uniqBy([...prev, ...selected], "productId"));
                }} />
            </ModalBody>
        </Modal>
        <Modal backdrop="static" isOpen={quickAddModal} toggle={toggleQuickAddModal}>
            <ModalBody>
                <ModalCloseButton onClick={toggleQuickAddModal} />
                <QuickAddForm onProductsAdded={products => {
                    setSelectedProducts(prev => _.uniqBy([...prev, ...products], "productId"));
                    toggleQuickAddModal();
                }} />
            </ModalBody>
        </Modal>
    </>;
};

export default EditForm;
