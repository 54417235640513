import BusyOverlay from "Components/Common/BusyOverlay";
import RequiredLabel from "Components/Common/RequiredLabel";
import Restricted from "Components/Common/Restricted";
import Checkbox from "Components/Form/Checkbox";
import ColorPicker from "Components/Form/ColorPicker";
import ValidatedInput from "Components/Form/Validated/ValidatedInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { ProductColorContract, ProductColorForm } from "api/types/contracts/products";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Col, Container, Form, Row } from "reactstrap";
import { createProductColor, updateProductColor } from "slices/product/color/thunk";
import * as Yup from "yup";

type FormProps = {
    productColor?: ProductColorContract,
    onSuccess: (productColorId: string) => void
}

const defaultForm: Partial<ProductColorForm> = {
    isStandart: false
};

const createProductColorForm = (productColor: ProductColorContract): ProductColorForm => {
    return {
        name: productColor.name,
        isStandart: productColor.isStandart,
        hexCode: productColor.hexCode
    };
};

const ColorForm = (props: FormProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const product = props.productColor ? createProductColorForm(props.productColor) : defaultForm;

    const { loading, error } = useAppSelector(
        (state) => ({
            error: state.ProductColor.error,
            loading: state.ProductColor.loading
        })
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: product,
        validationSchema: Yup.object<ProductColorForm, TypedShape<ProductColorForm>>({
            name: Yup.string().required(t("Name is required")),
            isStandart: Yup.boolean().required(),
            hexCode: Yup.string().required(t("You must pick a color"))
        }),
        onSubmit: async (values) => {
            const productColor = values as ProductColorForm;
            const productColorId = props.productColor?.colorId;
            
            if (productColorId) {
                const success = await dispatch(updateProductColor({
                    productColorId: productColorId,
                    productColor
                }));

                if (success) {
                    props.onSuccess(productColorId);
                }
            }
            else {
                const created = await dispatch(createProductColor({
                    productColor
                }));

                if (created) {
                    props.onSuccess(created.colorId);
                }
            }
        },
    });

    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <Container>
                <Row className="g-3">
                    <Col>
                        <RequiredLabel>{t("Color Name")}</RequiredLabel>
                        <ValidatedInput validation={validation} field="name" />
                    </Col>
                    <Restricted require="management.system" create={!props.productColor} write={!!props.productColor}>
                        <Col xs="auto" className="d-flex align-items-end pb-2">
                            <ValidationWrapper validation={validation} field="isStandart">
                                <Checkbox>{t("Is Standart")}</Checkbox>
                            </ValidationWrapper>
                        </Col>
                    </Restricted>
                    <Col xs={12}>
                        <RequiredLabel>{t("Color (HEX)")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="hexCode">
                            <ColorPicker type="chrome" placeholder="" />
                        </ValidationWrapper>
                    </Col>
                    <Col xs="auto" className="ms-auto">
                        <BusyOverlay busy={loading.save} size="sm">
                            <ValidatorButton validation={validation} color="primary" type="submit">
                                {t("Save")}
                            </ValidatorButton>
                        </BusyOverlay>
                    </Col>
                </Row>
            </Container>
        </Form>
    </>;
}

export default ColorForm;
