import { useProfile } from "Components/Hooks/ProfileHooks";
import { UnitOfLength } from "api/types/contracts/common";
import { convertLength } from "helpers/unitConversions";
import _ from "lodash";

type DimensionsProps = {
    value: {
        width: number | undefined,
        height: number | undefined,
        length: number | undefined,
    },
    unit: UnitOfLength | undefined,
    showAlternative?: boolean,
    alternativeUnit?: UnitOfLength
}

const Dimensions = ({ value, unit, showAlternative, alternativeUnit }: DimensionsProps) => {
    const { userProfile } = useProfile();
    const displayUnit = unit ?? userProfile?.user.warehouse?.settings?.unitOfLength ?? "inch";
    const alternativeDisplayUnit = alternativeUnit ?? displayUnit === "inch" ? "cm" : "inch";

    if (value.width || value.height || value.length) {

        const width = value.width ? _.round(value.width, 2).toString() : "-";
        const height = value.height ? _.round(value.height, 2).toString() : "-";
        const length = value.length ? _.round(value.length, 2).toString() : "-";

        return <>
            <span className="text-nowrap">
                <span>{width} x {height} x {length}</span>
                <span> {displayUnit}</span>
            </span>
            {showAlternative && <span className="text-nowrap d-block text-muted small">
                <span>
                    {value.width && _.round(convertLength(value.width, displayUnit, alternativeDisplayUnit), 2)}
                    {" x "}
                    {value.height && _.round(convertLength(value.height, displayUnit, alternativeDisplayUnit), 2)}
                    {" x "}
                    {value.length && _.round(convertLength(value.length, displayUnit, alternativeDisplayUnit), 2)}
                </span>
                <span> {alternativeDisplayUnit}</span>
            </span>}
        </>;
    }
    else {
        return <span>-</span>;
    }
}

export default Dimensions;