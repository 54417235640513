import { InfoAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import Loader from "Components/Common/Loader";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import TruncatableText from "Components/Common/TruncatableText";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import Checkbox from "Components/Form/Checkbox";
import NumberInput from "Components/Form/NumberInput";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { createAppSelector, useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import ProductEditForm from "Components/Shared/ProductEditForm";
import ShipmentPackageView, { PackageItemColors } from "Components/Shared/ShipmentPackageView";
import { UnitOfLength, UnitOfWeight } from "api/types/contracts/common";
import { ProductContract } from "api/types/contracts/products";
import { FbaShipmentBoxContract, FbaShipmentBoxItemContract, FbaShipmentPackageFormItem } from "api/types/contracts/shipping";
import { useFormik } from "formik";
import { desi } from "helpers/optimalBox";
import { TypedShape } from "helpers/types";
import { convertWeight } from "helpers/unitConversions";
import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, ButtonGroup, Card, CardBody, CardHeader, CardTitle, Form, Modal, ModalBody, Table } from "reactstrap";
import { updateFbaShipment, updateFbaShipmentPackages } from "slices/shipping/fbaShipping/thunk";
import * as Yup from "yup";

type EditPackagesProps = {
    
};

type Dimensions = { 
    width?: number, 
    height?: number, 
    length?: number
}

const EditPackages = (props: EditPackagesProps) => {
    const dispatch = useAppDispatch();
    const { fbaShipment, loading } = useAppSelector(
        createAppSelector([state => state.FbaShipping],
            (fbaShipping) => ({
                fbaShipment: fbaShipping.processingItem,
                loading: fbaShipping.loading
            })
        )
    );
    
    const [newDimension, setNewDimension] = useState<Dimensions>({});
    const [viewingProduct, setViewingProduct] = useState<ProductContract>();
    const { t } = useTranslation();
    const [boxDimensions, setBoxDimensions] = useState<Dimensions[]>(_.chain(fbaShipment?.packages ?? []).map(p => ({ width: p.box.width, height: p.box.height, length: p.box.length })).uniqWith(_.isEqual).value());
    const [editPackagesModal, setEditPackagesModal] = useState(false);
    const [estimatedBoxesModal, setEstimatedBoxesModal] = useState(false);
    const removeAllPackagesConfirmDialogRef = useRef<DialogRef>(null);
    const completePackagesConfirmDialogRef = useRef<DialogRef>(null);

    const toggleEditPackagesModal = () => setEditPackagesModal(prev => !prev);
    const toggleEstimatedBoxesModal = () => setEstimatedBoxesModal(prev => !prev);

    const productColorCodes = useMemo(() => _.chain(fbaShipment?.items ?? []).map((item, i) => [item.product.productId, PackageItemColors[i % PackageItemColors.length]]).uniqWith(_.isEqual).fromPairs().value(), [fbaShipment?.items]);
    const isNewDimensionValid = useMemo(() => !boxDimensions.find(d => _.isEqual(d, newDimension)), [boxDimensions, newDimension]);

    const packagesValidation = useFormik({
        initialValues: fbaShipment?.packages.map<FbaShipmentPackageFormItem>(p => ({
            box: p.box
        })) ?? [],
        validationSchema: Yup.array<FbaShipmentPackageFormItem>(Yup.object<FbaShipmentPackageFormItem, TypedShape<FbaShipmentPackageFormItem>>({
            box: Yup.object<FbaShipmentBoxContract, TypedShape<FbaShipmentBoxContract>>({
                boxNumber: Yup.number().required(t("Box number is required")),
                width: Yup.number().required(t("Width is required")).min(0.01, t("Width must be greater than 0")),
                height: Yup.number().required(t("Height is required")).min(0.01, t("Height must be greater than 0")),
                length: Yup.number().required(t("Length is required")).min(0.01, t("Length must be greater than 0")),
                weight: Yup.number().required(t("Weight is required")).min(0.01, t("Weight must be greater than 0")),
                desi: Yup.number().required(t("Desi is required")),
                lengthUnit: Yup.string<UnitOfLength>().required(t("Length unit is required")),
                weightUnit: Yup.string<UnitOfWeight>().required(t("Weight unit is required")),
                products: Yup.array<FbaShipmentBoxItemContract>(Yup.object<FbaShipmentBoxItemContract, TypedShape<FbaShipmentBoxItemContract>>({
                    productId: Yup.string().required(),
                    asin: Yup.string().required(),
                    count: Yup.number().required()
                })).required()
            }).required()
        })).required().min(1, t("At least one package is required")),
        onSubmit: async values => {
            await dispatch(updateFbaShipmentPackages({
                fbaShipmentId: fbaShipment!.fbaShipmentId,
                packages: values
            }));

            setEditPackagesModal(false);
        }
    });

    const addPackage = () => {
        const newItem: FbaShipmentPackageFormItem = {
            box: {
                boxNumber: packagesValidation.values.length + 1,
                weight: 0,
                width: 0,
                height: 0,
                length: 0,
                desi: 0,
                lengthUnit: fbaShipment!.warehouse.settings.unitOfLength,
                weightUnit: fbaShipment!.warehouse.settings.unitOfWeight,
                products: []
            }
        };

        packagesValidation.setValues([...packagesValidation.values, newItem]);
    };

    const removePackage = (index: number) => {
        packagesValidation.setValues(prev => prev.filter((_, i) => i !== index));
    }

    const addBoxDimensions = () => {
        if (!isNewDimensionValid) {
            return;
        }

        setBoxDimensions([...boxDimensions, newDimension]);
        setNewDimension({});
    };

    const removeBoxDimensions = (index: number) => {
        setBoxDimensions(prev => prev.filter((_, i) => i !== index));
    };

    const setDimensionProperty = (index: number, property: keyof Dimensions, value: number | undefined) => {
        setBoxDimensions(prev => {
            const newDimensions = [...prev];
            newDimensions[index][property] = value;
            return newDimensions;
        });
    }

    useEffect(() => {
        packagesValidation.setValues(fbaShipment?.packages.map(p => ({
            box: p.box
        })) ?? []);
    }, [fbaShipment])
    
    if (!fbaShipment) {
        return <Loader height="250px" />;
    }

    const updateStatusToBoxLabelPending = async () => {
        await packagesValidation.submitForm();
        if (packagesValidation.isValid) {
            await dispatch(updateFbaShipment({
                fbaShipmentId: fbaShipment.fbaShipmentId, 
                status: "boxLabelPending"
            }));
        }
    };

    return <>
        <Card>
            <CardHeader>
                <CardTitle tag="h5" className="mb-0">{t("Packages")}</CardTitle>
            </CardHeader>
            <CardBody>
                <div className="d-flex justify-content-between mb-3">
                    <div className="w-25 border rounded py-2 px-3 d-flex justify-content-between">
                        <span>{t("Estimated Packages")}</span>
                        <Link to="#" onClick={toggleEstimatedBoxesModal}>{t("{{count}} Package", { count: fbaShipment.estimatedBoxes.length })}</Link>
                    </div>
                    {fbaShipment.status === "processing" && <div>
                        <Button color="info" onClick={toggleEditPackagesModal}>{t(packagesValidation.values.length === 0 ? "Add Package Information" : "Edit Package Information")}</Button>
                    </div>}
                </div>
                {packagesValidation.values.length > 0 ? <>
                    <ShipmentPackageView 
                        colorCodes={productColorCodes} 
                        packedBoxes={packagesValidation.values} />
                </> : <>
                    <InfoAlert>{t("Add packages to view here")}</InfoAlert>
                </>}
            </CardBody>
        </Card>
        {fbaShipment.status === "processing" && <div className="d-flex justify-content-center gap-2 mb-2">
            <Button color="danger" onClick={() => removeAllPackagesConfirmDialogRef.current?.show()}>{t("Remove All Packages")}</Button>
            <BusyOverlay size="sm" busy={loading.save}>
                <ValidatorButton type="submit" validation={packagesValidation} color="success" onClick={() => completePackagesConfirmDialogRef.current?.show()}>{t("Complete Packaging")}</ValidatorButton>
            </BusyOverlay>
        </div>}
        <Modal backdrop="static" isOpen={editPackagesModal} toggle={toggleEditPackagesModal} size="xl">
            <ModalBody>
                <ModalCloseButton onClick={toggleEditPackagesModal} />
                <Form onSubmit={packagesValidation.handleSubmit}>
                    <Table className="align-middle" size="sm" borderless>
                        <thead>
                            <tr className="align-middle">
                                <th>{t("Image")}</th>
                                <th>{t("Name")}</th>
                                <th className="text-nowrap">{t("Units boxed")}</th>
                                {packagesValidation.values.map((p, i) => {
                                    return <th key={i} className="text-center text-nowrap" style={{ width: "5%" }}>{t("Box {{number}}", { number: p.box.boxNumber })}</th>;
                                })}
                                <th>
                                    {packagesValidation.values.length === 0 ? <>
                                        <Button color="info" size="sm" className="text-nowrap" onClick={addPackage}>
                                            {t("Add Box")}
                                        </Button>
                                    </> : <ButtonGroup>
                                        <Button color="soft-info" size="sm" className="btn-icon" onClick={addPackage}>
                                            <i className="ri-add-line fs-16"></i>
                                        </Button>
                                        <Button color="soft-danger" size="sm" className="btn-icon" onClick={() => removePackage(packagesValidation.values.length - 1)}>
                                            <i className="ri-subtract-line fs-16"></i>
                                        </Button>
                                    </ButtonGroup>}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {fbaShipment.items.map((item, i) => <tr key={i}>
                                <td>
                                    <ProductImageDisplay product={item.product} />
                                </td>
                                <td>
                                    <div>
                                        <Link to="#" className="link-info fw-semibold" onClick={() => setViewingProduct(item.product)}>
                                            <TruncatableText maxLines={2}>
                                                {item.product.name}
                                            </TruncatableText>
                                        </Link>
                                    </div>
                                    <div className="hstack gap-3">
                                        <Link to={`/product/edit/${item.product.productId}`} target="_blank">{item.product.asin}</Link>
                                        <span>SKU: {item.product.sku}</span>
                                    </div>
                                </td>
                                <td className="text-center">{t("{{boxedCount}} of {{totalCount}}", {
                                    boxedCount: _.sumBy(packagesValidation.values.flatMap(b => b.box.products).filter(bp => bp.productId === item.product.productId), p => p.count),
                                    totalCount: item.count
                                })}</td>
                                {packagesValidation.values.map((b, j) => {
                                    const boxProduct = b.box.products.find(bp => bp.productId === item.product.productId);
                                    return <td key={j}>
                                        <NumberInput size="sm" className="text-center" value={boxProduct?.count ?? 0} onChange={val => {
                                            if (val) {
                                                if (boxProduct) {
                                                    packagesValidation.setFieldValue(`${j}.box.products`, 
                                                        packagesValidation.values[j].box.products.map(bp => {
                                                            if (bp.productId === item.product.productId) {
                                                                return { ...bp, count: val };
                                                            }
                                                            return bp;
                                                        })
                                                    );
                                                }
                                                else {
                                                    packagesValidation.setFieldValue(`${j}.box.products`, 
                                                        [...packagesValidation.values[j].box.products, {
                                                            productId: item.product.productId,
                                                            asin: item.product.asin ?? "???",
                                                            count: val
                                                        }]
                                                    );
                                                }
                                            }
                                            else {
                                                if (boxProduct) {
                                                    packagesValidation.setFieldValue(`${j}.box.products`, 
                                                        packagesValidation.values[j].box.products.filter(bp => bp.productId !== item.product.productId)
                                                    );
                                                }
                                            }
                                            const weight = convertWeight(item.product.weight, item.product.options.unitOfWeight, fbaShipment.warehouse.settings.unitOfWeight);
                                            packagesValidation.setFieldValue(`${j}.box.weight`, _.round(_.sumBy(packagesValidation.values[j].box.products, p => p.count * weight), 2));
                                            packagesValidation.setFieldValue(`${j}.box.desi`, desi(packagesValidation.values[j].box));
                                        }} />
                                    </td>;
                                })}
                                <td></td>
                            </tr>)}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={3}>
                                    <div className="d-flex justify-content-between">
                                        <span>{t("Total SKUs: {{count}}", { count: fbaShipment.items.length })}</span>
                                        <strong>{t("Total Units: {{count}}", { count: _.sumBy(fbaShipment.items, i => i.count) })}</strong>
                                    </div>
                                </td>
                                {packagesValidation.values.map((p, i) => <th key={i}></th>)}
                                <th>
                                    
                                </th>
                            </tr>
                            <tr>
                                <td colSpan={3} className="text-end">
                                    {t("Box Weight ({{unit}})", { unit: fbaShipment.warehouse.settings.unitOfWeight })}
                                </td>
                                {packagesValidation.values.map((b, j) => <td key={j}>
                                    <NumberInput size="sm" className="text-center" value={b.box.weight} onChange={val => {
                                        if (val) {
                                            packagesValidation.setFieldValue(`${j}.box.weight`, _.round(_.sumBy(packagesValidation.values[j].box.products, p => p.count * val), 2));
                                            packagesValidation.setFieldValue(`${j}.box.desi`, desi(packagesValidation.values[j].box));
                                        }
                                    }} />
                                </td>)}
                                <td></td>
                            </tr>
                            <tr>
                                <td colSpan={3} className="text-end">
                                    {t("Box Dimensions ({{unit}})", { unit: fbaShipment.warehouse.settings.unitOfLength })}
                                </td>
                                <td colSpan={packagesValidation.values.length + 1}></td>
                            </tr>
                            {boxDimensions.map((dimension, i) => <tr key={i}>
                                <td colSpan={3}>
                                    <div className="justify-content-end gap-2 hstack">
                                        <NumberInput size="sm" className="text-center" value={dimension.width} style={{ width: "3rem" }}
                                            onChange={val => setDimensionProperty(i, "width", val)} />
                                        <span>x</span>
                                        <NumberInput size="sm" className="text-center" value={dimension.height} style={{ width: "3rem" }}
                                            onChange={val => setDimensionProperty(i, "height", val)} />
                                        <span>x</span>
                                        <NumberInput size="sm" className="text-center" value={dimension.length} style={{ width: "3rem" }}
                                            onChange={val => setDimensionProperty(i, "length", val)} />
                                    </div>
                                </td>
                                {packagesValidation.values.map((p, j) => <td key={j} >
                                    <div className="d-flex justify-content-center">
                                        <Checkbox 
                                            value={p.box.width === dimension.width && p.box.height === dimension.height && p.box.length === dimension.length} 
                                            onChange={checked => {
                                                const box = { ...packagesValidation.values[j].box };

                                                if (checked) {
                                                    box.width = dimension.width ?? 0;
                                                    box.height = dimension.height ?? 0;
                                                    box.length = dimension.length ?? 0;
                                                }
                                                else {
                                                    box.width = 0;
                                                    box.height = 0;
                                                    box.length = 0;
                                                }

                                                box.desi = desi(box);

                                                packagesValidation.setFieldValue(`${j}.box`, box);
                                        }} />
                                    </div>
                                </td>)}
                                <td>
                                    <Button color="ghost-danger" size="sm" className="btn-icon" 
                                        disabled={!!packagesValidation.values.find(p => p.box.width === dimension.width && p.box.height === dimension.height && p.box.length === dimension.length)}
                                        onClick={() => removeBoxDimensions(i)}>
                                        <i className="ri-delete-bin-line fs-16"></i>
                                    </Button>
                                </td>
                            </tr>)}
                            <tr>
                                <td colSpan={3}>
                                    <div className="justify-content-end gap-2 hstack">
                                        {!isNewDimensionValid && <>
                                            <span className="text-danger small">{t("This box dimension already exists")}</span>
                                        </>}
                                        <Button color="soft-secondary" size="sm" className="flex-shrink-0" disabled={!isNewDimensionValid} onClick={addBoxDimensions}>
                                            {t("Add another box dimension")}
                                        </Button>
                                        <NumberInput size="sm" className="text-center" value={newDimension.width} style={{ width: "3rem" }}
                                            onChange={val => setNewDimension({
                                                ...newDimension,
                                                width: val
                                            })} />
                                        <span>x</span>
                                        <NumberInput size="sm" className="text-center" value={newDimension.height} style={{ width: "3rem" }}
                                            onChange={val => setNewDimension({
                                                ...newDimension,
                                                height: val
                                            })} />
                                        <span>x</span>
                                        <NumberInput size="sm" className="text-center" value={newDimension.length} style={{ width: "3rem" }}
                                            onChange={val => setNewDimension({
                                                ...newDimension,
                                                length: val
                                            })} />
                                    </div>
                                </td>
                                <td colSpan={packagesValidation.values.length + 1}>
                                    
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                    <div className="d-flex justify-content-end">
                        <BusyOverlay busy={loading.save} size="sm">
                            <ValidatorButton color="success" type="submit" validation={packagesValidation}>
                                {t("Confirm Packing Information")}
                            </ValidatorButton>
                        </BusyOverlay>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
        <Modal backdrop="static" isOpen={estimatedBoxesModal} toggle={toggleEstimatedBoxesModal} size="xl">
            <ModalBody>
                <ModalCloseButton onClick={toggleEstimatedBoxesModal} />
                <ShipmentPackageView colorCodes={productColorCodes} packedBoxes={fbaShipment.estimatedBoxes} />
            </ModalBody>
        </Modal>
        <Modal backdrop="static" isOpen={!!viewingProduct} size="xl" unmountOnClose>
            <ModalCloseButton onClick={() => setViewingProduct(undefined)} />
            <ModalBody className="p-0">
                <ProductEditForm product={viewingProduct} onSuccess={() => setViewingProduct(undefined)} />
            </ModalBody>
        </Modal>
        <Dialog ref={removeAllPackagesConfirmDialogRef} color="danger" buttons={["yes", "no"]} busy={loading.save || loading.load} iconClass="ri-delete-bin-line"  
            message={`Do you want to continue?`} title={`Removing all packaging information`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    packagesValidation.setValues([]);
                    dispatch(updateFbaShipmentPackages({
                        fbaShipmentId: fbaShipment.fbaShipmentId,
                        packages: []
                    })).then(() => hide());
                }
                else {
                    hide();
                }
            }} />
        <Dialog ref={completePackagesConfirmDialogRef} color="info" buttons={["yes", "no"]} busy={loading.save || loading.load} iconClass="ri-check-line"  
            message={`Do you want to continue?`} title={`Completing the packaging process`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    updateStatusToBoxLabelPending().then(() => hide());
                }
                else {
                    hide();
                }
            }} />
    </>;
}
export default EditPackages;