import BusyOverlay from "Components/Common/BusyOverlay";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import TruncatableText from "Components/Common/TruncatableText";
import ProductIdentifiersDisplay, { ProductIdentifiersHeader } from "Components/Displays/ProductIdentifiersDisplay";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import ProductPickList from "Components/EntitySelects/ProductPickList";
import DateInput from "Components/Form/DateInput";
import NumberInput from "Components/Form/NumberInput";
import StepInput from "Components/Form/StepInput";
import TextInput from "Components/Form/TextInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { createAppSelector, useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { ProductContract, StockForm } from "api/types/contracts/products";
import { useFormik } from "formik";
import { dotDotDot } from "helpers/string";
import { TypedShape } from "helpers/types";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Button, Card, Form, InputGroup, Label, Modal, ModalBody, PopoverBody, Table, UncontrolledPopover } from "reactstrap";
import { loadReceivingEntry, saveStock } from "slices/receivings/thunk";
import { loadUnusableProductReasonList } from "slices/receivings/unusableProductReason/thunk";
import * as Yup from "yup";

const ProcessFBA = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { userProfile } = useProfile();
    
    const { processedEntry, selectedBox, unusableProductReasons, loading } = useAppSelector(
        createAppSelector([state => state.Receiving, state => state.UnusableProductReason],
            (receiving, unusableProductReason) => ({
                processedEntry: receiving.processedEntry!,
                selectedBox: receiving.selectedBox,
                unusableProductReasons: unusableProductReason.list,
                loading: receiving.loading
            })
        )
    );
    
    const [productSelectModal, setProductSelectModal] = useState(false);
    const [products, setProducts] = useState<ProductContract[]>(processedEntry.inbound?.items.map(i => i.product) || []);

    useEffect(() => {
        if (processedEntry.inbound) {
            setProducts(processedEntry.inbound.items.map(i => i.product));
        }
        else if (selectedBox) {
            setProducts(selectedBox.box.contents.map(c => c.product));
        }
    }, [processedEntry.inbound, selectedBox])
    useEffect(() => {
        dispatch(loadUnusableProductReasonList({
            page: 1, 
            pageSize: 100
        }));
    }, [dispatch]);

    const initial = useMemo(() => selectedBox?.box.contents.map<StockForm>(c => ({
        expireDate: c.expireDate,
        productId: c.product.productId,
        sellableStocks: c.sellableQuantity,
        warehouseId: userProfile?.user.warehouse?.warehouseId ?? "",
        unusableStocks: _.fromPairs(c.unusables.map(u => [u.unusableProductReason.unusableProductReasonId, u.quantity]))
    })) ?? [], [selectedBox?.box.contents]);

    const stocksValidation = useFormik({
        initialValues: initial,
        validationSchema: Yup.array<StockForm>(Yup.object<StockForm, TypedShape<StockForm>>({
            expireDate: Yup.date().nullable(),
            productId: Yup.string().required(),
            warehouseId: Yup.string().required(),
            sellableStocks: Yup.number().required(),
            unusableStocks: Yup.object(_.fromPairs(unusableProductReasons.items?.map(r => [r.unusableProductReasonId, Yup.number().notRequired()])))
        })),
        onSubmit: async values => {
            await dispatch(saveStock({
                receivingBoxId: selectedBox!.receivingBoxId,
                receivingEntryId: processedEntry.receivingEntryId,
                stocks: values
            }));

            await dispatch(loadReceivingEntry({
                receivingEntryId: processedEntry.receivingEntryId
            }));
        }
    });
        
    useEffect(() => {
        const newItems = products.map<StockForm>(s => ({
            productId: s.productId,
            sellableStocks: 0,
            warehouseId: userProfile?.user.warehouse?.warehouseId ?? "",
            unusableStocks: _.fromPairs(unusableProductReasons.items?.map(r => [r.unusableProductReasonId, 0])),
            expireDate: null
        }));
        
        const items = stocksValidation.values && stocksValidation.values.length > 0 ? 
            _.uniqBy([...stocksValidation.values, ...newItems], "productId") 
        : newItems;

        stocksValidation.setValues(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products]);

    return <div className="mb-4">
        <div className="hstack justify-content-between mb-2">
            <div className="hstack gap-2 align-items-center">
                <Label className="flex-shrink-0 mb-0">{t("Select Product")}</Label>
                {<TextInput placeholder={"Enter Product Barcode"} /* value={productBarcode} onChange={setProductBarcode}*/ /> }
            </div>
            <div>
                <Button type="button" color="secondary" onClick={() => setProductSelectModal(true)}>{t("Select From Inventory")}</Button>
            </div>
        </div>
        <Form onSubmit={stocksValidation.handleSubmit}>
            <Card body>
                <div className="table-card table-responsive">
                    <Table borderless striped className="mb-0 align-middle table-nowrap">
                        <thead>
                            <tr>
                                <th>{t("Image")}</th>
                                <th style={{ width: "30%" }}>{t("Name")}</th>
                                <th>
                                    <ProductIdentifiersHeader />
                                </th>
                                <th>{t("Total Sellable")}</th>
                                <th>{t("Total Unusable")}</th>
                                <th>{t("Total Received / Expected")}</th>
                                <th>{t("Expire Date")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stocksValidation.values.map((s, index) => {
                                const product = products.find(p => s.productId === p.productId);
                                const inboundItem = processedEntry.inbound?.items.find(i => i.product.productId === s.productId);

                                if (!product) {
                                    return <tr>
                                        <td colSpan={7} className="text-center">
                                            N/A
                                        </td>
                                    </tr>;
                                }

                                return <tr key={index}>
                                    <td>
                                        <ProductImageDisplay product={product} />
                                    </td>
                                    <td>
                                        <TruncatableText maxLines={2}>
                                            {product.name}
                                        </TruncatableText>
                                    </td>
                                    <td>
                                        <ProductIdentifiersDisplay product={product} />
                                    </td>
                                    <td>
                                        <ValidationWrapper validation={stocksValidation} field={`${index}.sellableStocks`}>
                                            <StepInput min={0} style={{ width: "6rem" }} />
                                        </ValidationWrapper>
                                    </td>
                                    <td>
                                        <InputGroup>
                                            <NumberInput className="text-center" value={_.sum(Object.values(s.unusableStocks))} readOnly style={{ width: "4rem" }} />
                                            <Button type="button" color="soft-primary" size="sm" id={`reasons-btn-${product.productId}`}>
                                                <i className="ri-arrow-down-s-line fs-16"></i>
                                            </Button>
                                        </InputGroup>
                                        <UncontrolledPopover trigger="legacy" placement="bottom" target={`reasons-btn-${product.productId}`}>
                                            <PopoverBody>
                                                <Table borderless className="table-nowrap align-middle mb-0" size="sm">
                                                    <tbody>
                                                        {unusableProductReasons.items?.map((reason) => {
                                                            return <tr key={reason.unusableProductReasonId}>
                                                                <th>{t(reason.name)}</th>
                                                                <td>
                                                                    <ValidationWrapper validation={stocksValidation} field={`${index}.unusableStocks.${reason.unusableProductReasonId}`}>
                                                                        <StepInput min={0} size="sm" />
                                                                    </ValidationWrapper>
                                                                </td>
                                                            </tr>;
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </td>
                                    <td>
                                        {inboundItem && <>{inboundItem.received}/{inboundItem.quantity}</>}
                                    </td>
                                    <td>
                                        <ValidationWrapper validation={stocksValidation} field={`${index}.expireDate`}>
                                            <DateInput />
                                        </ValidationWrapper>
                                    </td>
                                </tr>;
                            })}
                        </tbody>
                    </Table>
                </div>
            </Card>
            <div className="d-flex justify-content-end gap-2">
                <BusyOverlay busy={loading.saveStock} size="sm">
                    <ValidatorButton validation={stocksValidation} type="submit" color="success" disabled={!selectedBox}>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
        <Modal backdrop="static" isOpen={productSelectModal} toggle={() => setProductSelectModal(prev => !prev)} size="xl">
            <ModalBody>
                <ModalCloseButton onClick={() => setProductSelectModal(false)} />
                <ProductPickList customerId={processedEntry.customer.customerId} onSelectComplete={products => {
                    setProductSelectModal(false);
                    
                    if (products.length === 0) {
                        return;
                    }
                    
                    setProducts(prev => _.uniqBy([...prev, ...products], p => p.productId))
                }} />
            </ModalBody>
        </Modal>
    </div>;
}

export default ProcessFBA;