import { InfoAlert } from "Components/Alerts";
import Loader from "Components/Common/Loader";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { createAppSelector, useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import ShipmentCostBreakdownView from "Components/Shared/ShipmentCostBreakdownView";
import { useTranslation } from "react-i18next";
import { updateFbaShipment } from "slices/shipping/fbaShipping/thunk";

const CostBreakdown = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { canWrite, userProfile } = useProfile();
    const { fbaShipment } = useAppSelector(
        createAppSelector([state => state.FbaShipping],
            (fbaShipping) => ({
                fbaShipment: fbaShipping.processingItem,
            })
        )
    );
    

    if (!fbaShipment) {
        return <Loader height="250px" />;
    }

    return <>
        <ShipmentCostBreakdownView value={fbaShipment.actualCost ?? fbaShipment.estimatedCost} 
            currency={fbaShipment.warehouse.settings.unitOfCurrency}
            warehouseId={fbaShipment.warehouse.warehouseId} 
            canAdd={canWrite("shipping.fbashipping")} 
            canEdit={canWrite("shipping.fbashipping")}
            onChange={async cost => {
                await dispatch(updateFbaShipment({
                    fbaShipmentId: fbaShipment.fbaShipmentId,
                    actualCost: cost
                }));
            }}
            onPrepServiceAdded={async prepServiceId => {
                await dispatch(updateFbaShipment({
                    fbaShipmentId: fbaShipment.fbaShipmentId,
                    prepServices: [...fbaShipment.prepServices.map(p => p.prepService.prepServiceId), prepServiceId]
                }));
            }}
            onPrepServiceRemoved={async prepServiceId => {
                await dispatch(updateFbaShipment({
                    fbaShipmentId: fbaShipment.fbaShipmentId,
                    prepServices: fbaShipment.prepServices.filter(p => p.prepService.prepServiceId !== prepServiceId).map(p => p.prepService.prepServiceId)
                }));
            }}
            />
        {fbaShipment.owner.userId === userProfile?.user.userId && <div className="w-75 mx-auto">
            <InfoAlert>{t("paragraphs:FbaShipmentInvoiceDescription")}</InfoAlert>
        </div>}
    </>;

}

export default CostBreakdown;