import { useMutation, useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { InfoAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import Restricted from "Components/Common/Restricted";
import TableContainer, { TableContainerRef, selectRowColumn } from "Components/Common/TableContainer";
import InlineAddressDisplay from "Components/Displays/InlineAddressDisplay";
import WarehouseTypeDisplay from "Components/Displays/WarehouseTypeDisplay";
import CompanySelect from "Components/EntitySelects/CompanySelect";
import WarehouseStatusSelect from "Components/EnumSelects/WarehouseStatusSelect";
import SearchBox from "Components/Form/SearchBox";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { WarehouseContract, WarehouseStatus } from "api/types/contracts/warehouses";
import { ListWarehousesQuery } from "api/types/queries";
import { deleteWarehouse, getWarehouseList, patchWarehouseStatus } from "api/warehouse";
import { Ref, RefAttributes, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useImmer } from "use-immer";

export type WarehouseListRef = {
    reload: VoidFunction
}

const List = (props: {}, ref: Ref<WarehouseListRef>) => {
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const tableRef = useRef<TableContainerRef>(null);
    const dialogRef = useRef<DialogRef>(null);
    const { canRead } = useProfile();
    const [deleteMode, setDeleteMode] = useState<"single" | "multi">();
    const [selectedItems, setSelectedItems] = useState<WarehouseContract[]>([]);
    const warehouse = useMemo(() => selectedItems[0], [selectedItems]);
    const [params, setParams] = useSearchParams();
    
    const [query, updateQuery] = useImmer<ListWarehousesQuery>({
        page: 1,
        pageSize: 10,
        companyId: canRead("management.company") ? params.get("company") ?? undefined : undefined,
    });

    const { data: list, isPending: loading, refetch: loadList } = useQuery({
        queryKey: ["warehouses", query],
        queryFn: () => getWarehouseList(query),
        enabled: !!query.companyId || !canRead("management.company")
    });

    setPageTitle(`${t("Warehouse List")} - ${t("Management")}`);

    useEffect(() => {
        tableRef.current?.resetSelection();
    }, [list]);

    useImperativeHandle(ref, () => {
        return {
            reload: () => {
                loadList();
            }
        };
    }, [loadList]);

    const handleDeleteClick = (arg: WarehouseContract) => {
        setSelectedItems([arg]);
        setDeleteMode("single");
        dialogRef.current?.show();
    };

    const handleMultiDeleteClick = () => {
        setDeleteMode("multi");
        dialogRef.current?.show();
    };

    const deleteWarehouseMutation = useMutation({
        mutationFn: deleteWarehouse,
        mutationKey: ["delete-warehouse"],
        onSuccess: () => {
            setSelectedItems([]);
            setDeleteMode(undefined);
            dialogRef.current?.hide();
            loadList();
        }
    });

    const batchDeleteWarehouseMutation = useMutation({
        mutationFn: async () => {
            for (const element of selectedItems) {
                await deleteWarehouse({
                    warehouseId: element.warehouseId
                });
            }
        },
        mutationKey: ["batch-delete-warehouse"],
        onSuccess: () => {
            setSelectedItems([]);
            setDeleteMode(undefined);
            dialogRef.current?.hide();
            loadList();
        }
    });

    const warehouseStatusUpdateMutation = useMutation({
        mutationFn: patchWarehouseStatus,
        mutationKey: ["update-warehouse-status"],
        onSuccess: () => {
            loadList();
        }
    });

    const onWarehouseStatusChange = useCallback((warehouseId: string, status: WarehouseStatus) => {
        warehouseStatusUpdateMutation.mutate({
            warehouseId,
            status
        });
    }, [warehouseStatusUpdateMutation]);

    // Column
    const columns = useMemo<ColumnDef<WarehouseContract, any>[]>(() => [
        selectRowColumn<WarehouseContract>(), 
        {
            header: t("ACTIONS"),
            enableHiding: false,
            cell: (cell) => <>
                <div className="hstack gap-1">
                    <Restricted require="management.warehouse" write ownership={cell.row.original} fallback={() => <Button className="btn-ghost-dark px-1 py-0" disabled>
                            <i className="ri-pencil-fill fs-16"></i>
                        </Button>}>
                        <Link to={`/warehouses/edit/${cell.row.original.warehouseId}`} className="btn btn-ghost-dark px-1 py-0">
                            <i className="ri-pencil-fill fs-16"></i>
                        </Link>
                    </Restricted>
                    <Restricted require="management.warehouse" delete ownership={cell.row.original} fallback={() => <Button className="btn-ghost-danger px-1 py-0" disabled>
                            <i className="ri-delete-bin-5-fill fs-16"></i>
                        </Button>}>
                        <Button className="btn-ghost-danger px-1 py-0"
                            onClick={() => handleDeleteClick(cell.row.original)}>
                            <i className="ri-delete-bin-5-fill fs-16"></i>
                        </Button>
                    </Restricted>
                </div>
            </>
        }, 
        {
            header: t("CODE"),
            accessorFn: item => item.code,
            enableHiding: false,
            enableColumnFilter: false,
        },
        {
            header: t("NAME"),
            accessorFn: item => item.name,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <Restricted require="management.warehouse" ownership={cell.row.original} write fallback={() => <>{cell.row.original.name}</>}>
                <Link to={`/warehouses/edit/${cell.row.original.warehouseId}`} className="fw-medium link-secondary">{cell.row.original.name}</Link>
            </Restricted>
        },
        {
            header: t("COMPANY"),
            accessorFn: item => item.company.name,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <Restricted require="management.company" write ownership={cell.row.original.company} fallback={() => <>{cell.row.original.company.name}</>}>
                <Link to={`/companies/edit/${cell.row.original.company.companyId}`} className="fw-medium link-secondary">{cell.row.original.company.name}</Link>
            </Restricted>
        },
        {
            header: t("TYPE"),
            accessorFn: item => item.warehouseType,
            enableColumnFilter: false,
            cell: (cell) => <WarehouseTypeDisplay value={cell.row.original.warehouseType} />,
        },
        {
            header: t("PHONE"),
            accessorFn: item => item.address.phone,
            enableColumnFilter: false,
        },
        {
            header: t("EMAIL"),
            accessorFn: item => item.address.email,
            enableColumnFilter: false,
        },
        {
            header: t("ADDRESS"),
            accessorFn: item => <InlineAddressDisplay address={item.address} />,
            enableColumnFilter: false,
            cell: (cell) => <span className="text-muted fs-10 text-wrap">{cell.getValue()}</span>,
        },
        {
            header: t("STATUS"),
            accessorFn: item => item.warehouseStatus,
            cell: (cell) => {
                return <div style={{ minWidth: "100px" }}>
                    <BusyOverlay size="sm" busy={warehouseStatusUpdateMutation.isPending && warehouseStatusUpdateMutation.variables?.warehouseId === cell.row.original.warehouseId}>
                        <WarehouseStatusSelect name="status" size="sm"
                            value={cell.row.original.warehouseStatus} onChange={val => val && onWarehouseStatusChange(cell.row.original.warehouseId, val)} />
                    </BusyOverlay>
                </div>;
            }
        }],
        [t, onWarehouseStatusChange, warehouseStatusUpdateMutation.isPending, warehouseStatusUpdateMutation.variables?.warehouseId]
    );

    return <>
        <Row>
            <Col lg={12}>
                <Card>
                    <CardHeader className="border-0">
                        <Row className="align-items-center gy-3">
                            <div className="col-sm">
                                <h5 className="card-title mb-0">{t("Warehouse List")}</h5>
                            </div>
                            <div className="col-sm-auto">
                                <div className="d-flex gap-1 flex-wrap">
                                    <Link to="/warehouses/new" className="btn btn-info add-btn">
                                        <i className="ri-add-line align-bottom me-1"></i> {t("Create")}
                                    </Link>
                                    {selectedItems.length > 0  && <>
                                        <Button color="soft-danger" onClick={handleMultiDeleteClick}>
                                            <i className="ri-delete-bin-2-line"></i>
                                        </Button>
                                    </>}
                                </div>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody className="pt-0">
                        <div>
                            <Row>
                                <Restricted require="management.company" read>
                                    <Col sm={6} md={6} lg={3}>
                                        <CompanySelect value={query.companyId} onSelect={company => updateQuery(q => {
                                            q.companyId = company?.companyId;
                                        })} />
                                    </Col>
                                </Restricted>
                                <Col sm={6} md={4} lg={2}>
                                    <WarehouseStatusSelect value={query.warehouseStatus} placeholder="Select status" showClear
                                        onChange={val => updateQuery(q => {
                                            q.warehouseStatus = val;
                                        })} ></WarehouseStatusSelect>
                                </Col>
                                <Col sm={6} lg={4} className="ms-auto">
                                    <SearchBox value={query.search || ""} placeholder="Search name, email, phone or address"
                                        onChange={val => updateQuery(q => {
                                            q.search = val;
                                        })}></SearchBox>
                                </Col>
                            </Row>
                            {(query.companyId || !canRead("management.company")) &&
                                <TableContainer
                                    ref={tableRef}
                                    busy={loading}
                                    columns={columns}
                                    nowrap
                                    data={(list?.items || [])}
                                    totalDataLength={list?.totalCount}
                                    pagination={{
                                        pageIndex: query.page - 1,
                                        pageSize: query.pageSize
                                    }}
                                    onPaginationChanged={pagination => updateQuery(q => {
                                        q.page = pagination.pageIndex + 1;
                                        q.pageSize = pagination.pageSize;
                                    })}
                                    onSelectionChanged={selection => {
                                        setSelectedItems(selection);
                                    }}
                                    divClass="mb-1"
                                    tableClass="align-middle"
                                />
                            }
                        </div>
                    </CardBody>
                </Card>
                {!query.companyId && canRead("management.company") && <InfoAlert>{t("Please select a company to view warehouses")}</InfoAlert>}
            </Col>
        </Row>
        <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={batchDeleteWarehouseMutation.isPending || deleteWarehouseMutation.isPending} iconClass="ri-delete-bin-line"  
            message={`Do you want to continue?`} title={t("Deleting warehouse '{{name}}'", { name: warehouse?.name, count: selectedItems.length})}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    if (deleteMode === "multi") {
                        batchDeleteWarehouseMutation.mutate();
                    }
                    else if (deleteMode === "single" && warehouse) {
                        deleteWarehouseMutation.mutate({
                            warehouseId: warehouse.warehouseId
                        });
                    }
                    else {
                        hide();
                        setSelectedItems([]);
                    }
                }
                else {
                    hide();
                    if (deleteMode === "single") {
                        setSelectedItems([]);
                    }
                }
            }} />
    </>;
};

export default forwardRef(List) as (
    props: RefAttributes<WarehouseListRef>
) => ReturnType<typeof List>;