import { useTranslation } from "react-i18next";
import { Button, Label, PopoverBody, Table, UncontrolledPopover } from "reactstrap";
import Radiobox from "Components/Form/Radiobox";
import { CarrierAccountServiceContract, PackageInfoContract, ShipmentRateContract } from "api/types/contracts/carrier";
import CountryDisplay from "Components/Displays/CountryDisplay";
import _ from "lodash";
import Currency from "Components/Displays/UnitDisplay/Currency";
import Weight from "Components/Displays/UnitDisplay/Weight";
import React, { useEffect, useMemo, useRef } from "react";
import BusyOverlay from "Components/Common/BusyOverlay";
import { ShipmentChargesContract } from "api/types/contracts/shipping";
import { WarningAlert } from "Components/Alerts";
import { useQuery } from "@tanstack/react-query";
import { getCarrierAccountServiceList, postShipmentRateQuery } from "api/carrier";
import classNames from "classnames";

type CarrierSelectTableProps = {
    boxes: PackageInfoContract[],
    originWarehouseId: string,
    shipmentTargetId: string,
    originCountryCode: string,
    initialRates?: Record<string, ShipmentChargesContract>,
    disableAutoRates?: boolean,
    disabled?: boolean,
    static?: boolean,
    value?: string,
    onSelect?: (selected: CarrierServiceItem) => void
}

type CarrierServiceItem = {
    rates: ShipmentRateContract,
    carrierAccountService: CarrierAccountServiceContract
};

const CarrierSelectTable = (props: CarrierSelectTableProps) => {
    const { t } = useTranslation();

    const { initialRates, onSelect, ...rest } = props;

    const { data: list, isFetching: loading } = useQuery({
        queryKey: ["carrier-account-services", props.shipmentTargetId, props.originWarehouseId],
        queryFn: () => getCarrierAccountServiceList({ 
            shipmentTargetId: props.shipmentTargetId,
            warehouseId: props.originWarehouseId
        }),
        select: data => data.map<CarrierServiceItem>(service => ({
            carrierAccountService: service,
            rates: {
                ratio: 1,
                currencyCode: undefined,
                success: false,
                shippingCost: 0,
                importTaxCost: 0,
                insuranceCost: 0,
                oversizeCost: 0,
                overweightCost: 0,
                totalCost: 0,
                packageRates: [],
                details: {},
                message: undefined
            }
        }))
    });

    const selectItem = (item: CarrierServiceItem) => {
        if (props.disabled) {
            return;
        }
        props.onSelect?.({
            carrierAccountService: item.carrierAccountService,
            rates: {
                ...item.rates,
                success: !!item.rates.success
            }
        });
    }

    const totalWeight = useMemo(() => _.sumBy(props.boxes, b => b.weight), [props.boxes]);
    const isInternational = _.first(list)?.carrierAccountService.country.code !== props.originCountryCode;

    return <>
        {(!list || list.length === 0) ? <>
            <WarningAlert kind="default" className="m-2">{t("No carrier services available")}</WarningAlert>
        </> : <>
            <Table className="mb-0 align-middle">
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>{t("Carrier Service")}</th>
                        <th>{t("Destination Country")}</th>
                        <th className="text-center">{t("Box Count")}</th>
                        <th className="text-center">{t("Total Weight")}</th>
                        {isInternational && <th className="text-center">{t("Import Tax")}</th>}
                        <th className="text-center">{t("Shipping Cost")}</th>
                        <th className="text-center">{t("Other Cost(s)")}</th>
                        <th className="text-center">{t("Total Cost")}</th>
                        {!props.static && <th></th>}
                    </tr>
                </thead>
                <tbody>
                    {_.chain(list).groupBy(i => i.carrierAccountService.carrierId).map((carrierAccountServices, carrierId) => {
                        const carrier = carrierAccountServices[0].carrierAccountService.carrierService.carrier;

                        return <React.Fragment key={carrierId}>
                            <tr className="table-group-divider table-light">
                                <td colSpan={!props.static ? isInternational ? 10 : 9 : isInternational ? 9 : 8}>
                                    <div className="p-2">
                                        <img src={carrier.logoImage} alt={carrier.name} style={{ maxWidth: "100px", maxHeight: "35px" }} />
                                    </div>
                                </td>
                            </tr>
                            {carrierAccountServices.map((item, i) => {
                                const initialRate = initialRates?.[item.carrierAccountService.carrierAccountServiceId];

                                return <CarrierSelectTableItem isInternational={isInternational} {...rest} key={i} totalWeight={totalWeight}
                                    item={item} initialRate={initialRate} onSelect={selectItem} />;
                                })
                            }
                        </React.Fragment>;
                    }).value()}
                </tbody>
            </Table>
        </>}
    </>;
}

/*
* CarrierSelectTableItem
*/

type CarrierSelectTableItemProps = Pick<CarrierSelectTableProps, "originWarehouseId" | "boxes" | "shipmentTargetId" | "static" | "disableAutoRates" | "disabled" | "value"> & {
    item: CarrierServiceItem,
    totalWeight: number,
    isInternational: boolean,
    initialRate: ShipmentChargesContract | undefined,
    onSelect: (selected: CarrierServiceItem) => void
}

const CarrierSelectTableItem = (props: CarrierSelectTableItemProps) => {
    const warningIconRef = useRef<HTMLElement>(null);
    const { t } = useTranslation();

    const rateQuery = useQuery({
        queryKey: ["shipment-rate", props.item.carrierAccountService.carrierAccountServiceId, props.boxes],
        queryFn: () => postShipmentRateQuery({
            originWarehouseId: props.originWarehouseId,
            carrierAccountServiceId: props.item.carrierAccountService.carrierAccountServiceId,
            shipmentTargetId: props.shipmentTargetId,
            packageTypeCode: "",
            shipmentCode: "",
            packages: props.boxes
        }),
        staleTime: 1000 * 5, // 5 seconds
        enabled: !props.static && !props.disableAutoRates && props.boxes.length > 0
    });

    useEffect(() => {
        if (rateQuery.isSuccess && props.item.carrierAccountService.carrierAccountServiceId === props.value) {
            props.onSelect({
                carrierAccountService: props.item.carrierAccountService,
                rates: rateQuery.data
            });
        }
    }, [rateQuery.data]);
    
    const initialTotalOtherCosts = (props.initialRate?.overweightCost ?? 0) + (props.initialRate?.oversizeCost ?? 0) + (props.initialRate?.insuranceCost ?? 0);
    const totalOtherCosts = rateQuery.isSuccess ? rateQuery.data.overweightCost + rateQuery.data.oversizeCost + rateQuery.data.insuranceCost : 0;

    const currency = rateQuery.data?.currencyCode ?? props.boxes.length > 0 ? props.boxes[0].currency : "usd";

    return <tr>
        <td>
            <Radiobox 
                name="carrier"
                id={`carrier-service-${props.item.carrierAccountService.carrierAccountServiceId}`}
                disabled={props.disabled || !rateQuery.isSuccess}
                value={props.value === props.item.carrierAccountService.carrierAccountServiceId} 
                onChange={val => { 
                    if (val && rateQuery.isSuccess) {
                        props.onSelect({
                            carrierAccountService: props.item.carrierAccountService,
                            rates: rateQuery.data
                        });
                    }
                }} />
        </td>
        <td>
            <Label className="" htmlFor={`carrier-service-${props.item.carrierAccountService.carrierAccountServiceId}`}>
                {props.item.carrierAccountService.carrierService.logoImage ? 
                    <img src={props.item.carrierAccountService.carrierService.logoImage} alt={props.item.carrierAccountService.carrierService.name} style={{ maxWidth: "100px", maxHeight: "35px" }} />
                :
                    <span className="fw-semibold">{props.item.carrierAccountService.carrierService.name}</span>
                }
            </Label>
        </td>
        <td>
            <CountryDisplay country={props.item.carrierAccountService.country} />
        </td>
        <td className="text-center">{props.boxes.length}</td>
        <td className="text-center"><Weight value={props.totalWeight} unit={props.boxes.length > 0 ? props.boxes[0].weightUnit : undefined} /></td>
        {props.isInternational && <td className="text-center">
            <BusyOverlay busy={rateQuery.isFetching} opaque size="sm" backgroundColor="body-secondary" inline spinnerColor="tenant-primary">
                {(props.static || rateQuery.isPending || rateQuery.isError) ?
                    (props.initialRate && !rateQuery.isError ? <Currency value={props.initialRate.importTaxCost} currency={currency} />
                    : <span>-</span>) 
                : <>
                    <Currency value={rateQuery.data.importTaxCost} currency={currency} />
                </>}
            </BusyOverlay>
        </td>}
        <td className="text-center">
            <BusyOverlay busy={rateQuery.isFetching} opaque size="sm" backgroundColor="body-secondary" inline spinnerColor="tenant-primary">
                {(props.static || rateQuery.isPending || rateQuery.isError) ?
                    (props.initialRate && !rateQuery.isError ? <Currency value={props.initialRate.shippingCost} currency={currency} />
                    : <span>-</span>) 
                : <>
                    <Currency value={rateQuery.data.shippingCost} currency={currency} />
                </>}
            </BusyOverlay>
        </td>
        <td className="text-center">
            <BusyOverlay busy={rateQuery.isFetching} opaque size="sm" backgroundColor="body-secondary" inline spinnerColor="tenant-primary">
                {(props.static || rateQuery.isPending || rateQuery.isError) ?
                    (props.initialRate && !rateQuery.isError ? <>
                        <span className="text-decoration-dotted-underline cursor-pointer" id={`initialTotalOtherCosts_${props.item.carrierAccountService.carrierAccountServiceId}`}>
                            <Currency value={initialTotalOtherCosts} currency={currency} />
                        </span>
                        <UncontrolledPopover target={`initialTotalOtherCosts_${props.item.carrierAccountService.carrierAccountServiceId}`} trigger="legacy">
                            <PopoverBody className="p-2">
                                <Table borderless className="mb-0">
                                    <tr>
                                        <th className="py-1">{t("Oversize Cost")}</th>
                                        <td className="py-1"><Currency value={props.initialRate.oversizeCost} currency={currency} /></td>
                                    </tr>
                                    <tr>
                                        <th className="py-1">{t("Overweight Cost")}</th>
                                        <td className="py-1"><Currency value={props.initialRate.overweightCost} currency={currency} /></td>
                                    </tr>
                                    <tr>
                                        <th className="py-1">{t("Insurance Cost")}</th>
                                        <td className="py-1"><Currency value={props.initialRate.insuranceCost} currency={currency} /></td>
                                    </tr>
                                </Table>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </>
                    : <span>-</span>)
                : <>
                    <span className="text-decoration-dotted-underline cursor-pointer" id={`totalOtherCosts_${props.item.carrierAccountService.carrierAccountServiceId}`}>
                        <Currency value={totalOtherCosts} currency={currency} />
                    </span>
                    <UncontrolledPopover target={`totalOtherCosts_${props.item.carrierAccountService.carrierAccountServiceId}`} trigger="legacy">
                        <PopoverBody className="p-2">
                            <table className="table table-borderless mb-0">
                                <tr>
                                    <th className="py-1">{t("Oversize Cost")}</th>
                                    <td className="py-1"><Currency value={rateQuery.data.oversizeCost} currency={currency} /></td>
                                </tr>
                                <tr>
                                    <th className="py-1">{t("Overweight Cost")}</th>
                                    <td className="py-1"><Currency value={rateQuery.data.overweightCost} currency={currency} /></td>
                                </tr>
                                <tr>
                                    <th className="py-1">{t("Insurance Cost")}</th>
                                    <td className="py-1"><Currency value={rateQuery.data.insuranceCost} currency={currency} /></td>
                                </tr>
                            </table>
                        </PopoverBody>
                    </UncontrolledPopover>
                </>}
            </BusyOverlay>
        </td>
        <td className="text-center">
            <BusyOverlay busy={rateQuery.isFetching} opaque size="sm" backgroundColor="body-secondary" inline spinnerColor="tenant-primary">
                {(props.static || rateQuery.isPending) ?
                    (props.initialRate ? <Currency value={props.initialRate.totalCost} currency={currency} /> 
                    : <span>-</span>)
                : rateQuery.isError ? <>
                    <UncontrolledPopover target={warningIconRef} trigger="legacy">
                        <PopoverBody>
                            <div>
                                <span>{rateQuery.error.message}</span>
                            </div>
                        </PopoverBody>
                    </UncontrolledPopover>
                    <i className="ri-alert-fill text-warning cursor-pointer fs-4" ref={warningIconRef}></i>
                </> : <>
                    <Currency value={rateQuery.data.totalCost} currency={currency} />
                </>}
            </BusyOverlay>
        </td>
        {!props.static && <td>
            {props.disableAutoRates && 
                <Button color="ghost-success" disabled={rateQuery.isFetching} className="btn-icon" size="sm" onClick={() => rateQuery.refetch()}>
                    <i className={classNames("ri-refresh-line fs-4", {
                        "animated-rotation": rateQuery.isFetching
                    })}></i>
                </Button>}
        </td>}
    </tr>;
}

export default CarrierSelectTable;