import CountryDisplay from "Components/Displays/CountryDisplay";
import CustomerDisplay from "Components/Displays/CustomerDisplay";
import FbaShipmentStatusBadge from "Components/Displays/FbaShipmentStatusBadge";
import { InvoiceContract, InvoiceStatus } from "api/types/contracts/payment";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, CardTitle, Table } from "reactstrap";
import Currency from "Components/Displays/UnitDisplay/Currency";
import InvoiceStatusBadge from "Components/Displays/InvoiceStatusBadge";
import _ from "lodash";
import React, { useMemo, useRef } from "react";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import { payFbaShipmentInvoice } from "slices/shipping/fbaShipping/thunk";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { useMutation } from "@tanstack/react-query";
import { downloadInvoice, postCancelInvoice, postRefundInvoice } from "api/invoice";
import { toast } from "react-toastify";
import { multiDownload } from "helpers/urlHelper";
import BusyOverlay from "Components/Common/BusyOverlay";

type ViewProps = {
    invoice: InvoiceContract
}

const View = ({ invoice }: ViewProps) => {
    const dispatch = useAppDispatch();
    const { userProfile } = useProfile();
    const { t } = useTranslation();
    const payConfirmationDialogRef = useRef<DialogRef>(null);
    const refundConfirmationDialogRef = useRef<DialogRef>(null);
    const cancelConfirmationDialogRef = useRef<DialogRef>(null);

    const { loading } = useAppSelector(
        (state) => ({
            loading: state.FbaShipping.loading
        })
    );

    const itemGroups = useMemo(() => _.chain(invoice.items)
            .groupBy(i => i.groupName)
            .map((items, groupName) => ({
                groupName,
                items: items,
                total: _.sumBy(items, i => i.total)
            }))
            .value(), [invoice.items]);
    
    const grandTotal = useMemo(() => _.sumBy(invoice.items, item => item.total), [invoice.items]);
    

    const refundMutation = useMutation({
        mutationFn: postRefundInvoice,
        onSuccess: () => {
            toast.success(t("Invoice has been refunded"));
            refundConfirmationDialogRef.current?.hide();
        }
    });

    const cancelMutation = useMutation({
        mutationFn: postCancelInvoice,
        onSuccess: () => {
            toast.success(t("Invoice has been cancelled"));
            cancelConfirmationDialogRef.current?.hide();
        }
    });

    const downloadMutation = useMutation({
        mutationFn: downloadInvoice,
        onSuccess: result => {
            multiDownload([result]);
        }
    });

    const handleRefund = () => {
        refundConfirmationDialogRef.current?.show();    
    }

    const handleCancel = () => {
        cancelConfirmationDialogRef.current?.show();    
    }

    const handleDownload = () => {
        downloadMutation.mutate({
            invoiceId: invoice.invoiceId
        });
    }
    
    return <>
        <div className="hstack gap-2 mb-3">
            {Array<InvoiceStatus>("paid", "pending").includes(invoice.status) && <BusyOverlay overlayClassName="ms-auto" busy={downloadMutation.isPending} size="sm">
                <Button color="primary" className="ms-auto" title={t("Download")} onClick={handleDownload}>
                    {t("Download PDF")}
                </Button>
            </BusyOverlay>}
            {invoice.status === "paid" && <BusyOverlay busy={refundMutation.isPending} size="sm">
                <Button color="danger" onClick={handleRefund}>
                    {t("Refund Invoice")}
                </Button>
            </BusyOverlay>}
            {invoice.status === "pending" && <BusyOverlay busy={cancelMutation.isPending} size="sm">
                <Button color="warning" onClick={handleCancel}>
                    {t("Cancel Invoice")}
                </Button>
            </BusyOverlay>}
            {invoice.status === "draft" && <BusyOverlay busy={false} size="sm">
                <Button color="success">
                    {t("Pay Invoice")}
                </Button>
            </BusyOverlay>}
        </div>
        <Card body>
            <dl className="row align-items-center mb-0 g-2">
                {invoice.fbaShipment && <>
                    <dt className="col-2">{t("FBA Shipment ID")}</dt>
                    <dd className="col-2 mb-0"><Link to={`/fba-shipment/${invoice.fbaShipment.fbaShipmentId}`}># {invoice.fbaShipment.shipmentCode}</Link></dd>
                    <dt className="col-2">{t("Status")}</dt>
                    <dd className="col-2 mb-0"><FbaShipmentStatusBadge value={invoice.fbaShipment.status} /></dd>
                    <dt className="col-2">{t("Country")}</dt>
                    <dd className="col-2 mb-0"><CountryDisplay country={invoice.fbaShipment.shipmentTarget.address.country} /></dd>
                </>}

                <dt className="col-2">{t("Customer")}</dt>
                <dd className="col-2 mb-0"><CustomerDisplay customer={invoice.customer} /></dd>
                {invoice.fbaShipment && <>
                    <dt className="col-2">{t("Boxes")}</dt>
                    <dd className="col-2 mb-0">{invoice.fbaShipment.packages ? invoice.fbaShipment.packages.length : invoice.fbaShipment.estimatedBoxes.length}</dd>
                    <dt className="col-2">{t("Carrier")} / {t("Carrier")}</dt>
                    <dd className="col-2 mb-0">{invoice.fbaShipment.carrierAccountService.carrierService.carrier.name} / {invoice.fbaShipment.carrierAccountService.carrierService.name}</dd>
                </>}
                <hr className="my-2" />
                <dt className="col-2">{t("Invoice ID")}</dt>
                <dd className="col-2 mb-0"># {invoice.invoiceCode}</dd>
                <dt className="col-2">{t("Total")}</dt>
                <dd className="col-2 mb-0 d-flex align-items-center gap-2">
                    <Currency currency={invoice.unitOfCurrency} value={invoice.total} />
                    {invoice.customer.user.userId === userProfile?.user.userId && invoice.status === "pending" && <>
                        <Button color="success" className="" size="sm" onClick={() => payConfirmationDialogRef.current?.show()}>{t("Pay")}</Button>
                    </>}
                </dd>
                <dt className="col-2">{t("Payment Status")}</dt>
                <dd className="col-2 mb-0">
                    <InvoiceStatusBadge value={invoice.status} />
                </dd>
            </dl>
        </Card>
        <Card>
            <CardHeader>
                <CardTitle tag="h5">{t("Details")}</CardTitle>
            </CardHeader>
            <CardBody>
                <div className="table-responsive table-card">
                    <Table className="mb-0 align-middle" borderless>
                        {itemGroups.map(group => <React.Fragment key={group.groupName}>
                            <thead className="table-light text-muted">
                                <tr>
                                    <th>{group.groupName}</th>
                                    <th style={{ width: "10%" }}>{t("Unit Price")}</th>
                                    <th style={{ width: "10%" }}>{t("Count")}</th>
                                    <th style={{ width: "10%" }}>{t("Sub Total")}</th>
                                    <th style={{ width: "10%" }}>{t("Discount")}</th>
                                    <th style={{ width: "10%" }}>{t("Total")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {group.items.map((item, i) => {
                                    return <tr key={i}>
                                        <td>{item.serviceName}</td>
                                        <td><Currency currency={invoice.unitOfCurrency} value={item.unitPrice} /></td>
                                        <td>{item.count}</td>
                                        <td><Currency currency={invoice.unitOfCurrency} value={item.count * item.unitPrice} /></td>
                                        <td>{item.discount ? <Currency currency={invoice.unitOfCurrency} value={item.discount} /> : <>-</>}</td>
                                        <td><Currency currency={invoice.unitOfCurrency} value={item.total} /></td>
                                    </tr>;
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan={5} className="text-end">{t("Total")}</th>
                                    <th><Currency currency={invoice.unitOfCurrency} value={group.total} /></th>
                                </tr>
                            </tfoot>
                        </React.Fragment>)}
                    </Table>
                </div>
            </CardBody>
        </Card>
        <Card body>
            <div className="mx-auto w-50 vstack gap-3">
                {itemGroups.map(group => <React.Fragment key={group.groupName}>
                        <div className="d-flex justify-content-between">
                            <span>{group.groupName}</span>
                            <span className="fw-bold"><Currency currency={invoice.unitOfCurrency} value={group.total} /></span>
                        </div>
                    </React.Fragment>)}
                    <div className="d-flex justify-content-end gap-2 py-2 border-top border-dark-subtle">
                        <span className="fs-5">{t("Total")}</span>
                        <span className="fw-bold fs-5"><Currency currency={invoice.unitOfCurrency} value={grandTotal} /></span>
                    </div>
            </div>
        </Card>
        <Dialog ref={payConfirmationDialogRef} color="info" buttons={["yes", "no"]} busy={loading.invoicePay} iconClass="ri-money-dollar-circle-line"  
            message={`Do you want to continue?`} title={`You are about to pay the invoice`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    dispatch(payFbaShipmentInvoice({
                        invoiceId: invoice.invoiceId
                    })).then(() => {
                        hide();
                    });
                }
                else {
                    hide();
                }
            }} />
            <Dialog ref={refundConfirmationDialogRef} color="danger" buttons={["yes", "no"]} busy={refundMutation.isPending} iconClass="ri-refund-2-line"  
                message={`Do you want to continue?`} title={`You are about to refund the invoice`}
                onButtonClick={(button, hide) => {
                    if (button === "yes") {
                        refundMutation.mutate({
                            invoiceId: invoice.invoiceId
                        });
                    }
                    else {
                        hide();
                    }
                }} />
            <Dialog ref={cancelConfirmationDialogRef} color="warning" buttons={["yes", "no"]} busy={cancelMutation.isPending} iconClass="ri-close-circle-line"  
                message={`Do you want to continue?`} title={`You are about to cancel the invoice`}
                onButtonClick={(button, hide) => {
                    if (button === "yes") {
                        cancelMutation.mutate({
                            invoiceId: invoice!.invoiceId
                        });
                    }
                    else {
                        hide();
                    }
                }} />
    </>;
}

export default View;