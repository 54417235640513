import { RangeValueDefinitionContract } from 'api/types/contracts/common';
import { TypedShape } from 'helpers/types';
import * as Yup from 'yup';

Yup.addMethod(Yup.array<TypedShape<RangeValueDefinitionContract<number>>, RangeValueDefinitionContract<number>>, 'overlapCheck', function(message?: string) {
    
    return this.test("overlapCheck", message ?? "", (val, { schema }) => {
        
        if (!val) {
            return true;
        }
        
        for (let i = 0; i < val.length; i++) {
            const range = val[i];

            if (range.from >= range.to) {
                return false;
            }

            for (let j = i + 1; j < val.length; j++) {
                const otherRange = val[j];

                if ((range.from >= otherRange.from && range.from < otherRange.to) 
                    || (range.to > otherRange.from && range.to <= otherRange.to)) {
                    return false;
                }
            }
        }

        return true;
    });
});