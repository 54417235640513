import { useMemo } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

type CurrencyCountUpProps = {
    value: number,
    currency?: string
}

const CurrencyCountUp = (props: CurrencyCountUpProps) => {
    const { i18n } = useTranslation();

    const currencyCounterProps = useMemo(() => {
        const parts = Intl.NumberFormat(i18n.language, {
            style: 'currency',
            currency: props.currency ?? "usd"
        }).formatToParts(1111.11);

        return {
            start: 0,
            duration: 2,
            prefix: parts.find(p => p.type === "currency")?.value ?? "",
            decimal: parts.find(p => p.type === "decimal")?.value ?? ".",
            seperator: parts.find(p => p.type === "group")?.value ?? ",",
            decimals: 2,
            end: props.value,
        }
    }, [props.value, props.currency, i18n.language]);

    return <CountUp {...currencyCounterProps} />;
}

export default CurrencyCountUp;