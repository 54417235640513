import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { reloadLocations } from "slices/location/thunk";
import CreateAisleFormView from "./_CreateAisleFormView";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

const NewAisleButton = () => {
    const [newModal, setNewModal] = useState(false);
    const { t } = useTranslation();
    //const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { warehouse, selectedArea } = useAppSelector(
        (state) => ({
            ...state.Location,
            selectedArea: state.Location.areas?.find(a => a.areaId === state.Location.selectedAreaId)
        })
    );

    const onAisleAdded = () => { 
        setNewModal(false);
        dispatch(reloadLocations({ warehouseId: warehouse!.warehouseId }));
    };

    return warehouse ? <>
        <Button color="info" onClick={() => setNewModal(true)}>
            <i className="ri-add-line align-bottom me-1"></i> {t("Add New Aisle")}
        </Button>
        <Modal backdrop="static" isOpen={newModal} toggle={() => setNewModal(prev => !prev)} unmountOnClose>
            <ModalCloseButton onClick={() => setNewModal(false)} />
            <ModalHeader>
                {t("Add Aisles")}
            </ModalHeader>
            <ModalBody>
                <CreateAisleFormView areaId={selectedArea!.areaId} onSuccess={onAisleAdded} />
            </ModalBody>
        </Modal>
    </> : null;
};

export default NewAisleButton;