import { InvoiceStatus } from "api/types/contracts/payment";
import classNames from "classnames";
import { StatusBadgeProps } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";

const InvoiceStatusMap: Record<InvoiceStatus, { className: string }> = {
    "draft": { className: "bg-body text-body" },
    "pending": { className: "bg-danger" },
    "refund": { className: "bg-info" },
    "paid": { className: "bg-success" },
    "cancelled": { className: "bg-warning" }
};

const InvoiceStatusBadge = (props: StatusBadgeProps<InvoiceStatus>) => {
    const { t } = useTranslation("enums");

    if (props.value === undefined) {
        return <></>;
    }

    const statusName = t(`InvoiceStatus.${props.value}`);
    const { className } = InvoiceStatusMap[props.value];

    return <Badge color="invoice-status" className={classNames(props.className, className)}>{statusName}</Badge>
}

export default InvoiceStatusBadge;