import _ from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { Card, CardBody, CardHeader, CardTitle, UncontrolledTooltip } from "reactstrap"
import { FbaShipmentBoxContract, FbaShipmentPackageFormItem } from "api/types/contracts/shipping"
import Dimensions from "Components/Displays/UnitDisplay/Dimensions"
import Weight from "Components/Displays/UnitDisplay/Weight"
import { convertWeight } from "helpers/unitConversions"
import { dimensionalWeight } from "helpers/optimalBox"

const PackageItemColors = ["#3577f1", "#f06548", "#6559cc", "#f7b84b", "#02a8b5", "#405189", "#f672a7", "#299cdb", "#f1963b", "#0ab39c"];

type ShipmentPackageViewProps = {
    packedBoxes: FbaShipmentPackageFormItem[] | FbaShipmentBoxContract[],
    colorCodes: Record<string, string>
}

type ItemAvatarProps = {
    color: string | undefined,
    size: number,
    ratio?: number
};

const ItemAvatar = (props: ItemAvatarProps) => <span className="d-inline-block rounded rounded-circle" style={{
    width: `${props.size}rem`,
    backgroundColor: `${props.color ?? "var(--body-color)"}`,
    aspectRatio: props.ratio ?? 1
}}></span>;

const ShipmentPackageView = ({ packedBoxes, colorCodes }: ShipmentPackageViewProps) => {
    const { t } = useTranslation();

    return <>
        <div className="d-grid gap-3" style={{
            gridTemplateColumns: "repeat(auto-fill, minmax(17rem, 1fr)"
        }}>
            {packedBoxes.map((item, i) => {
                const box = "box" in item ? item.box : item;
                const primaryWeightUnit = box.weightUnit;
                const secondaryWeightUnit = primaryWeightUnit === "lbs" ? "kg" : "lbs";
                const dimWeight = dimensionalWeight(box);

                return <Card key={i} className="border">
                    <CardHeader className="">
                        <div className="d-flex justify-content-between">
                            <CardTitle tag="h6" className="fw-bold mb-0">{t("Package #{{number}}", { number: box.boxNumber })}</CardTitle>
                            <CardTitle tag="h6" className="mb-0">
                                <span id={`package-${i}`} className="text-decoration-dotted-underline">
                                    <Weight value={box.desi} unit="lbs" />/<Weight value={convertWeight(box.desi, "lbs", "kg")} unit="kg" />
                                </span>
                                <UncontrolledTooltip target={`package-${i}`}>
                                    {t("Desi")}
                                </UncontrolledTooltip>
                            </CardTitle>
                        </div>
                    </CardHeader>
                    <CardBody className="">
                        <div className="d-grid gap-2" style={{
                            gridTemplateColumns: "repeat(auto-fill, minmax(0.5rem, 1fr))"
                        }}>
                            {box.products.map((product, j) => {
                                return <React.Fragment key={j}>
                                    {Array(product.count).fill(0).map((_, k) => <ItemAvatar color={colorCodes[product.productId]} size={0.75} key={k} />)}
                                </React.Fragment>;
                            })}
                        </div>
                    </CardBody>
                    <CardBody className="justify-content-end vstack border-top py-2">
                        <div>
                            <Dimensions value={box} unit={box.lengthUnit} />
                        </div>
                        <div>
                            {t("Actual Weight")}: <Weight value={box.weight} unit={primaryWeightUnit} /> 
                            (<Weight value={convertWeight(box.weight, primaryWeightUnit, secondaryWeightUnit)} unit={secondaryWeightUnit} />)
                        </div>
                        {dimWeight > 0 && <div>
                            {t("Dimensional Weight")}: <Weight value={convertWeight(dimWeight, "lbs", primaryWeightUnit)} unit={primaryWeightUnit} />
                            (<Weight value={convertWeight(dimWeight, "lbs", secondaryWeightUnit)} unit={secondaryWeightUnit} />)
                        </div>}
                        {box.products.map((product, j) => <div className="hstack gap-2" key={j}>
                            <ItemAvatar color={colorCodes[product.productId]} size={0.5} />
                            <span>{product.asin} x {product.count}</span>
                        </div>)}
                    </CardBody>
                </Card>;
                })}
        </div>
        <div className="fs-5">
            {t("Total {{count}} package and {{weight}} {{unit}}", { 
                weight: _.sum(packedBoxes.map(p => p ? "box" in p ? p.box.weight : p.weight : 0)).toFixed(2), 
                count: packedBoxes.length, 
                unit: packedBoxes[0] ? "box" in packedBoxes[0] ? packedBoxes[0].box.weightUnit : packedBoxes[0].weightUnit : "lbs"
            })}
        </div>
    </>;
}

export { ItemAvatar, PackageItemColors };
export default ShipmentPackageView;