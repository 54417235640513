import { useQueries } from "@tanstack/react-query";
import SelectInput from "Components/Form/SelectInput"
import { getCarrierAccountServiceList, getCarrierList } from "api/carrier";
import { CarrierContract, type CarrierAccountServiceContract } from "api/types/contracts/carrier";
import { EntitySelectInputProps } from "helpers/types";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

type CarrierSelectProps = (EntitySelectInputProps<CarrierContract, "carrierId"> & {
    userDefinedOnly?: false
}) | (EntitySelectInputProps<CarrierContract, "carrierId"> & {
    userDefinedOnly: true,
    shipmentTargetId: string,
    warehouseId: string
})

const CarrierSelect = ({ isMulti, value, onChange, onSelect, name, isValid, ...rest }: CarrierSelectProps) => {
    const { t } = useTranslation();

    const [{ data: carriers, isPending: loading }] = useQueries({
        queries: [rest.userDefinedOnly ? {
            queryKey: ["carrier-account-service", rest.shipmentTargetId, rest.warehouseId],
            queryFn: () => getCarrierAccountServiceList({ shipmentTargetId: rest.shipmentTargetId, warehouseId: rest.warehouseId }),
            select: (data: CarrierAccountServiceContract[]) => _.chain(data)
                .map(cas => cas.carrierService.carrier)
                .uniqBy(c => c.carrierId)
                .value(),
        } : {
            queryKey: ["carriers"],
            queryFn: () => getCarrierList({})
        }]
    });

    const carrierList = useMemo(() => carriers?.map(c => ({ value: c.carrierId, label: c.name})) ?? [], [carriers]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = carriers?.filter(c => val.includes(c.carrierId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = carriers?.find(c => c.carrierId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.carrierId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        if (!value) {
            return;
        }

        if (!carriers || carriers.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carriers]);
    
    const selectProps = {
        ...rest,
        placeholder: rest.placeholder ?? t("Carrier"),
        busy: loading,
        options: carrierList,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default CarrierSelect;
