import { Row, Col, Label, Form, InputGroup, InputGroupText } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { BinType } from "api/types/contracts/bin";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import BusyOverlay from "Components/Common/BusyOverlay";
import { TypedShape } from "helpers/types";
import { DangerAlert } from "Components/Alerts";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import { CreateBinsCommand } from "api/types/commands";
import CompanySelect from "Components/EntitySelects/CompanySelect";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import NumberInput from "Components/Form/NumberInput";
import { CompanyContract } from "api/types/contracts/companies";
import Restricted from "Components/Common/Restricted";
import BinTypeSelect from "Components/EnumSelects/BinTypeSelect";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import RequiredLabel from "Components/Common/RequiredLabel";
import { useMutation } from "@tanstack/react-query";
import { postBin } from "api/bin";
import type { WarehouseContract } from "api/types/contracts/warehouses";
import { useProfile } from "Components/Hooks/ProfileHooks";
import LengthInput from "Components/Form/UnitInputs/LengthInput";
import StepInput from "Components/Form/StepInput";

type FormProps = {
    onSuccess?: (selectedWarehouse: WarehouseContract) => void;
}

type FormType = CreateBinsCommand;

const CreateBinForm = (props: FormProps) => {
    const [company, setCompany] = useState<CompanyContract>();
    const [warehouse, setWarehouse] = useState<WarehouseContract>();
    const { setPageTitle } = useMetadata();
    const { t } = useTranslation();
    const { userProfile } = useProfile();

    const createBinMutation = useMutation({
        mutationFn: postBin,
        mutationKey: ["create-bin"],
        onSuccess: () => {
            props.onSuccess?.(warehouse!);
        }
    });

    setPageTitle(`${t("Create Bin")} - ${t("Management")}`);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            count: 1
        } as FormType,
        validationSchema: Yup.object<FormType, TypedShape<FormType>>({
            count: Yup.number().required(t("You must enter bin count")).min(1, t("Bin count must be greater than zero")),
            width: Yup.number().notRequired(),
            height: Yup.number().notRequired(),
            length: Yup.number().notRequired(),
            warehouseId:  Yup.string().required(t("Please select a warehouse")),
            binType: Yup.string<BinType>().required(t("Please select a bin type")),
        }),
        onSubmit: (values) => {
            createBinMutation.mutate({
                ...values
            });
        },
    });

    const lengthUnit = warehouse?.settings.unitOfLength ?? userProfile?.user.warehouse?.settings.unitOfLength;

    return <>
        <Form className="needs-validation" action="#" onSubmit={validation.handleSubmit}>
            <div>
                <Row className="g-3">
                    <Restricted require={"management.company"} read>
                        <Col lg={5}>
                            <RequiredLabel htmlFor="company">{t("Company")}</RequiredLabel>
                            <CompanySelect name="company" onSelect={setCompany} />
                        </Col>
                    </Restricted>
                    <Col lg={5}>
                        <RequiredLabel htmlFor="warehouseId">{t("Warehouse")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="warehouseId">
                            <WarehouseSelect companyId={company?.companyId} onSelect={setWarehouse} />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={2}>
                        <RequiredLabel htmlFor="count">{t("Bin Count")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="count">
                            <StepInput min={1} placeholder="Enter bin count" />
                        </ValidationWrapper>
                    </Col>
                    <div className="clearfix m-0"></div>
                    <Col lg={5}>
                        <RequiredLabel htmlFor="binType">{t("Type")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="binType">
                            <BinTypeSelect />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={7}>
                        <Label>{t("Dimensions")}</Label>
                        <div className="hstack gap-2">
                            <InputGroup>
                                <ValidationWrapper validation={validation} field="width">
                                    <NumberInput placeholder="Enter Width" />
                                </ValidationWrapper>
                                <ValidationWrapper validation={validation} field="height">
                                    <NumberInput placeholder="Enter Height" />
                                </ValidationWrapper>
                                <ValidationWrapper validation={validation} field="length">
                                    <NumberInput placeholder="Enter Length" />
                                </ValidationWrapper>
                                <InputGroupText>
                                    {lengthUnit}
                                </InputGroupText>
                            </InputGroup>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="mt-4">{createBinMutation.isError && <DangerAlert>{createBinMutation.error.message}</DangerAlert>}</div>
            <div className="d-flex justify-content-end">
                <BusyOverlay busy={createBinMutation.isPending} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
};

export default CreateBinForm;
