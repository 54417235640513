import { useMutation, useQuery } from "@tanstack/react-query";
import type { ColumnDef } from "@tanstack/react-table";
import { downloadInvoice, getInvoiceList, postCancelInvoice, postPayInvoice, postRefundInvoice } from "api/invoice";
import type { InvoiceContract } from "api/types/contracts/payment";
import type { ListInvoicesQuery } from "api/types/queries";
import BusyOverlay from "Components/Common/BusyOverlay";
import Dialog, { type DialogRef } from "Components/Common/Dialog";
import Restricted from "Components/Common/Restricted";
import TableContainer from "Components/Common/TableContainer";
import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import CustomerDisplay from "Components/Displays/CustomerDisplay";
import DateDisplay from "Components/Displays/DateDisplay";
import InvoiceStatusBadge from "Components/Displays/InvoiceStatusBadge";
import Currency from "Components/Displays/UnitDisplay/Currency";
import InvoiceStatusSelect from "Components/EnumSelects/InvoiceStatusSelect";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { multiDownload } from "helpers/urlHelper";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { useImmer } from "use-immer";

const InvoiceListPage = () => {
    const { t } = useTranslation();
    const refundConfirmationDialogRef = useRef<DialogRef>(null);
    const cancelConfirmationDialogRef = useRef<DialogRef>(null);
    const [selectedInvoice, setSelectedInvoice] = useState<InvoiceContract>();
    const [query, updateQuery] = useImmer<ListInvoicesQuery>({
        page: 1,
        pageSize: 10,
        status: undefined
    });
    const { customer } = useProfile();

    const { data: invoices, isFetching: invoicesLoading, refetch: reloadInvoices } = useQuery({
        queryKey: ["invoices", query],
        queryFn: () => getInvoiceList(query)
    });

    const payMutation = useMutation({
        mutationFn: postPayInvoice,
        onSuccess: () => {
            toast.success(t("Payment has been successfully processed"));
            reloadInvoices();
        }
    });

    const refundMutation = useMutation({
        mutationFn: postRefundInvoice,
        onSuccess: () => {
            toast.success(t("Invoice has been refunded"));
            reloadInvoices();
            refundConfirmationDialogRef.current?.hide();
        }
    });

    const cancelMutation = useMutation({
        mutationFn: postCancelInvoice,
        onSuccess: () => {
            toast.success(t("Invoice has been cancelled"));
            reloadInvoices();
            cancelConfirmationDialogRef.current?.hide();
        }
    });

    const downloadMutation = useMutation({
        mutationFn: downloadInvoice,
        onSuccess: result => {
            multiDownload([result]);
        }
    });

    const handleRefund = (invoice: InvoiceContract) => {
        setSelectedInvoice(invoice);
        refundConfirmationDialogRef.current?.show();    
    }

    const handleCancel = (invoice: InvoiceContract) => {
        setSelectedInvoice(invoice);
        cancelConfirmationDialogRef.current?.show();    
    }

    const handleDownload = (invoice: InvoiceContract) => {
        downloadMutation.mutate({
            invoiceId: invoice.invoiceId
        });
    }

    const columns = useMemo<ColumnDef<InvoiceContract, any>[]>(() => [{
        header: t("ACTIONS"),
        enableHiding: false,
        cell: (cell) => <>
            <div className="hstack gap-1">
                <Link to={`/invoice/${cell.row.original.invoiceId}`} className="btn btn-ghost-dark btn-sm btn-icon" title={t("View")}>
                    <i className="ri-eye-fill fs-16"></i>
                </Link>
                <BusyOverlay busy={downloadMutation.isPending && downloadMutation.variables.invoiceId === cell.row.original.invoiceId} size="sm" backgroundColor="body-secondary" spinnerColor="tenant-primary" opaque inline>
                    <Button color="ghost-primary" size="sm" className="btn-icon" title={t("Download")} onClick={() => handleDownload(cell.row.original)}>
                        <i className="ri-download-line fs-16"></i>
                    </Button>
                </BusyOverlay>
                {cell.row.original.status === "paid" && <Button color="ghost-danger" size="sm" className="btn-icon" onClick={() => handleRefund(cell.row.original)} title={t("Refund")}>
                    <i className="ri-refund-2-line fs-16"></i>
                </Button>}
                {cell.row.original.status === "pending" && <Button color="ghost-warning" size="sm" className="btn-icon" onClick={() => handleCancel(cell.row.original)} title={t("Cancel")}>
                    <i className="ri-close-circle-line fs-16"></i>
                </Button>}
                {cell.row.original.status === "draft" && <Button color="ghost-success" size="sm" className="btn-icon" title={t("Pay")}>
                    <i className="ri-hand-coin-line fs-16"></i>
                </Button>}
            </div></>
        }, 
        {
            header: t("CUSTOMER"),
            accessorFn: item => item.customer.name,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <CustomerDisplay customer={cell.row.original.customer} />
        },
        {
            header: t("WAREHOUSE"),
            accessorFn: item => item.warehouse.name,
            enableHiding: false,
            enableColumnFilter: false
        },
        {
            header: t("INVOICE NUMBER"),
            accessorFn: item => item.invoiceCode,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <Link to={`/invoice/${cell.row.original.invoiceId}`} className="fw-medium link-secondary">
                #{cell.row.original.invoiceCode}
            </Link>
        },
        {
            header: t("RELATED SERVICE"),
            accessorFn: item => item.fbaShipment?.fbaShipmentId,
            enableColumnFilter: false,
            cell: (cell) => cell.row.original.fbaShipment ? <Link to={`/fba-shipment/${cell.row.original.fbaShipment.fbaShipmentId}`} className="fw-medium link-secondary">
                #{cell.row.original.fbaShipment.shipmentCode}
            </Link> : <span>-</span>
        },
        {
            header: t("AMOUNT"),
            accessorFn: item => item.total,
            enableColumnFilter: false,
            cell: (cell) => <Currency value={cell.row.original.total} currency={cell.row.original.unitOfCurrency} />
        },
        {
            header: t("STATUS"),
            accessorFn: item => item.status,
            enableColumnFilter: false,
            cell: (cell) => <div className="hstack gap-1">
                <InvoiceStatusBadge value={cell.row.original.status} />
                {cell.row.original.status === "pending" && <>
                    {cell.row.original.customer.customerId === customer?.customerId && <>
                        <BusyOverlay size="sm" busy={payMutation.isPending} inline>
                            <Button size="sm" color="success" onClick={() => payMutation.mutate({
                                invoiceId: cell.row.original.invoiceId
                            })}>{t("Pay")}</Button>
                        </BusyOverlay>
                    </>}
                </>}
            </div>
        },
        {
            header: t("CREATED"),
            accessorFn: item => item.createdAt,
            enableColumnFilter: false,
            cell: (cell) => <DateDisplay date={cell.row.original.createdAt} />
        }],
        [t, payMutation, downloadMutation]
    );

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("Invoices")} parents={[t("Billing")]} />
                <Row>
                    <Col>
                        <Card>
                            <CardHeader className="border-0 pb-0">
                                <Row className="align-items-center gy-3">
                                    <div className="col-sm">
                                        <h4 className="card-title mb-0">{t("Invoices")}</h4>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <p className="mb-0">{t("paragraphs:InvoicesPageDescription")}</p>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader className="border-0">
                                <Row className="align-items-center gy-3">
                                    <div className="col-sm">
                                        <h5 className="card-title mb-0">{t("Invoice List")}</h5>
                                    </div>
                                    <div className="col-auto ms-auto">
                                        <Restricted require="management.customer" read>
                                            <Link to="/create-invoice" className="btn btn-primary">
                                                <i className="ri-add-line me-2"></i>
                                                {t("New Invoice")}
                                            </Link>
                                        </Restricted>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <div>
                                    <Row>
                                        <Col sm={6} md={4}>
                                            <InvoiceStatusSelect value={query.status} placeholder={t("All Status")} onChange={val => {
                                                updateQuery(q => {
                                                    q.status = val;
                                                    return q;
                                                });
                                            }} />
                                        </Col>
                                    </Row>
                                    <TableContainer
                                        busy={invoicesLoading}
                                        columns={columns}
                                        nowrap
                                        data={(invoices?.items || [])}
                                        totalDataLength={invoices?.totalCount}
                                        pagination={{
                                            pageIndex: query.page - 1,
                                            pageSize: query.pageSize
                                        }}
                                        onPaginationChanged={pagination => updateQuery(q => {
                                            q.page = pagination.pageIndex + 1;
                                            q.pageSize = pagination.pageSize;
                                        })}
                                        divClass="mb-1"
                                        tableClass="align-middle"
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
        <Dialog ref={refundConfirmationDialogRef} color="danger" buttons={["yes", "no"]} busy={refundMutation.isPending} iconClass="ri-refund-2-line"  
            message={`Do you want to continue?`} title={`You are about to refund the invoice`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    refundMutation.mutate({
                        invoiceId: selectedInvoice!.invoiceId
                    });
                }
                else {
                    hide();
                }
            }} />
        <Dialog ref={cancelConfirmationDialogRef} color="warning" buttons={["yes", "no"]} busy={cancelMutation.isPending} iconClass="ri-close-circle-line"  
            message={`Do you want to continue?`} title={`You are about to cancel the invoice`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    cancelMutation.mutate({
                        invoiceId: selectedInvoice!.invoiceId
                    });
                }
                else {
                    hide();
                }
            }} />
    </>;
}

export default InvoiceListPage;