import { useQuery } from "@tanstack/react-query";
import InlineAddressDisplay from "Components/Displays/InlineAddressDisplay";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { WarehouseContract } from "api/types/contracts/warehouses";
import { getWarehouseListForSelect } from "api/warehouse";
import classNames from "classnames";
import { ValidatableInputProps } from "helpers/types";
import { useEffect, useState } from "react";
import { Input, Label } from "reactstrap";

type WarehouseGridSelectProps = ValidatableInputProps<string> & {
    onSelect?: (warehouse: WarehouseContract) => void,
    className?: string
};

const WarehouseGridSelect = (props: WarehouseGridSelectProps) => {
    const [initial, setInitial] = useState(true);
    const { userProfile } = useProfile();
    const { data: warehouses, isFetching: loading } = useQuery({
        queryKey: ["warehouse-grid-select"],
        queryFn: () => getWarehouseListForSelect({})
    });

    const onChange = (warehouse: WarehouseContract) => {
        props.onChange?.(warehouse.warehouseId);
        props.onSelect?.(warehouse);
    };

    useEffect(() => {
        if (!props.value) {
            return;
        }
        const found = warehouses?.find(w => w.warehouseId === props.value);
        
        if (found){
            onChange(found);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouses, props.value]);

    useEffect(() => {
        if (initial && userProfile?.user.warehouse) {
            onChange(userProfile.user.warehouse);
            setInitial(false);
        }
    }, [userProfile?.user.warehouse?.warehouseId, initial]);

    return <>
        <div className={classNames("w-100 d-grid gap-3 m-2", props.className)} style={{
            gridTemplateColumns: "repeat(6, minmax(120px, 1fr))"
        }}>
            {warehouses?.map((warehouse, i) => <div className="form-check card-radio" key={i}>
                <Input id={warehouse.warehouseId} name="warehouses" 
                    type="radio" className="form-check-input" 
                    checked={props.value === warehouse.warehouseId} onChange={() => onChange(warehouse)} />
                <Label className="form-check-label h-100" htmlFor={warehouse.warehouseId}>
                    <span className="d-flex vstack gap-3">
                        <span className="fs-14 text-wrap d-block">
                            <strong>{warehouse.name}</strong>
                        </span>
                        <span className="fw-light text-wrap d-block">
                            <InlineAddressDisplay address={warehouse.address} />
                        </span>
                    </span>
                </Label>
            </div>)}
            {/*warehouses.map(w => <div key={w.warehouseId} style={{ width: "15%" }}>
                <Card body className="mb-0 border border-dark">
                    <Label check>
                        <Radiobox name={`warehose_${w.warehouseId}`} value={props.value === w.warehouseId} onChange={() => onChange(w)}>
                            <strong>{w.name}</strong>
                        </Radiobox>
                        
                    </Label>
                </Card>
            </div>)*/}
        </div>
    </>;
}

export default WarehouseGridSelect;