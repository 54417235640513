import { InputGroup, InputGroupText } from "reactstrap";
import NumberInput, { NumberInputProps } from "../NumberInput";
import { useProfile } from "Components/Hooks/ProfileHooks";
import classNames from "classnames";
import { UnitOfWeight } from "api/types/contracts/common";

export type WeightInputProps = NumberInputProps & {
    unit?: UnitOfWeight
}

const WeightInput = ({ unit, className, size, ...rest }: WeightInputProps) => {
    const { userProfile } = useProfile();

    const weightUnit = unit ?? userProfile?.user.warehouse?.settings.unitOfWeight ?? "lbs";

    return <InputGroup size={size}>
        <NumberInput {...rest} decimalPlaces={2} className={classNames("border-end-0", className)} />
        <InputGroupText className="pe-2 ps-0">
            {weightUnit}
        </InputGroupText>
    </InputGroup>
}

export default WeightInput;