import { useQuery } from "@tanstack/react-query";
import SelectInput from "Components/Form/SelectInput"
import { getProductColorList } from "api/productColor";
import { ProductColorContract } from "api/types/contracts/products";
import { ListProductColorsQuery } from "api/types/queries";
import { EntitySelectInputProps, SelfPopulatedSelectInputRef } from "helpers/types";
import { ForwardedRef, RefAttributes, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useImmer } from "use-immer";

type ProductColorSelectProps = EntitySelectInputProps<ProductColorContract, "colorId"> & {
    userId?: string
}

const ProductColorSelect = ({ isMulti, value, onChange, onSelect, name, isValid, userId, ...rest }: ProductColorSelectProps, ref: ForwardedRef<SelfPopulatedSelectInputRef>) => {
    const { t } = useTranslation();
    const [query, updateQuery] = useImmer<ListProductColorsQuery>({
        page: 1,
        pageSize: 99,
        search: "",
        userId
    });
    const { data: colors, isFetching: loading, refetch: reload } = useQuery({
        queryKey: ["product-color-list", query],
        queryFn: () => getProductColorList(query),
        select: data => data.items
    });

    const productColorList = useMemo(() => colors?.map(b => ({
        label: <span className="d-inline-flex align-items-center">
            <i className="ri-checkbox-blank-circle-fill fs-14 me-2" style={{ 
                color: b.hexCode, 
                textShadow: "0 0 2px var(--body-color)" 
            }}></i>
            {b.name}
        </span>,
        value: b.colorId
    })) ?? [], [colors]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = colors?.filter(c => val.includes(c.colorId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = colors?.find(c => c.colorId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.colorId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useImperativeHandle(ref, () => {
        return {
            reload: () => {
                reload();
                return Promise.resolve();
            }
        };
    }, [reload]);

    const onSearch = useCallback((val?: string) => {
        updateQuery(q => {
            q.search = val;
        });
    }, [updateQuery]);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (!colors || colors.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [colors]);

    const selectProps = {
        ...rest,
        placeholder: rest.placeholder ?? t("Product Color"),
        busy: loading,
        options: productColorList,
        onSearch: onSearch,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default forwardRef(ProductColorSelect) as (
    props: ProductColorSelectProps & RefAttributes<SelfPopulatedSelectInputRef>
) => ReturnType<typeof ProductColorSelect>;