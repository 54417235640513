import { InfoAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import Loader from "Components/Common/Loader";
import ValidatedFileUpload from "Components/Form/Validated/ValidatedFileUpload";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { createAppSelector, useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { FbaShipmentContract } from "api/types/contracts/shipping";
import { useFormik } from "formik";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, Col, Form, Row } from "reactstrap";
import { clearFbaShipmentDocuments, uploadFbaShipmentDocuments } from "slices/shipping/fbaShipping/thunk";
import * as Yup from "yup";

type DocumentUploadProps = {
    
}

const BoxLabelDocumentType = "00000000-0000-0000-0002-000000000001";
const ShippingLabelDocumentType = "00000000-0000-0000-0002-000000000002";

const createForm = (fbaShipment: FbaShipmentContract | undefined) => {
    return {
        boxLabel: fbaShipment?.documents.find(d => d.documentTypeId === BoxLabelDocumentType)?.path ?? "",
        shippingLabel: fbaShipment?.documents.find(d => d.documentTypeId === ShippingLabelDocumentType)?.path,
    };
}

const DocumentUpload = (props: DocumentUploadProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const removeDocumentsConfirmDialogRef = useRef<DialogRef>(null);

    const { fbaShipment, loading } = useAppSelector(
        createAppSelector([state => state.FbaShipping],
            (fbaShipping) => ({
                fbaShipment: fbaShipping.processingItem,
                loading: fbaShipping.loading
            })
        )
    );
    
    const documentsValidation = useFormik({
        initialValues: createForm(fbaShipment),
        validationSchema: Yup.object({
            boxLabel: Yup.string().required("Please upload box label"),
            shippingLabel: Yup.string().notRequired()
        }),
        onSubmit: async values => {
            const documents = [
                { documentTypeId: BoxLabelDocumentType, file: values.boxLabel },
            ];

            if (values.shippingLabel) {
                documents.push({ documentTypeId: ShippingLabelDocumentType, file: values.shippingLabel });
            }

            await dispatch(uploadFbaShipmentDocuments({
                fbaShipmentId: fbaShipment!.fbaShipmentId,
                documents
            }));
        }
    });

    useEffect(() => {
        documentsValidation.setValues(createForm(fbaShipment));
    }, [fbaShipment]);

    if (!fbaShipment) {
        return <Loader height="50px" />;
    }

    const isUploadActive = fbaShipment.status === "boxLabelPending" || fbaShipment.status === "boxLabelUploaded";

    return <>
        <Card>
            <CardBody>
                <Form className="needs-validation" action="#" onSubmit={documentsValidation.handleSubmit}>
                    <Row>
                        <Col>
                            <InfoAlert kind="solid">{t("Please upload the box label you obtained form Amazon here.")}</InfoAlert>
                            <ValidatedFileUpload fetchOnly={!isUploadActive} validation={documentsValidation} field="boxLabel" />
                        </Col>
                        <Col>
                            <InfoAlert kind="solid">{t("Please upload the shipping label you obtained form Amazon here.")}</InfoAlert>
                            <ValidatedFileUpload fetchOnly={!isUploadActive} validation={documentsValidation} field="shippingLabel" />
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-center gap-2 mt-3">
                        <BusyOverlay busy={loading.save} size="sm">
                            <ValidatorButton type="submit" color="success" validation={documentsValidation}>
                                {t("I confirm that I have uploaded labels")}
                            </ValidatorButton>
                        </BusyOverlay>
                        {fbaShipment.documents.length > 0 && <Button type="button" color="danger" onClick={() => removeDocumentsConfirmDialogRef.current?.show()}>{t("Cancel label upload")}</Button>}
                    </div>
                </Form>
            </CardBody>
        </Card>
        <Dialog ref={removeDocumentsConfirmDialogRef} color="danger" buttons={["yes", "no"]} busy={loading.save} iconClass="ri-delete-bin-line"  
            message={t('You are going to remove all uploaded documents')} title={`Do you want to remove documents?`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    dispatch(clearFbaShipmentDocuments({
                        fbaShipmentId: fbaShipment.fbaShipmentId
                    })).then(() => {
                        hide();
                    });
                }
                else {
                    hide();
                }
            }} />
    </>;
}

export default DocumentUpload;
