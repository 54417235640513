import config from "config"
import delay from "./delay";

export const withCdn = (path: string) => isFullUrl(path) ? path : `${config.cdn.FILES_URL}/${path}`;
export const isCdnUrl = (path: string) => path.startsWith(config.cdn.FILES_URL);
export const isFullUrl = (path: string) => path.startsWith("http://") || path.startsWith("https://");

const download = async (url: string, name: string) => {
	const a = document.createElement('a');
	a.download = name;
	a.href = url;
	a.target = '_blank';
	a.style.display = 'none';
	document.body.append(a);
	a.click();

	// Chrome requires the timeout
	await delay(100);
	a.remove();
};

export const multiDownload = async (items: (string | { blob: Blob, fileName: string })[]) => {
	if (!items) {
		throw new Error('`items` required');
	}

	for (const [index, item] of items.entries()) {
		let url = '', name = '';
		if (typeof item === "string") {
			url = item;
			name = url.split('/').at(-1)?.split('?').at(0) ?? '';
		}
		else {
			url = URL.createObjectURL(item.blob);
			name = item.fileName;
		}

		await delay(index * 1000); // eslint-disable-line no-await-in-loop
		download(url, name);
	}
}

/*
const a = (strs: TemplateStringsArray, ...values: any[]) => {
	console.log(strs, values);
	return "";
}
const b = a`Hello ${"world"}`;
*/