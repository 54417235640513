import ProductCard from "./_ProductCard";
import { Button, Card, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Loader from "Components/Common/Loader";
import BusyOverlay from "Components/Common/BusyOverlay";
import { updateFbaShipment } from "slices/shipping/fbaShipping/thunk";
import { createAppSelector, useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

type ProductCardGridProps = {
    
}

const ProductCardGrid = (props: ProductCardGridProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { fbaShipment, loading } = useAppSelector(
        createAppSelector([state => state.FbaShipping], 
            (fbaShipping) => ({
                fbaShipment: fbaShipping.processingItem,
                loading: fbaShipping.loading
            })
        )
    );

    if (!fbaShipment) {
        return <Loader height="200px" />;
    }

    const updateStatusToProcessing = async () => {
        await dispatch(updateFbaShipment({
            fbaShipmentId: fbaShipment.fbaShipmentId,
            status: "processing"
        }));
    };

    return <>
        {["productPending", "fnskuPending", "ready"].includes(fbaShipment.status) && <div className="d-flex justify-content-center mt-2">
            <BusyOverlay busy={loading.save || loading.load} size="sm">
                <Button color="success" onClick={updateStatusToProcessing}>{t("Start Processing")}</Button>
            </BusyOverlay>
        </div>}
        <Card body className="mt-3">
            <h5 className="mb-0">
                {t("Selected Products ({{skuCount}} SKU & {{count}} items)", { 
                    skuCount: fbaShipment.items.length, 
                    count: _.sumBy(fbaShipment.items, i => i.count)
                })}
            </h5>
        </Card>
        <Row className="row-cols-3 mb-2">
            {fbaShipment.items.map((item, index) => {
                return <Col key={index}>
                    <ProductCard fbaShipment={fbaShipment} fbaShipmentItem={item} canEdit={fbaShipment.status === "processing"} />
                </Col>;
            })}
        </Row>
    </>;
};

export default ProductCardGrid;