import { useMutation } from "@tanstack/react-query";
import BusyOverlay from "Components/Common/BusyOverlay";
import RequiredLabel from "Components/Common/RequiredLabel";
import ValidationErrorDisplay from "Components/Displays/ValidationErrorDisplay";
import CountrySelect from "Components/EntitySelects/CountrySelect";
import NumberInput from "Components/Form/NumberInput";
import TextInput from "Components/Form/TextInput";
import CurrencyInput from "Components/Form/UnitInputs/CurrencyInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { createAppSelector, useAppSelector } from "Components/Hooks/StoreHooks";
import { postCarrierImportTaxPricingRule, putCarrierImportTaxPricingRule } from "api/carrierRules";
import { CarrierImportTaxPricingRuleContract, CarrierImportTaxPricingRuleForm } from "api/types/contracts/carrier";
import { RangeValueDefinitionContract } from "api/types/contracts/common";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, Col, Container, Form, Label, Row, UncontrolledTooltip } from "reactstrap";
import * as Yup from "yup";

type FormProps = {
    importTaxPricingRule?: CarrierImportTaxPricingRuleContract,
    onSuccess: VoidFunction
}

const defaultForm: Partial<CarrierImportTaxPricingRuleForm> = {
    name: "",
    taxRanges: {}
};

const createImportTaxPricingRuleForm = (rule: CarrierImportTaxPricingRuleContract | undefined): CarrierImportTaxPricingRuleForm | undefined => {
    return rule ? {
        name: rule.name,
        taxRanges: {..._.transform(rule.taxRanges, (ranges, value, key) => {
            ranges[key.toUpperCase()] = _.sortBy(value, r => r.from);
        })},
    } as CarrierImportTaxPricingRuleForm : undefined
};

const EditForm = (props: FormProps) => {
    const { setPageTitle } = useMetadata();
    const { t } = useTranslation();
    const ruleForm = useMemo(() => createImportTaxPricingRuleForm(props.importTaxPricingRule) || defaultForm, [props.importTaxPricingRule]);
    const [countries, setCountries] = useState<string[]>(Object.keys(ruleForm.taxRanges || {}).map(c => c.toUpperCase()));

    setPageTitle(`${t(props.importTaxPricingRule ? "Update Import Tax Pricing Rule" : "Create Import Tax Pricing Rule")} - ${t("Carrier Rules")} - ${t("Management")}`);

    const { countryList } = useAppSelector(
        createAppSelector([state => state.Common],
            (common) => ({
                countryList: common.countries
            })
        )
    );

    const selectedCountries = useMemo(() => countryList.filter(c => countries.includes(c.code)), [countries, countryList]);

    const saveCarrierImportTaxPricingRuleMutation = useMutation({
        mutationFn: async (params: CarrierImportTaxPricingRuleForm) => {
            if (props.importTaxPricingRule) {
                await putCarrierImportTaxPricingRule({
                    carrierImportTaxPricingRuleId: props.importTaxPricingRule.carrierImportTaxPricingRuleId,
                    carrierImportTaxPricingRule: params
                });
            }
            else {
                await postCarrierImportTaxPricingRule({
                    carrierImportTaxPricingRule: params
                });
            }
        },
        onSuccess: () => {
            if (props.importTaxPricingRule) {
                toast.success(t("Import tax rule updated"))
            }
            else {
                toast.success(t("Import tax rule created"))
            }

            props.onSuccess();
        }
    });
    
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...ruleForm
        },
        validationSchema: Yup.object<CarrierImportTaxPricingRuleForm, TypedShape<CarrierImportTaxPricingRuleForm>>({
            name: Yup.string().required(t("Name is required")),
            taxRanges: Yup.lazy(values => {
                const newEntries = Object.keys(values).reduce(
                    (accumulator, currentValue) => ({
                        ...accumulator,
                        [currentValue.toUpperCase()]: Yup.array<RangeValueDefinitionContract<number>>().of(Yup.object({
                            from: Yup.number().required(t("Lower price is required")),
                            to: Yup.number().required(t("Upper price is required")),
                            value: Yup.number().required(t("Tax Percentage is required"))
                        })).min(1, t("At least one price range is required")).overlapCheck(t("Ranges must not overlap")),
                    }), {});
                    
                    return Yup.object(newEntries);
                }),
        }),
        onSubmit: values => {
            const carrierImportTaxPricingRule = values as CarrierImportTaxPricingRuleForm;

            saveCarrierImportTaxPricingRuleMutation.mutate(carrierImportTaxPricingRule);
        },
    });

    const addNewPriceRangeRow = (countryCode: string) => {
        const taxRanges = validation.values.taxRanges!;
        const oldRanges = taxRanges[countryCode] || [];
        const lastRangeEnd = oldRanges.at(-1)?.to || 0;

        validation.setFieldValue(`taxRanges.${countryCode}`, [
            ...oldRanges, {
                from: lastRangeEnd,
                to: lastRangeEnd + 1
            }]
        );
    };

    const removePriceRangeRow = (countryCode: string, i: number) => {
        const taxRanges = validation.values.taxRanges!;
        const newRanges = taxRanges[countryCode].filter((_, index) => index !== i);
        validation.setFieldValue(`taxRanges.${countryCode}`, newRanges);
    }

    const removeCountryRow = (countryCode: string) => {
        setCountries(countries.filter(c => c !== countryCode));

        const taxRanges = validation.values.taxRanges!;
        const newRanges = { ...taxRanges };
        delete newRanges[countryCode];
        validation.setFieldValue("taxRanges", newRanges);
    }

    useEffect(() => {
        const taxRanges = validation.values.taxRanges!;

        for (const country of countries) {
            if (!taxRanges[country]) {
                addNewPriceRangeRow(country);
            }
        }

        Object.keys(taxRanges).forEach(countryCode => {
            if (!countries.find(c => c === countryCode)) {
                removeCountryRow(countryCode);
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries]);

    return <div>
        <Form className="needs-validation" action="#" onSubmit={validation.handleSubmit}>
            <Container fluid>
                <Row>
                    <Col className="mb-2">
                        <h4>{t(props.importTaxPricingRule ? "Edit Import Tax Pricing Rule" : "Create Import Tax Pricing Rule")}</h4>
                        <p className="mb-0 fs-10">{t("paragraphs:CarrierRulesImportTaxPricingRuleDescription")}</p>
                    </Col>
                </Row>
                <Row className="g-2 mb-2">
                    <Col xs={12}>
                        <RequiredLabel>{t("Rule Name")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="name">
                            <TextInput />
                        </ValidationWrapper>
                    </Col>
                    <Col xs={12}>
                        <Label>{t("Countries")}</Label>
                        <CountrySelect isMulti value={countries} onChange={setCountries} />
                    </Col>
                </Row>
                <div className="overflow-y-auto overflow-x-hidden mb-2" style={{ height: "25em" }}>
                {countries.length === 0 && <div className="text-center text-muted">
                    {t("Please select countries to add tax ranges")}
                </div>}
                {_.map(validation.values.taxRanges, (ranges, countryCode) => {
                    const country = selectedCountries.find(c => c.code === countryCode);

                    if (!country) {
                        return;
                    }

                    return <Row className="g-2 mb-2" key={countryCode}>
                        <Col className="vstack gap-2">
                            <div className="label-separator my-2 label-seperator-2" style={{ marginRight: "2rem" }}>
                                <span className="label">
                                    <span>{country.name}</span>
                                    <span> - </span>
                                    <span className="text-muted">{country.currency.toUpperCase()}</span>
                                </span>
                                <Button onClick={() => removeCountryRow(country.code)} 
                                    color="ghost-danger" 
                                    size="sm" 
                                    className="btn-icon position-absolute" 
                                    id={`remove-country-${country.code}`}
                                    style={{ right: "-2rem" }}>
                                    <i className="ri-close-circle-fill"></i>
                                </Button>
                                <UncontrolledTooltip target={`remove-country-${country.code}`} placement="top">
                                    {t("Remove country")}
                                </UncontrolledTooltip>
                            </div>
                            <div className="hstack gap-3 text-center">
                                <div style={{ flex: 4 }} className="fw-semibold small">
                                    {t("Price Range")}
                                </div>
                                <div style={{ flex: 4 }} className="fw-semibold small">
                                    {t("Tax Percentage")}
                                </div>
                            </div>
                            {ranges.map((range, i) => <React.Fragment key={i}>
                                <div className="hstack gap-3 align-items-center" key={i}>
                                    <span className="small text-muted">{t("from")}</span>
                                    <div style={{ flex: 3 }}>
                                        <ValidationWrapper validation={validation} field={`taxRanges.${country.code}.${i}.from`}>
                                            <CurrencyInput size="sm" placeholder="From" className="text-center" currency={country.currency} />
                                        </ValidationWrapper>
                                    </div>
                                    <span className="small text-muted">{t("to")}</span>
                                    <div style={{ flex: 3 }}>
                                        <ValidationWrapper validation={validation} field={`taxRanges.${country.code}.${i}.to`}>
                                            <CurrencyInput size="sm" placeholder="To" className="text-center" currency={country.currency} />
                                        </ValidationWrapper>
                                    </div>
                                    <span className="small text-muted">{t("then")}</span>
                                    <div style={{ flex: 3 }}>
                                        <ValidationWrapper validation={validation} field={`taxRanges.${country.code}.${i}.value`}>
                                            <NumberInput size="sm" placeholder="Percentage" />
                                        </ValidationWrapper>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <Button size="sm" color="ghost-danger" className="btn-icon" onClick={() => removePriceRangeRow(country.code, i)}>
                                            <i className="ri-delete-bin-5-fill"></i>
                                        </Button>
                                    </div>
                                </div>
                            </React.Fragment>)}
                            <ValidationErrorDisplay validation={validation} field="taxRanges" showUntouched />
                            <div className="d-flex justify-content-end">
                                <Button size="sm" type="button" className="btn-ghost-info" onClick={() => addNewPriceRangeRow(country.code)}>
                                    {t("Add another")}
                                </Button>
                            </div>
                        </Col>
                    </Row>;
                })}
                </div>
            </Container>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={saveCarrierImportTaxPricingRuleMutation.isPending} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </div>;
}

export default EditForm;