import { useQuery } from "@tanstack/react-query";
import type { ColumnDef } from "@tanstack/react-table";
import { getTransactionHistoryList } from "api/payment";
import type { TransactionHistoryContract } from "api/types/contracts/payment";
import { type ListTransactionHistoryQuery } from "api/types/queries";
import TableContainer, { type TableContainerRef } from "Components/Common/TableContainer";
import CustomerDisplay from "Components/Displays/CustomerDisplay";
import DateDisplay from "Components/Displays/DateDisplay";
import Currency from "Components/Displays/UnitDisplay/Currency";
import CustomerSelect from "Components/EntitySelects/CustomerSelect";
import { forwardRef, useImperativeHandle, useMemo, useRef, type Ref, type RefAttributes} from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useImmer } from "use-immer";
import AdjustUserBalance from "./_AdjustUserBalance";

export type TransactionHistoryListRef = {
    reload: VoidFunction
}

const TransactionHistoryList = (props: {}, ref: Ref<TransactionHistoryListRef>) => {
    const { t } = useTranslation();
    const tableRef = useRef<TableContainerRef>(null);
    const [query, updateQuery] = useImmer<ListTransactionHistoryQuery>({
        page: 1,
        pageSize: 10,
    });

    const { data: transactions, isFetching: transactionsLoading, refetch: reloadTransactionHistoryList } = useQuery({
        queryKey: ["transaction-history", query],
        queryFn: () => getTransactionHistoryList(query)
    });

    useImperativeHandle(ref, () => {
        return {
            reload: () => {
                reloadTransactionHistoryList();
            }
        };
    }, [reloadTransactionHistoryList]);

    const columns = useMemo<ColumnDef<TransactionHistoryContract, any>[]>(() => [
        {
            header: t("TRANSACTION ID"),
            accessorFn: item => item.transactionCode,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <>
                <Link to={`#`} className="fw-medium link-secondary">
                    #{cell.row.original.transactionCode}
                </Link>
            </>
        },
        {
            header: t("USER"),
            accessorFn: item => item.customer.name,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <CustomerDisplay customer={cell.row.original.customer} />
        },
        {
            header: t("DESCRIPTION"),
            accessorFn: item => item.description,
            enableColumnFilter: false
        },
        {
            header: t("DEBIT"),
            accessorFn: item => item.debit,
            enableColumnFilter: false,
            cell: (cell) => <Currency value={cell.row.original.debit || undefined} currency={cell.row.original.unitOfCurrency} />
        },
        {
            header: t("CREDIT"),
            accessorFn: item => item.credit,
            enableColumnFilter: false,
            cell: (cell) => <Currency value={cell.row.original.credit || undefined} currency={cell.row.original.unitOfCurrency} />
        },
        {
            header: t("BALANCE"),
            accessorFn: item => item.balance,
            enableColumnFilter: false,
            cell: (cell) => <Currency value={cell.row.original.balance} currency={cell.row.original.unitOfCurrency} />
        },
        {
            header: t("INVOICE"),
            accessorFn: item => item.invoice?.invoiceId,
            enableColumnFilter: false,
            cell: (cell) => cell.row.original.invoice ? <Link to={`/invoice/${cell.row.original.invoice.invoiceId}`} className="fw-medium link-secondary">
                #{cell.row.original.invoice.invoiceCode}
            </Link> : <span>-</span>
        },
        {
            header: t("DATE"),
            accessorFn: item => item.createdAt,
            enableColumnFilter: false,
            cell: (cell) => <DateDisplay date={cell.row.original.createdAt} />
        }],
        [t]
    );

    return <>
        <Row>
            <Col>
                <Card>
                    <CardHeader className="border-0 pb-0">
                        <Row className="align-items-center gy-3">
                            <div className="col-sm">
                                <h5 className="card-title mb-0">{t("Transaction History")}</h5>
                            </div>
                            <div className="col-sm-auto">
                                <div className="d-flex gap-1 flex-wrap">
                                    <AdjustUserBalance onSuccess={() => {
                                        reloadTransactionHistoryList();
                                    }} />
                                </div>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody className="pt-0">
                        <div>
                            <Row>
                                <Col sm={6} md={4}>
                                    <CustomerSelect value={query.customerId} placeholder={t("All Customers")} onChange={val => {
                                        updateQuery(q => {
                                            q.customerId = val;
                                            return q;
                                        });
                                    }} />
                                </Col>
                            </Row>
                            <TableContainer
                                ref={tableRef}
                                busy={transactionsLoading}
                                columns={columns}
                                nowrap
                                data={(transactions?.items || [])}
                                totalDataLength={transactions?.totalCount}
                                pagination={{
                                    pageIndex: query.page - 1,
                                    pageSize: query.pageSize
                                }}
                                onPaginationChanged={pagination => updateQuery(q => {
                                    q.page = pagination.pageIndex + 1;
                                    q.pageSize = pagination.pageSize;
                                })}
                                divClass="mb-1"
                                tableClass="align-middle"
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>;
}

export default forwardRef(TransactionHistoryList) as (
    props: RefAttributes<TransactionHistoryListRef>
) => ReturnType<typeof TransactionHistoryList>;