import { useMutation, useQuery } from "@tanstack/react-query";
import { CustomerContract } from "api/types/contracts/customers";
import type { WalletContract } from "api/types/contracts/payment";
import { getWallet, patchAdjustBalance } from "api/wallet";
import { WarningAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import Currency from "Components/Displays/UnitDisplay/Currency";
import CustomerSelect from "Components/EntitySelects/CustomerSelect";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import SelectInput from "Components/Form/SelectInput";
import TextInput from "Components/Form/TextInput";
import CurrencyInput from "Components/Form/UnitInputs/CurrencyInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, FormGroup, FormText, Label, Modal, ModalBody, Row } from "reactstrap";
import * as Yup from "yup";

type AdjustUserBalanceProps = {
    onSuccess: () => void
}

const AdjustUserBalance = (props: AdjustUserBalanceProps) => {
    const { t } = useTranslation();
    const [userBalanceModal, setUserBalanceModal] = useState(false);
    const [customer, setCustomer] = useState<CustomerContract>();

    const adjustmentTypeOptions = useMemo(() => [
        { label: t("Credit"), value: true },
        { label: t("Debit"), value: false }
    ], [t]);

    const adjustBalanceMutation = useMutation({
        mutationFn: patchAdjustBalance,
        onSuccess: () => {
            setUserBalanceModal(false);
            validation.resetForm();
            setCustomer(undefined);
            props.onSuccess();
        }
    });

    const validation = useFormik({
        initialValues: {
            customerId: "",
            warehouseId: "",
            note: "",
            amount: 0,
            isCredit: true
        },
        validationSchema: Yup.object({
            customerId: Yup.string().required(t("Select a customer")),
            warehouseId: Yup.string().required(t("Select a warehouse")),
            amount: Yup.number().min(0, t("Enter a valid amount")).required(t("Enter an amount")),
            isCredit: Yup.boolean().required(),
            note: Yup.string().notRequired()
        }),
        onSubmit: values => {
            adjustBalanceMutation.mutate({
                walletId: wallet!.walletId,
                amount: values.amount,
                note: values.note,
                isCredit: values.isCredit
            });
        }
    });

    const { data: wallet, isFetching: walletLoading } = useQuery({
        queryKey: ["wallet", customer?.customerId, validation.values.warehouseId],
        staleTime: 5000,
        queryFn: () => getWallet({
            customerId: customer!.customerId,
            warehouseId: validation.values.warehouseId
        }),
        enabled: !!customer && !!validation.values.warehouseId
    });

    const newBalance = wallet ? (validation.values.isCredit ? wallet.balance + validation.values.amount : wallet.balance - validation.values.amount) : undefined;

    return <>
        <Button color="soft-dark" onClick={() => setUserBalanceModal(true)}>
            {t("Adjust User Balance")}
        </Button>
        <Modal backdrop="static" size="lg" isOpen={userBalanceModal} toggle={() => setUserBalanceModal(prev => !prev)} unmountOnClose>
            <ModalBody>
                <ModalCloseButton onClick={() => setUserBalanceModal(false)} />
                <div>
                    <div>
                        <h5 className="mb-1">{t("Adjust User Balance")}</h5>
                        <p className="text-muted">
                            {t("paragraphs:AdjustUserBalanceDescription")}
                        </p>
                    </div>
                    <hr />
                    <div>
                        <FormGroup row>
                            <Label xs={5}>
                                {t("Select Customer")}
                            </Label>
                            <Col xs={7}>
                                <ValidationWrapper validation={validation} field="customerId">
                                    <CustomerSelect onSelect={setCustomer} />
                                </ValidationWrapper>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label xs={5}>
                                {t("Select Warehouse")}
                            </Label>
                            <Col xs={7}>
                                <ValidationWrapper validation={validation} field="warehouseId">
                                    <WarehouseSelect />
                                </ValidationWrapper>
                            </Col>
                        </FormGroup>
                        <hr />
                        <Row>
                            <Col className="p-4 text-center">
                                <Label>{t("Starting Balance")}</Label>
                                <div className="fw-semibold fs-3">
                                    <BusyOverlay busy={walletLoading} size="sm" backgroundColor="body-secondary" spinnerColor="tenant-primary" opaque inline>
                                        {wallet ? <Currency value={wallet.balance} currency={wallet.unitOfCurrency} /> : "-"}
                                    </BusyOverlay>
                                </div>
                            </Col>
                            <Col className="border-left-1 border-dark-subtle p-4 text-center">
                                <Label>{t("New Balance")}</Label>
                                <div className="fw-semibold fs-3">
                                    <BusyOverlay busy={walletLoading} size="sm" backgroundColor="body-secondary" spinnerColor="tenant-primary" opaque inline>
                                        {wallet ? <Currency value={newBalance} currency={wallet.unitOfCurrency} /> : "-"}
                                    </BusyOverlay>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <FormGroup row>
                            <Label xs={5}>
                                {t("Adjustment Type")}
                            </Label>
                            <Col xs={7}>
                                <ValidationWrapper validation={validation} field="isCredit">
                                    <SelectInput options={adjustmentTypeOptions} display="inline-radio" />
                                </ValidationWrapper>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label xs={5}>
                                {t("Amount")}
                            </Label>
                            <Col xs={7}>
                                <ValidationWrapper validation={validation} field="amount">
                                    <CurrencyInput currency={wallet?.unitOfCurrency} />
                                </ValidationWrapper>
                            </Col>
                        </FormGroup>
                        <div>
                            <Label>{t("Note")}</Label>
                            <ValidationWrapper validation={validation} field="note">
                                <TextInput type="textarea" placeholder={t("paragraphs:AdjustUserBalanceNotePlaceholder")} />
                            </ValidationWrapper>
                            <FormText>{t("paragraphs:AdjustUserBalanceNoteDescription")}</FormText>
                        </div>
                    </div>
                    <div className="my-4">
                        <WarningAlert kind="solid">{t("paragraphs:AdjustUserBalanceWarningAlert")}</WarningAlert>
                    </div>
                    <div className="d-flex justify-content-end">
                        <BusyOverlay busy={adjustBalanceMutation.isPending} size="sm" inline>
                            <ValidatorButton validation={validation} color="success" onClick={validation.submitForm}>
                                {t("Apply to credit balance")}
                            </ValidatorButton>
                        </BusyOverlay>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    </>;
}

export default AdjustUserBalance;