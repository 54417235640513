import type { PrintPreferenceContract, PrintTemplateType } from "api/types/contracts/printing";
import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import PrintSizeSelect from "Components/EntitySelects/PrintSizeSelect";
import PrintOrientationSelect from "Components/EnumSelects/PrintOrientationSelect";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Label, Row } from "reactstrap";
import ShippingLabelEditSection from "./_ShippingLabelEditSection";
import FnskuLabelEditSection from "./_FnskuLabelEditSection";
import BinLabelEditSection from "./_BinLabelEditSection";
import BinLocationLabelEditSection from "./_BinLocationLabelEditSection";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import _ from "lodash";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getPrintPreference, patchPrintPreferences } from "api/printing";
import { toast } from "react-toastify";
import Loader from "Components/Common/Loader";
import BusyOverlay from "Components/Common/BusyOverlay";

const disabledMap: Record<PrintTemplateType, { size: boolean, orientation: boolean }> = {
    "binLabel": { size: false, orientation: false },
    "fnskuLabel": { size: false, orientation: false },
    "invoice": { size: false, orientation: false },
    "locationLabel": { size: false, orientation: false },
    "packingSlip": { size: false, orientation: false },
    "pickList": { size: false, orientation: false },
    "shippingLabel": { size: true, orientation: false },
    "binLocationLabel": { size: false, orientation: false },
    "other": { size: false, orientation: false }
};

type PrintDocumentEditProps = {
}

export type EditSectionProps = {
    value: Record<string, string>,
    onChange: (value: Record<string, string | undefined>) => void
}

const PrintDocumentEditPage = (props: PrintDocumentEditProps) => {
    const { printPreferenceId } = useParams<{ printPreferenceId: string }>();
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();

    setPageTitle(`${t("Edit Document")} - ${t("Printer Setup")} - ${t("Management")}`);

    const { data: printPreference, isLoading: loading, isSuccess: loaded, refetch: reload } = useQuery({
        queryKey: ["print-preference", printPreferenceId],
        queryFn: () => getPrintPreference({ printPreferenceId: printPreferenceId! }),
        enabled: !!printPreferenceId
    });

    const savePrintPreferencesMutation = useMutation({
        mutationFn: patchPrintPreferences,
        mutationKey: ["save-print-preferences"],
        onSuccess: () => {
            toast.success(t("Preferences Saved"));
            reload();
        }
    })

    const createEditSectionProps = (printPreference: PrintPreferenceContract): EditSectionProps => {
        return {
            value: printPreference.preferences,
            onChange: (value) => {
                if (_.isEqual(printPreference.preferences, value)) {
                    return;
                }

                savePrintPreferencesMutation.mutate({
                    printPreferenceId: printPreference.printPreferenceId,
                    preferences: {
                        ...printPreference.preferences,
                        ...value
                    }
                });
            }
        }
    }

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={"Edit Document"} parents={[t("Management"), {
                    title: t("Printer Setup"),
                    to: "/printing-setup"
                }]} />
                {loading && <>
                    <Loader height="500px" />
                </>}
                {loaded && <>
                    <Card body>
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <h5 className="mb-3">
                                    <i className="ri-printer-fill me-2"></i>
                                    {t("Printing Templates - {{documentType}}", { documentType: t(`enums:PrintTemplateType.${printPreference.templateType}`) })}
                                </h5>
                                <p className="mb-0">
                                    {t(`paragraphs:PrintingTemplateDescription.${printPreference.templateType}`)}
                                </p>
                            </div>
                            <div>
                                <Link to="/printing-setup" className="btn btn-info">
                                    {t("Return to Printer Setup")}
                                </Link>
                            </div>
                        </div>
                    </Card>
                    <Row>
                        <Col xl={8} md={6}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag={"h6"} className="mb-0">{t("Size & Orientation")}</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col xs={6}>
                                            <FormGroup>
                                                <Label>{t("Size")}</Label>
                                                <BusyOverlay busy={savePrintPreferencesMutation.isPending} size="sm">
                                                    <PrintSizeSelect 
                                                        disabled={disabledMap[printPreference.templateType].size}
                                                        templateType={printPreference.templateType} 
                                                        value={printPreference.printSize.printSizeId}
                                                        onChange={val => {
                                                            if (printPreference.printSize.printSizeId === val) {
                                                                return;
                                                            }

                                                            if (val) {
                                                                savePrintPreferencesMutation.mutate({
                                                                    printPreferenceId: printPreference.printPreferenceId,
                                                                    printSizeId: val
                                                                });
                                                            }
                                                    }} />
                                                </BusyOverlay>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6}>
                                            <FormGroup>
                                                <Label>{t("Orientation")}</Label>
                                                <BusyOverlay busy={savePrintPreferencesMutation.isPending} size="sm">
                                                    <PrintOrientationSelect 
                                                        value={printPreference.orientation} 
                                                        disabled={disabledMap[printPreference.templateType].orientation}
                                                        onChange={val => {
                                                            if (printPreference.orientation === val) {
                                                                return;
                                                            }

                                                            if (val) {
                                                                savePrintPreferencesMutation.mutate({
                                                                    printPreferenceId: printPreference.printPreferenceId,
                                                                    orientation: val
                                                                })
                                                            }
                                                        }} />
                                                </BusyOverlay>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <BusyOverlay busy={savePrintPreferencesMutation.isPending}>
                                {printPreference.templateType === "shippingLabel" && <ShippingLabelEditSection {...createEditSectionProps(printPreference)} />}
                                {printPreference.templateType === "fnskuLabel" && <FnskuLabelEditSection {...createEditSectionProps(printPreference)} />}
                                {printPreference.templateType === "binLabel" && <BinLabelEditSection {...createEditSectionProps(printPreference)} />}
                                {printPreference.templateType === "binLocationLabel" && <BinLocationLabelEditSection {...createEditSectionProps(printPreference)} />}
                                {printPreference.templateType === "locationLabel" && <BinLocationLabelEditSection {...createEditSectionProps(printPreference)} />}
                            </BusyOverlay>
                        </Col>
                        <Col xl={4} md={6}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag={"h6"} className="mb-0">{t("{{documentType}} Example", { documentType: t(`enums:PrintTemplateType.${printPreference.templateType}`) })}</CardTitle>
                                </CardHeader>
                                <img className="img-fluid shadow" src={`/printTemplateExamples/${printPreference.templateType}.png`}
                                    alt={t("{{documentType}} Example Image", { documentType: t(`enums:PrintTemplateType.${printPreference.templateType}`) })}
                                    style={{ minHeight: "100px" }} />
                                <CardBody>
                                    <CardTitle tag={"strong"}>{t("{{documentType}} Example", { documentType: t(`enums:PrintTemplateType.${printPreference.templateType}`) })}</CardTitle>
                                    <p className="my-3">
                                        {t(`paragraphs:PrintingTemplateExampleDescription.${printPreference.templateType}`)}
                                    </p>
                                    <span className="small">
                                        *{t("Image will not update with the changes")}
                                    </span>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </>}
            </Container>
        </div>
    </>;
}

export default PrintDocumentEditPage;