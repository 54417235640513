import { useQuery } from "@tanstack/react-query";
import type { ColumnDef } from "@tanstack/react-table";
import { getTransactionHistoryList } from "api/payment";
import type { TransactionHistoryContract } from "api/types/contracts/payment";
import type { WarehouseContract } from "api/types/contracts/warehouses";
import { ListTransactionHistoryQuery } from "api/types/queries";
import TableContainer, { selectRowColumn, type TableContainerRef } from "Components/Common/TableContainer";
import DateDisplay from "Components/Displays/DateDisplay";
import Currency from "Components/Displays/UnitDisplay/Currency";
import { forwardRef, useImperativeHandle, useMemo, useRef, type Ref, type RefAttributes } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useImmer } from "use-immer";

type TransactionHistoryProps = {
    warehouse: WarehouseContract
}

export type TransactionHistoryRef = {
    refresh: () => void
}

const TransactionHistory = (props: TransactionHistoryProps, ref: Ref<TransactionHistoryRef>) => {
    const { t } = useTranslation();
    const tableRef = useRef<TableContainerRef>(null);
    const [query, updateQuery] = useImmer<ListTransactionHistoryQuery>({
        page: 1,
        pageSize: 10,
        warehouseId: props.warehouse.warehouseId
    });

    const { data: transactions, isFetching: transactionsLoading, refetch: reload } = useQuery({
        queryKey: ["transactionHistory", query],
        queryFn: () => getTransactionHistoryList(query)
    });

    useImperativeHandle(ref, () => {
            return {
                refresh: () => {
                    reload();
                }
            };
        }, [reload]);
    
    const columns = useMemo<ColumnDef<TransactionHistoryContract, any>[]>(() => [
        {
            header: t("DESCRIPTION"),
            accessorFn: item => item.description,
            enableHiding: false,
            enableColumnFilter: false
        },
        {
            header: t("DEBIT"),
            accessorFn: item => item.debit,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => cell.row.original.debit ? <Currency value={cell.row.original.debit} currency={cell.row.original.unitOfCurrency} /> : <span>-</span>
        },
        {
            header: t("CREDIT"),
            accessorFn: item => item.credit,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => cell.row.original.credit ? <Currency value={cell.row.original.credit} currency={cell.row.original.unitOfCurrency} /> : <span>-</span>
        },
        {
            header: t("BALANCE"),
            accessorFn: item => item.balance,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <Currency value={cell.row.original.balance} currency={cell.row.original.unitOfCurrency} />
        },
        {
            header: t("INVOICE"),
            accessorFn: item => item.invoice?.invoiceCode,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => cell.row.original.invoice ? <Link to={`/invoice/${cell.row.original.invoice.invoiceId}`}>{cell.row.original.invoice.invoiceCode}</Link> : <span>-</span>
        },
        {
            header: t("DATE"),
            accessorFn: item => item.createdAt,
            enableColumnFilter: false,
            cell: (cell) => <DateDisplay date={cell.row.original.createdAt} />
        }],
        [t]
    );

    return <>
        <div>
            <TableContainer
                    ref={tableRef}
                    busy={transactionsLoading}
                    columns={columns}
                    nowrap
                    data={(transactions?.items || [])}
                    totalDataLength={transactions?.totalCount}
                    pagination={{
                        pageIndex: query.page - 1,
                        pageSize: query.pageSize
                    }}
                    onPaginationChanged={pagination => updateQuery(q => {
                        q.page = pagination.pageIndex + 1;
                        q.pageSize = pagination.pageSize;
                    })}
                    divClass="mb-1"
                    tableClass="align-middle"
                />
        </div>
    </>
};

export default forwardRef(TransactionHistory) as (
    props: TransactionHistoryProps & RefAttributes<TransactionHistoryRef>
) => ReturnType<typeof TransactionHistory>;