import { CSSProperties } from "react";
import { Input, InputProps } from "reactstrap";
import { ValidatableInputProps } from "helpers/types";
import classNames from "classnames";
import _ from "lodash";

export type StepInputProps = ValidatableInputProps<number> & {
    id?: string,
    min?: number,
    max?: number,
    step?: number,
    className?: string,
    decimalPlaces?: number,
    size?: "sm" | "lg",
    style?: CSSProperties,
    disabled?: boolean,
    placeholder?: string,
    fullWidth?: boolean,
    readOnly?: boolean,
    tabIndex?: number,
    onBlur?: InputProps["onBlur"],
    onInput?: InputProps["onInput"],
}

const StepInput = ({ isValid, onChange, value, size, className, style, decimalPlaces, ...rest }: StepInputProps) =>  {
    const onChangeHandler = (value: string | undefined) => {
        if (value === undefined || value === "") {
            onChange?.(undefined);
        } else {
            let numberValue = Number(value);

            if (_.isNaN(numberValue)) {
                numberValue = 0;
            }

            if (rest.min !== undefined && numberValue < rest.min) {
                numberValue = rest.min;
            }

            if (rest.max !== undefined && numberValue > rest.max) {
                numberValue = rest.max;
            }

            onChange?.(numberValue);
        }
    }

    const countDown = () => {
        if (rest.readOnly || rest.disabled) {
            return;
        }

        let newVal = (value ?? 0) - (rest.step ?? 1);
        if (rest.min !== undefined) {
            newVal = Math.max(newVal, rest.min);
        }

        onChange?.(newVal);
    }

    const countUp = () => {
        if (rest.readOnly || rest.disabled) {
            return;
        }

        let newVal = (value ?? 0) + (rest.step ?? 1);
        if (rest.max !== undefined) {
            newVal = Math.min(newVal, rest.max);
        }

        onChange?.(newVal);
    }

    return <>
        <div style={style} className={classNames("input-step", { 
            "input-step-sm": size === "sm",
            "is-invalid border-danger": isValid === false,
            "bg-light": rest.disabled,
            "full-width": rest.fullWidth 
        })}>
            <button type="button" disabled={rest.disabled} className={classNames("minus", {
                "btn-sm": size === "sm",
                "invisible": rest.readOnly
            })} onClick={countDown}>–</button>
            <Input
                type="number"
                onChange={e => onChangeHandler(e.target.value)}
                value={value ?? ""}
                bsSize={size}
                className={classNames("text-center px-2", className)}
                { ...rest } />
            <button type="button" disabled={rest.disabled} className={classNames("plus", {
                "btn-sm": size === "sm",
                "invisible": rest.readOnly
            })} onClick={countUp}>+</button>
        </div>
    </>;
}

export default StepInput;