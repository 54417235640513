import type {  PaymentMethod } from "api/types/contracts/payment";
import type { WarehouseContract } from "api/types/contracts/warehouses";
import { InfoAlert } from "Components/Alerts";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, FormGroup, Label, Row } from "reactstrap";
import CreditCardSelect from "./_CreditCardSelect";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { postLoadCreditWithStripe, postRequestCredit } from "api/payment";
import RequiredLabel from "Components/Common/RequiredLabel";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import CurrencyInput from "Components/Form/UnitInputs/CurrencyInput";
import Currency from "Components/Displays/UnitDisplay/Currency";
import ValidatedFileUpload from "Components/Form/Validated/ValidatedFileUpload";
import BusyOverlay from "Components/Common/BusyOverlay";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useProfile } from "Components/Hooks/ProfileHooks";
import WiseInfo from "./_WiseInfo";
import PayoneerInfo from "./_PayoneerInfo";
import WireTransferInfo from "./_WireTransferInfo";
import ZelleInfo from "./_ZelleInfo";
import LinedHeader from "Components/Common/LinedHeader";

type AddCreditFormProps = {
    warehouse: WarehouseContract,
    paymentMethod: PaymentMethod | undefined,
    onCreditAdded: () => void
}

const AddCreditForm = (props: AddCreditFormProps) => {
    const { t } = useTranslation();
    const { customer } = useProfile();
    const [showSuccess, setShowSuccess] = useState<boolean>();

    const processingFee = useMemo(() => {
        switch (props.paymentMethod) {
            case "stripe":
                return props.warehouse.settings.stripe?.processingFee ?? 0;
            case "payoneer":
                return props.warehouse.settings.payoneer?.processingFee ?? 0;
            case "wireTransfer":
                return props.warehouse.settings.wireTransfer?.processingFee ?? 0;
            case "wise":
                return props.warehouse.settings.wise?.processingFee ?? 0;
            case "zelle":
                return props.warehouse.settings.zelle?.processingFee ?? 0;
            default:
                return 0;
        }
    }, [props.paymentMethod, props.warehouse.settings]);

    const loadCreditWithStripeMutation = useMutation({
        mutationFn: postLoadCreditWithStripe,
        mutationKey: ["load-credit-stripe"],
        onSuccess: (data, variables, context) => {
            setShowSuccess(true);
            validation.resetForm();
            props.onCreditAdded();
        }
    });

    const requestCreditMutation = useMutation({
        mutationFn: postRequestCredit,
        mutationKey: ["request-credit"],
        onSuccess: (data, variables, context) => {
            setShowSuccess(true);
            validation.resetForm();
        }
    });

    const validation = useFormik({
        initialValues: {
            amount: 0,
            proofOfPayment: undefined,
            creditCardId: undefined
        },
        validationSchema: Yup.object({
            amount: Yup.number().min(1, t("You need to enter amount greater than zero")).required(t("Amount is required")),
            proofOfPayment: Yup.string().notRequired(),
            creditCardId: Yup.string().when([], (values, schema, context) => 
                props.paymentMethod === "stripe" ? schema.required(t("You need to select a credit card")) : schema.notRequired()
            ),
        }),
        onSubmit: values => {
            if (!props.paymentMethod) {
                return;
            }

            if (props.paymentMethod === "stripe") {
                loadCreditWithStripeMutation.mutate({
                    warehouseId: props.warehouse.warehouseId,
                    creditCardId: values.creditCardId!,
                    amount: values.amount,
                    fee: fee
                });
            }
            else {
                requestCreditMutation.mutate({
                    warehouseId: props.warehouse.warehouseId,
                    paymentMethod: props.paymentMethod,
                    amount: values.amount,
                    proofOfPayment: values.proofOfPayment,
                    fee: fee
                })
            }
        }
    });

    const fee = (validation.values.amount * processingFee / 100);
    const total = validation.values.amount + fee;

    return <div className="pb-3">
        <Row>
            {!props.paymentMethod && <Col xs={12} style={{ height: "200px" }}>
                <div className="h-100 d-flex justify-content-center align-items-center">
                    <p className="text-muted fs-16">
                        {t("Please select a payment method")}
                    </p>
                </div>
            </Col>}
            {showSuccess && <Col xs={12}>
                <div className="text-center py-5">
                    <div className="mb-3">
                        <i className="ri-checkbox-circle-line text-success fs-48"></i>
                    </div>
                    {props.paymentMethod === "stripe" && <h5>{t("Credit succesfully loaded to your wallet")}</h5>}
                    {props.paymentMethod !== "stripe" && <>
                        <h5>{t("Credit load request sent")}</h5>
                        <p className="text-muted">
                            {t("paragraphs:CreditLoadRequestSent")}
                        </p>
                    </>}
                    <p className="mt-3">
                        <Button color="primary" onClick={() => setShowSuccess(false)}>{t("OK")}</Button>
                    </p>
                </div>
            </Col>}
            {(props.paymentMethod && !showSuccess) && <>
                <Col xs={6}>
                    <div className="h-100 mb-0">
                        <div className="h-100">
                            {props.paymentMethod && <LinedHeader>{t(`enums:PaymentMethod.${props.paymentMethod}`)}</LinedHeader>}
                            {props.paymentMethod === "stripe" && <>
                                <FormGroup row noMargin>
                                    <Col>
                                        <ValidationWrapper validation={validation} field="creditCardId">
                                            <CreditCardSelect warehouse={props.warehouse} />
                                        </ValidationWrapper>
                                    </Col>
                                </FormGroup>
                            </>}
                            {props.paymentMethod === "wise" && <WiseInfo warehouse={props.warehouse} />}
                            {props.paymentMethod === "payoneer" && <PayoneerInfo warehouse={props.warehouse} />}
                            {props.paymentMethod === "wireTransfer" && <WireTransferInfo warehouse={props.warehouse} />}
                            {props.paymentMethod === "zelle" && <ZelleInfo warehouse={props.warehouse} />}
                        </div>
                    </div>
                </Col>
                <Col xs={6}>
                    <div className="h-100 mb-0">
                        <div>
                            <LinedHeader>{t("Add Credit")}</LinedHeader>
                            {processingFee > 0 && <InfoAlert kind="top-border">
                                {t("paragraphs:AddCreditProcessingFeeStatement", { fee: processingFee, paymentType: t(`enums:PaymentMethod.${props.paymentMethod}`) })}
                            </InfoAlert>}

                            <Form onSubmit={validation.handleSubmit}>
                                <div className="vstack gap-4">
                                    <FormGroup row noMargin>
                                        <RequiredLabel xs={12}>{t("Amount")}</RequiredLabel>
                                        <Col xs={12}>
                                            <ValidationWrapper validation={validation} field="amount">
                                                <CurrencyInput currency={props.warehouse.settings.unitOfCurrency} />
                                            </ValidationWrapper>
                                        </Col>
                                    </FormGroup>
                                    <Row>
                                        <Col xs="auto">
                                            <Label>{t("Amount")}</Label>
                                        </Col>
                                        <Col xs="auto" className="ms-auto">
                                            <Currency value={validation.values.amount} currency={props.warehouse.settings.unitOfCurrency} />
                                        </Col>
                                    </Row>
                                    {processingFee > 0 && <Row>
                                        <Col xs="auto">
                                            <Label>{t("Processing Fee ({{fee}}%)", { fee: processingFee })}</Label>
                                            <p className="form-text mb-0">{t("paragraphs:AddCreditProcessingFeeDescription", { fee: processingFee })}</p>
                                        </Col>
                                        <Col xs="auto" className="ms-auto">
                                            <Currency value={validation.values.amount ? (validation.values.amount * processingFee / 100) : undefined} currency={props.warehouse.settings.unitOfCurrency} />
                                        </Col>
                                    </Row>}
                                    <Row>
                                        <Col xs="auto">
                                            <Label>{t("Total Charge")}</Label>
                                        </Col>
                                        <Col xs="auto" className="ms-auto">
                                            <Currency value={total} currency={props.warehouse.settings.unitOfCurrency} />
                                        </Col>
                                    </Row>
                                    {props.paymentMethod !== "stripe" && <>
                                        <FormGroup row>
                                            <Label xs={12}>{t("Upload Proof of Payment")} ({t("Optional")})</Label>
                                            <Col xs={12}>
                                                <ValidatedFileUpload validation={validation} field="proofOfPayment" />
                                            </Col>
                                        </FormGroup>
                                    </>}
                                    <Row>
                                        <Col xs="auto">
                                            <BusyOverlay busy={loadCreditWithStripeMutation.isPending || requestCreditMutation.isPending} size="sm" inline>
                                                <ValidatorButton type="submit" color="primary" disabled={!customer?.invoiceAddress || !props.paymentMethod} validation={validation}>
                                                    {props.paymentMethod === "stripe" ? t("Confirm Payment") : t("Request Credit")}
                                                </ValidatorButton>
                                            </BusyOverlay>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Col>
            </>}
        </Row>
    </div>;
}

export default AddCreditForm;