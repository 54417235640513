import { useRef } from "react";
import { Container, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import List, { BinListRef } from "./_List";
import { useLoaderData, useNavigate } from "react-router-dom";
import UpdateBinForm from "./_UpdateForm";
import CreateBinForm from "./_CreateForm";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import ResolveWrapper from "Components/Common/ResolveWrapper";
import type { BinContract } from "api/types/contracts/bin";

type BinsPageProps = {
    edit?: boolean
}

type BinsPageLoaderData = {
    bin?: Promise<BinContract>
}

const BinsPage = (props: BinsPageProps ) => {
    const loaderData = useLoaderData() as BinsPageLoaderData | undefined;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const listRef = useRef<BinListRef>(null);

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("Bins")} parents={[t("Management")]} />
                <List ref={listRef} />
                {props.edit && 
                    <Modal backdrop="static" size="lg" isOpen toggle={() => navigate("/bins")} unmountOnClose>
                        <ModalCloseButton onClick={() => navigate("/bins")} />
                        <ModalHeader>
                            {loaderData?.bin ? t("Update Bin") : t("Create Bin")}
                        </ModalHeader>
                        <ModalBody>
                            {loaderData?.bin ? <ResolveWrapper promise={loaderData.bin}>
                                {bin => <>
                                    <UpdateBinForm bin={bin} onSuccess={() => {
                                        navigate("/bins");
                                        listRef.current?.reload({});
                                    }} />
                                </>}
                            </ResolveWrapper> : <>
                                <CreateBinForm onSuccess={val => {
                                    navigate("/bins");
                                    listRef.current?.reload({
                                        warehouseId: val.warehouseId
                                    });
                                }} />
                            </>}
                        </ModalBody>
                    </Modal>
                }
            </Container>
        </div>
    </>;
};

export default BinsPage;
